<template>
    <loading v-if="loading"/>
    <div v-else>
        <div v-if="paidUp">
            <h1>You are registered for this selection trial.</h1>
            <br/>
            <div>
                Your participant number is:
            </div>
            <div class="athlete-id">
                {{participant.participantNumber}}
            </div>
            <div>
                <p>This has been emailed to you, along with further details about the tryouts.</p>
                <br/>
                <p>We are looking forward to seeing you at the tryouts. If you have any further questions, contact </p>
                <p v-if="tryouts[selectedTryout].contactName" class="contact-name">{{tryouts[selectedTryout].contactName}}</p>
                <p v-if="tryouts[selectedTryout].contactEmail" class="contact-email">Email: {{tryouts[selectedTryout].contactEmail}}</p>
                <p v-if="tryouts[selectedTryout].contactPhone" class="contact-phone">Phone: {{tryouts[selectedTryout].contactPhone}}</p>
            </div>
            <div>
                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th></th>
                                <th class="text-left">
                                    Question
                                </th>
                                <th class="text-left">
                                    Your Response
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="questionId in questionList" :key="questionId">
                                <td>
                                    <span style="font-weight:600">{{questionNumber(questionId)}}</span>
                                </td>
                                <td class="text-left">
                                    {{questions[questionId].question}}
                                </td>
                                <td v-if="formResponses" class="text-left">
                                    <span v-if="questions[questionId].type==='select'">{{ getSelectText(questionId)}}</span>
                                    <span v-else-if="questions[questionId].type==='checkbox'">{{ formResponses[questionId]?"Yes":"No" }}</span>
                                    <span v-else> {{formResponses[questionId]}}</span>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </div>
        </div>
        <div v-else>
            <!-- Error Messages -->
            <v-icon color="#ffa300">mdi-alert-outline</v-icon>
            <span v-if="regError==='Already Registered'">You already appear to be registered for this tryout. If you need to alter any of your registration details, please let the club contact know</span>
            <span v-else>Your registration may have failed due to an internal error. Please let your club contact know.</span>
            <div>
                Your participant number is:
            </div>
            <div class="athlete-id">
                {{participant.participantNumber}}
            </div>
            <p></p> 
            <div v-if="tryouts[selectedTryout].contactName || tryouts[selectedTryout].contactPhone || tryouts[selectedTryout].contactEmail" class="text-left">
                <h3>Contact Details</h3>
                <p v-if="tryouts[selectedTryout].contactName">{{tryouts[selectedTryout].contactName}}</p>
                <p v-if="tryouts[selectedTryout].contactPhone">{{tryouts[selectedTryout].contactPhone}}</p>
                <p v-if="tryouts[selectedTryout].contactEmail">{{tryouts[selectedTryout].contactEmail}}</p>
            </div>
        </div>

    </div>
</template>

<script>

import {mapState, mapActions} from 'vuex'
import Loading from '@/components/loading'

export default {
    props: [],
    components: {
        Loading,
    },
    computed: {
        ...mapState({
            regComplete:            state => state.tryoutregStore.complete,
            regError:               state => state.tryoutregStore.error,
            newParticipant:         state => state.tryoutregStore.newParticipant,

            selectedTryout:         state => state.navigatorStore.selectedTryout,
            selectedClub:           state => state.navigatorStore.selectedClub,
            
            tryouts:                state => state.tryoutStore.data,

            participants:           state => state.participantStore.data,
            participantList:        state => state.participantStore.list,
            participantStatus:      state => state.participantStore.status,

            bankState:              state => state.navigatorStore.bankState,
            questions:              state => state.appquestionStore.data,
            questionList:           state => state.appquestionStore.list,

            formResponses: state => state.tryoutregStore.qresponses,
        }),
        paidUp() {
            if(this.participantList.length===0 && (!this.participant || this.participant==='undefined')) return false
            if(this.participants[this.participantList[0]].registrationStatus === 'complete'){
                if(this.tryouts[this.selectedTryout].feeExempt) return true
                if(this.bankState=='paid') return true
                if(this.participants[this.participantList[0]].feeType===0) return true
            }
            return false
        },
        loading(){
            return false
                || this.participantStatus.loading
        },
        participant: {
            get(){
                if(this.participantList.length ===0) return this.newParticipant
                return this.participants[this.participantList[0]]
            }
        }
    },
    methods : {
        ...mapActions('tryoutregStore', {
            setCompletion: 'setCompletion',
        }),
        ...mapActions('participantStore',{
            findParticipant: 'inEvent'
        }),
        getSelectText(questionId){
            const options = JSON.parse(this.questions[questionId].options)

            if(this.formResponses[questionId]>options.length) return ""
            const text = options[this.formResponses[questionId]].text
            return text
        },
        questionNumber: function(id) {
            return `Q${this.questions[id].order+1}: `
        },
        questionLabel: function(id) {
            return `Q${this.questions[id].order+1}: ${this.questions[id].question}`
        },  
    },
    created(){
        // Let's fetch the participantRecord for the current user, because it's status may have updated...

    }

}
</script>

<style>
.contact-name {
    font-weight:600;
}

.contact-phone {
    line-height: 50%;
}

.contact-email {
    line-height: 50%
}

.athlete-id {
    display: flex;
    justify-content: center;
    border-style: solid;
    width: 20%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    margin-bottom: 30px;
    font-size:64px;
    border-radius: 36px;
    background: blue;
    color: white;
    font-weight: 900;
    padding: 40px;
}
</style>