<template>
    <loading v-if="loading"/>
    <v-timeline v-else align-top dense>
        <v-dialog v-model="renderEpisode" width="90%" @click:outside="closeDialog()">
            <DevStreamEpisodeParticipantRender :mode="mode" :episodeId="renderEpisode" :viewer="account.user.details.id"/>
        </v-dialog>
        <v-timeline-item v-if="false"
            color="#BA43B6" 
            icon="mdi-notebook"
            fill-dot
            align-top
        >
            <div style="display:flex;flex-flow:row;" class="text-left">
                <div class="journal-pill">
                    <div class="journal-title-content">
                        <div class="journal-text">Journal Entries</div>
                        <div style="font-size:small">{{ user.firstName }} {{ user.lastName }}</div>
                    </div>
                </div>
            </div>

        </v-timeline-item>
        <div 
            v-for="(entry,n) in timelineEntries" 
            :key="n"
        >
            <v-timeline-item 
                v-if="showEntry(entry)"
                :color="entry.color"
                :icon="entry.icon"
                fill-dot
                small
                right
                align-top
            >
                <div v-if="$vuetify.breakpoint.mdAndUp">
                    <div style="display:flex;flex-flow:row;" class="text-left">
                        <div class="title-pill" :style="'background-color: '+ entry.color ">
                            <div class="title-pill-text">
                                {{ entry.title}}
                            </div>
                                <JournalEntryEditor iconOnly v-if="entry.type!=='episodeForm' && entry.authorId === useraccount.id"  :journalOwnerId="entry.journalOwnerId" :journalEntryId="entry.journalId" :type="entry.type" :color="entry.color"/>
                        </div>
                        <div class="date-pill" :style="'border-color: '+entry.color">
                            <div class="date-pill-text">
                                <strong>{{ entryDay(entry)}}</strong>
                            </div>
                            <div class="date-pill-text">
                                <p>{{ entryDateTime(entry) }}</p>
                            </div>
                        </div>
                        <div v-if="entry.author" class="author">
                            by {{ entry.author }}
                        </div>
                        <div>
                        </div>
                        <v-spacer/>
                    </div>
                    <div v-if="entry.type==='episodeForm' " class="text-left ma-5" style="display:flex;flex-flow:row;cursor:pointer;" @click="viewEpisode(entry.parentId)"> <!-- The journal parentId contains the link to the episode... -->
                        <div style="text-align:center">
                            <img height="30px" src="https://s3.ap-southeast-2.amazonaws.com/www.coachsight.net/app_assets/verified.png"/>
                            <div v-if="episodes[entry.parentId].published===0" class="role-pill" style="background-color:teal">Incomplete</div>
                        </div>
                        <div>
                            <div class="question-text">Date</div>
                            <div class="response-text">
                                {{ moment(episodes[entry.parentId].date).format('DD/MM/yy, HH:mm') }}
                            </div>
                        </div>
                        <div v-for="n in events[selectedEvent].numSummaryQuestions" :key="n" class="question-summary">
                            <div v-if="!questionStatus.loading" class="question-text">
                                {{ questions[questionList['devform'][n-1]].question }} 
                            </div>
                            <div class="response-text">
                                {{ renderResponse(entry.parentId, questionList['devform'][n-1]) }}
                            </div>
                        </div>
                        <div style="margin-left:40px;">Click to view</div>
                    </div>
                    <div v-else class="text-left">
                        <div class="content" :style="editorStyle">
                            <div v-if="entry.content.length<151" class="ql-editor" v-html="entry.content"/>
                            <div v-else>
                                <div :style="contentHeight(entry.journalId)+';overflow-y:hidden'" class="ql-editor" v-html="entry.content"/>
                                <span class="read" v-if="!journalMeta[entry.journalId].readActivated" @click="toggleReadActivated(entry.journalId)">...see more</span>
                                <span class="read" v-else @click="toggleReadActivated(entry.journalId)">...see less</span>
                            </div>
                        </div>
                    </div>

                </div>
                <div v-else>
                    <div style="display:flex;flex-flow: column;width:100%">
                        <div class="title-pill small " :style="'background-color: '+entry.color">
                            <div class="title-pill-text">
                                {{ entry.title }}
                            </div>
                            <v-spacer/>
                            <div>
                                <JournalEntryEditor v-if="entry.authorId === useraccount.id"  iconOnly :journalOwnerId="entry.journalOwnerId" :journalEntryId="entry.journalId" :type="entry.type" :color="entry.color"/>
                            </div>
                        </div>
                        <div class="date-pill small" :style="' ;border-color: '+entry.color">
                            <strong>{{  moment(entry.date).format('dddd') }}, {{ moment(entry.date).format('Do MMM, YYYY') }}</strong>
                        </div>
                        <div v-if="entry.author" class="author">
                            by {{ entry.author }}
                        </div>
                    </div>
                    <div v-if="entry.type==='episodeForm' " class="text-left ma-5" style="display:flex;flex-flow:row;cursor:pointer" @click="viewEpisode(entry.parentId)">
                        <div style="text-align:center">
                            <img height="30px" src="https://s3.ap-southeast-2.amazonaws.com/www.coachsight.net/app_assets/verified.png"/>
                            <div v-if="episodes[entry.parentId].published===0" class="role-pill" style="background-color:teal">Incomplete</div>
                        </div>
                        <div>
                            <div class="question-text">Date</div>
                            <div class="response-text">
                                {{ moment(episodes[entry.parentId].date).format('DD/MM/yy, HH:mm') }}
                            </div>
                        </div>
                        <div v-for="n in events[selectedEvent].numSummaryQuestions" :key="n" class="question-summary">
                            <div class="question-text">
                                <!--
                                {{ questions[questionList['devform'][n-1]].question }} 
                            -->
                            </div>
                            <div class="response-text">
                                {{ renderResponse(entry.parentId, questionList['devform'][n-1]) }}
                            </div>
                        </div>
                        <div style="margin-left:40px;">Click to view</div>
                    </div>
                    <div v-else class="text-left">
                        <div class="content" :style="editorStyle">
                            <div v-if="entry.content.length<151" class="ql-editor" v-html="entry.content"/>
                            <div v-else>
                                <div :style="contentHeight(entry.journalId)+';overflow-y:hidden'" class="ql-editor" v-html="entry.content"/>
                                <span class="read" v-if="!journalMeta[entry.journalId].readActivated" @click="toggleReadActivated(entry.journalId)">...see more</span>
                                <span class="read" v-else @click="toggleReadActivated(entry.journalId)">...see less</span>
                            </div>
                        </div>
                    </div>

                </div>
            
            </v-timeline-item>

        </div>

    </v-timeline>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import JournalEntryEditor from './JournalEntryEditor.vue'
import DevStreamEpisodeParticipantRender from '@/ClubPanes/DevelopmentStreams/DevStreamEpisodeParticipantRender'
import moment from 'moment'

export default{
    props: {
        userId: {
            type: String,
        },
        mode: {
            type: String,
            default: 'participant'
        }
    },
    components:{
        JournalEntryEditor,
        DevStreamEpisodeParticipantRender,
    },
    computed: {
        ...mapState({
            journalEntries:         state => state.journalentryStore.data,
            journalEntryList:       state => state.journalentryStore.list,
            journalEntryListByOwner:state => state.journalentryStore.listByJournalOwner,
            journalMeta:            state => state.journalentryStore.meta,
            journalStatus:          state => state.journalentryStore.status,

            sessions:               state => state.sessionStore.data,
            sessionList:            state => state.sessionStore.list,
            sessionStatus:          state => state.sessionStore.status,

            participants:           state => state.participantStore.data,
            participantStatus:      state => state.participantStore.status,

            selectedEvent:         state => state.navigatorStore.selectedTryout,
            selectedNav:            state => state.navigatorStore.selectedNav,
            selectedClub:           state => state.navigatorStore.selectedClub,

            useraccount:            state => state.account.user.details,
            //user:                   state => state.userStore.all.user.users[0],
            userLoading:            state => state.userStore.all.loading,
            selectedSeason:         state => state.navigatorStore.selectedSeason,
            selectedParticipant:    state => state.navigatorStore.selectedParticipant,

            questions:              state => state.appquestionStore.data,
            questionList:          state => state.appquestionStore.listByContext,
            questionStatus:         state => state.appquestionStore.status,
            responses:              state => state.qresponseStore.individual,
            responseStatus:         state => state.qresponseStore.status,


            account:                state => state.account,

            episodes:               state => state.episodeStore.data,
            episodeList:            state => state.episodeStore.list,
            episodeStatus:          state => state.episodeStore.status,

            events:                 state => state.tryoutStore.data,

        }),
        loading(){
            return !this.dataReady;
            /*
            this.journalStatus.loading ||
                this.sessionStatus.loading ||
                this.participantStatus.loading ||
                this.episodeStatus.loading ||
                !this.dataReady ||
                this.userLoading || 
                false;
                */
        },
        timelineEntries(){
            const list = []

            for(var idx in this.journalEntryListByOwner[this.userId]){
                const journalId = this.journalEntryListByOwner[this.userId][idx]
                const journalEntry = this.journalEntries[journalId]

                var authorName=null
                if(journalEntry.type === 'coachRemark' || journalEntry.type==='staffNote') {
                    authorName=journalEntry.authorName 
                }
                var date=journalEntry.createdAt


                list.push({
                    journalId: journalId,
                    date: date,
                    type: journalEntry.type,
                    content: journalEntry.content,
                    author: authorName,
                    authorId: journalEntry.authorId,
                    color: this.entryTypes[journalEntry.type].color,
                    icon: this.entryTypes[journalEntry.type].icon,
                    title: this.entryTypes[journalEntry.type].label,
                    journalOwnerId: journalEntry.journalOwnerId,
                    parentId: journalEntry.parentId,
                    
                })
            }

            for(idx in this.sessionList){
                const id = this.sessionList[idx]
                list.push({
                    date: this.sessions[id].startDateTime,
                    type: 'session',
                    content: this.sessions[id].title,
                    color: '#2196F3',
                    icon: 'mdi-calendar-month-outline',
                    title: 'Event'
                })
            }

            // Insert any broadcasts

            for(idx in this.journalEntryListByOwner[null]){
                const journalId = this.journalEntryListByOwner[null][idx]
                const journalEntry = this.journalEntries[journalId]

                authorName=null
                if(journalEntry.type === 'coachRemark' || journalEntry.type==='staffNote') {
                    authorName=journalEntry.authorName 
                }
                list.push({
                    journalId: journalId,
                    date: journalEntry.createdAt,
                    type: journalEntry.type,
                    content: journalEntry.content,
                    author: authorName,
                    authorId: journalEntry.authorId,
                    color: this.entryTypes[journalEntry.type].color,
                    icon: this.entryTypes[journalEntry.type].icon,
                    title: this.entryTypes[journalEntry.type].label,
                    journalOwnerId: '0',
                })

            }

            // Insert any episodes


            list.sort((a,b) => {
                return new Date(b.date) - new Date(a.date);
            })
            return list
        },
        editorStyle() {
            return {
                '--minheight': '1em'
            }
        },
        entryTypes(){
            return  {
                'reflection': {
                    color: 'green',
                    label: `${this.capitalize(this.events[this.selectedEvent].participantPronoun)} Reflection`,
                    icon: 'mdi-notebook-outline',
                },
                'coachRemark':{
                    color: 'orange',
                    label: 'Coach Remark',
                    icon:  'mdi-whistle'
                },
                'participantGoal' : {
                    color: 'red',
                    label: 'Goal',
                    icon:  'mdi-bullseye-arrow'
                },
                'event' : {
                    color: '#2196F3',
                    label: 'Event',
                    icon:  'mdi-calendar-month-outline'
                },
                'staffNote' : {
                    color: 'grey',
                    label: 'Staff Note',
                    icon:  'mdi-book-lock-outline'
                },
                'broadcast' : {
                    color: 'gold',
                    label: 'Broadcast',
                    icon: 'mdi-bullhorn-variant-outline'
                },
                'episodeForm': {
                    color: '#BA43B6',
                    label: this.capitalize(this.events[this.selectedEvent].episodeFormName),
                    icon: 'mdi-clipboard-text-outline'
                }
            }
        },
    },
    methods: {
        ...mapActions('episodeStore', [
            'loadAllEpisodesForUserAndEvent'
        ]),
        ...mapActions('journalentryStore',[
            'loadAllJournalEntriesForUser',
            'toggleReadActivated',
        ]),
        ...mapActions('sessionStore', [
            'loadAllSessionsForEvent',
            'loadAllSessionsForTeam'
        ]),
        ...mapActions('roleStore', [
            'loadAllRolesForEvent',
            'loadAllRolesForTeam',
        ]),
        ...mapActions('appquestionStore', [
            'loadAllQuestionsForEvent',
        ]),
        ...mapActions('qresponseStore',[
            'loadAllQResponsesForEvent',
            'loadQResponses',
        ]),

        ...mapActions('userStore', [
            'loadUserById'
        ]),
        ...mapActions('navigatorStore', [
            'setSelectedEpisode'
        ]),
        
        entryDay(entry){
            var dateToShow=null
            dateToShow=entry.date

           return moment(dateToShow).format('dddd')
        },
        entryDateTime(entry){
            var dateToShow=null
            dateToShow=entry.date

           return moment(dateToShow).format('Do MMM, YYYY')
        },
        contentHeight(journalId){
            if(this.journalMeta[journalId].readActivated)
                return '' 
            return 'height: 2em';
        },
        viewEpisode(episodeId){
            this.setSelectedEpisode(episodeId)
            this.renderEpisode=true
        },
        renderResponse(episodeId, responseId){
            if(!responseId) return '---'

            if(this.responses[episodeId][episodeId][responseId]===null) return '---'
            const response = this.responses[episodeId][episodeId][responseId].response 
            const type = this.questions[this.responses[episodeId][episodeId][responseId].questionId].type
            var options=[]
            if(response === null || !responseId) return '---'

            switch(type){
                case 'short': 
                    return response
                case 'select': 

                    options = JSON.parse(this.questions[this.responses[episodeId][episodeId][responseId].questionId].options)
                    return options[response].text
                case 'checkbox':
                    return response?"Yes":"No"
            } 

        },
        closeDialog(){
            this.$root.$emit('close-episode-render-dialog')
        },
        startDate(episodeId){
            return new Date(this.episodes[episodeId].date).toLocaleDateString('sv')
        },
        //eslint-disable-next-line
        showEntry(entry){


            /*
            if(entry.type==='episodeForm'){
                const episodeId = entry.parentId

                // Is this a staff member? If yes then show

                for (var i=0;i<this.episodes[episodeId].staffList.length;i++){
                    if (this.episodes[episodeId].staffList[i] === this.account.user.details.id) return true
                }

                // If its an athlete, only show after 7am

                const showDate = new Date(new Date(this.episodes[episodeId].date).toLocaleDateString('sv'))

                showDate.setHours(7)
                showDate.setMinutes(0)


                return (new Date()<showDate ? false : true)
            } else {
                return true
            }
                */
            return true
        },
    },
    watch: {
        async userId(){
            this.dataReady=false
            // Fetch this user's data now
            await this.loadUserById({userId: this.userId, clubId: this.selectedClub, eventId: this.selectedEvent})
            if(this.selectedNav==='Team'){
                await this.loadAllJournalEntriesForUser({teamId: this.selectedEvent ,userId: this.userId})
            } else {
                await this.loadAllJournalEntriesForUser({eventId: this.selectedEvent ,userId: this.userId})

            }
            await this.loadAllEpisodesForUserAndEvent({eventId: this.selectedEvent, userId: this.userId })
           // await this.loadAllQuestionsForEvent({eventId: this.selectedEvent})

            const data=[]

            if(this.episodeList.length>0){
                for(var i=0;i<this.episodeList.length;i++){
                    const node1 = {
                        eventId: this.episodeList[i],
                        participantId: this.episodeList[i],
                        questionId: this.questionList['devform'][0]
                    }
                    const node2 = {
                        eventId: this.episodeList[i],
                        participantId: this.episodeList[i],
                        questionId: this.questionList['devform'][1]
                    }
                    const node3 = {
                        eventId: this.episodeList[i],
                        participantId: this.episodeList[i],
                        questionId: this.questionList['devform'][2]
                    }
                    const node4 = {
                        eventId: this.episodeList[i],
                        participantId: this.episodeList[i],
                        questionId: this.questionList['devform'][3]
                    }
                    data.push(node1)
                    data.push(node2)
                    data.push(node3)
                    data.push(node4)
                }
                await this.loadQResponses({eventId: this.selectedEvent, data: JSON.stringify(data)})
            }
            this.dataReady=true
        }
    },
    async mounted(){
        this.$root.$on('close-episode-render', ()=> {
            var self=this
            self.renderEpisode=false
        })
    },
    async created(){
        this.dataReady=false

        await this.loadUserById({userId: this.userId, clubId: this.selectedClub, eventId: this.selectedEvent})
        if(this.selectedNav === 'Team'){
            await this.loadAllJournalEntriesForUser({teamId: this.selectedEvent, userId: this.userId})
            await this.loadAllRolesForTeam({teamId: this.selectedEvent})
            await this.loadAllSessionsForTeam({teamId: this.selectedEvent})
        } else {
            await this.loadAllJournalEntriesForUser({eventId: this.selectedEvent, userId: this.userId})
            await this.loadAllRolesForEvent({eventId: this.selectedEvent})
            await this.loadAllSessionsForEvent({eventId: this.selectedEvent})
        }
        await this.loadAllEpisodesForUserAndEvent({eventId: this.selectedEvent, userId: this.userId })

        await this.loadAllQuestionsForEvent({eventId: this.selectedEvent})

        const data=[]

        if(this.episodeList.length>0){
            for(var i=0;i<this.episodeList.length;i++){
                const node1 = {
                    eventId: this.episodeList[i],
                    participantId: this.episodeList[i],
                    questionId: this.questionList['devform'][0]
                }
                const node2 = {
                    eventId: this.episodeList[i],
                    participantId: this.episodeList[i],
                    questionId: this.questionList['devform'][1]
                }
                const node3 = {
                    eventId: this.episodeList[i],
                    participantId: this.episodeList[i],
                    questionId: this.questionList['devform'][2]
                }
                const node4 = {
                    eventId: this.episodeList[i],
                    participantId: this.episodeList[i],
                    questionId: this.questionList['devform'][3]
                }
                data.push(node1)
                data.push(node2)
                data.push(node3)
                data.push(node4)
            }
            await this.loadQResponses({eventId: this.selectedEvent, data: JSON.stringify(data)})
        }
        this.dataReady=true

    },
    data(){
        return {
            dataReady: false,
          //  entryTypes: null,
            renderEpisode: false,
            selectedEpisode: null,
            remarkType: null,
            editRemarkDialog: false,
            editorText: null,
            edittingJournalId: null,
            imageResize: {},
            /*
            editorSettings: {
                modules:{
                    imageResize: {},
                    divider: true, 
                    toolbar: {
                        container:  "#toolbar",
                        handlers: {
                            image: this.imageHandler,
                            fileupload: () => {this.fileuploadHandler()}
                        }
                    }
                }
            },
            customModulesForEditor: [
                { alias:    "imageResize", module:ImageResize }
            ]
                */
        }
    }

}
</script>

<style scoped>
.content {
    min-height: var(--minheight);
}

.ql-editor{
    min-height: 2.5em !important;
}

.author {
    padding-left: 10px;
    font-size: 0.8em;
    font-style:italic;
}
.journal-pill{
    display: inline-flex;
    flex-direction:column;
    border-radius:10px;
    background-color: #BA43B6;
    color: white;
    height: 3.5em;
    padding: 0 2em 0 2em;
}
.journal-title-content{
    margin-top: auto;
    margin-bottom: auto;
}
.journal-text {
    font-size:1.5em;
    font-weight:900;
}

.edit-pill {
    display:inline-flex;
    border-radius: 10px;
    border-bottom-left-radius: 10px;
    padding: 0px 10px 0px 10px;
    height:1.5em;
    cursor:pointer
}

.edit-pill-text{
    font-weight:900;
    color: white;
    margin-top:auto;
    margin-bottom:auto;
    font-size:0.8em;

}

.title-pill {
    display:inline-flex;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    padding: 0px 10px 0px 10px;
    height:1.5em;
}

.title-pill-text{
    font-weight:900;
    color: white;
    margin-top:auto;
    margin-bottom:auto;
    font-size:0.8em;

}

.title-pill.small {
    border-top-right-radius: 10px;
    border-bottom-left-radius: 0px;
    display:flex;
    flex-flow:row;
}

.skill-pill {
    display:inline-block;
    border-radius: 10px;
    padding: 0px 10px 0px 10px;
    font-weight:900;
    color: white;
    background-color: blue;
    margin:5px;
}

.skill-pill.none{
    background-color: black;
    border-color: blue;
}

.date-pill{
    display:inline-flex;
    flex-flow:right;
    margin-top:auto;
    margin-bottom:auto;
    border-top-right-radius:10px;
    border-bottom-right-radius:10px;
    border-style:solid;
    border-color:blue;
    padding-left: 1em;
    height:1.5em;
    text-align:left;
}

.date-pill.small{
    border-top-right-radius:0px;
    border-bottom-left-radius: 10px;
    height:2em;
    font-size:x-small;
}

.date-pill-text{
    font-size:0.8em;
    margin-top:auto;
    margin-bottom:auto;
    padding-right:0.8em;
}

.date-pill.none{
    border-color:black;
}

.paperclip{
    /*
    stroke: black;
    stroke-width:2;
    */
}

.paperclip:hover{
    stroke:blue;
}

.question-summary{
    padding-left:20px;
}
.question-text{
    font-size: x-small;
    font-weight:600;
}

.response-text{
    font-size:small;
}
</style>