<template>
    <loading v-if='loading'/>
    <div v-else-if="true">
        <v-app-bar class="participant-bar" color="var(--cs1-lev2)">
            <v-row class="no-boundaries">
                <v-col v-if="$vuetify.breakpoint.smAndDown" cols="1" style="padding-left:2px;color:white;font-size:x-small;font-weight:600;margin-top:auto;margin-bottom:auto;">
                    Journal Library
                </v-col>
                <v-col v-else cols="1" style="padding-left:2px;color:white;font-weight:600;margin-top:auto;margin-bottom:auto;">
                    Journal Library
                </v-col>
                <v-col cols="5">
                    <v-select :class="responsiveClass('participant-select')" dark label="Participant" small color="white" hide-details outlined dense v-model="selectedParticipantId" :items="participantNameList" item-text="name" item-value="id" :value="selectedParticipantId">
                    </v-select>
                </v-col>
                <v-col style="display:flex;flex-flow:row;margin-top:auto;margin-bottom:auto" >
                    <v-spacer/>
                    <JournalEntryEditor v-if="selectedParticipantId"    tinyButton type="broadcast" color="gold" :journalOwnerId="participants[selectedParticipantId].userId" style="margin-left:5px;margin-right:5px;"/>
                    <JournalEntryEditor v-if="selectedParticipantId"    tinyButton type="coachRemark" color="orange" :journalOwnerId="participants[selectedParticipantId].userId" style="margin-left:5px;margin-right:5px;"/>
                    <JournalEntryEditor v-if="selectedParticipantId"    tinyButton type="staffNote" color="grey" :journalOwnerId="participants[selectedParticipantId].userId" style="margin-left:5px;margin-right:5px;"/>

                </v-col>
            </v-row>
        </v-app-bar>
        <JournalTimeline v-if="selectedParticipantId !== null && participants[selectedParticipantId]" mode="coach" class="pt-2 timeline-wrapper" :userId="participants[selectedParticipantId].userId"/>
        <div v-else>
            <h3 class="ma-5">Select a participant to display journal entries</h3>
        </div>
    </div>
    <div v-else style="display:flex;flex-flow:row" >
        <v-navigation-drawer permanent :expand-on-hover="$vuetify.breakpoint.smAndDown" :mini-variant.sync="miniDrawer" style="min-height:70vh">
            <div v-if="miniDrawer">
                <v-avatar v-if="selectedParticipantId !== null" size="50">
                    <participant-profile-image :participantId="selectedParticipantId" size="50px"/>
                </v-avatar>
                <v-avatar v-else>
                    <v-icon>
                        mdi-account-group
                    </v-icon>
                </v-avatar>
                <v-divider/>
                <div style="transform: rotate(-90deg) translateX(-230px) ; font-weight:600;">
                    Participants
                </div>
            </div>
            <div v-else>
                <div v-if="selectedParticipantId !== null && participants[selectedParticipantId]" style="padding-left:5px;padding-right:5px;">
                    <v-avatar size="90">
                        <participant-profile-image :participantId="selectedParticipantId" size="90px"/>
                    </v-avatar>
                    <div style="font-weight:600;">
                        {{ participants[selectedParticipantId].firstName }} {{ participants[selectedParticipantId].lastName }}
                    </div>
                    <!-- 
                    <div>
                        Preferred Roles:
                    </div>
                    <div v-if="participants[selectedParticipantId].prefRole1 && participants[selectedParticipantId].prefRole2">
                        <div class="skill-pill" v-if="participants[selectedParticipantId].prefRole1">
                            {{ roles[participants[selectedParticipantId].prefRole1].name }}
                        </div>
                        <div class="skill-pill" v-if="participants[selectedParticipantId].prefRole2">
                            {{ roles[participants[selectedParticipantId].prefRole2].name }}
                        </div>
                    </div>
                    <div v-else>
                        <div class="skill-pill none">
                            No Preference Supplied
                        </div>
                    </div>
                -->
                    <JournalEntryEditor type="broadcast" color="gold" :journalOwnerId="participants[selectedParticipantId].userId"/>
                    <JournalEntryEditor type="coachRemark" color="orange" :journalOwnerId="participants[selectedParticipantId].userId"/>
                    <JournalEntryEditor type="staffNote" color="grey" :journalOwnerId="participants[selectedParticipantId].userId"/>

                    <small><sup>*</sup> Staff notes are not visible to participants</small>
                    <v-divider />
                </div>
                <v-list class="participant-list" nav dense >
                    <v-list-item link v-for="participantId in participantList" :key="participantId" @click="selectParticipant(participantId)">
                        <v-list-item-title style="display:flex;flex-flow:row;text-align:left">
                            <v-icon  style="margin-right:10px" small>mdi-notebook</v-icon>
                            {{ participants[participantId].lastName }}, {{ participants[participantId].firstName }}
                        </v-list-item-title>
                    </v-list-item>
                </v-list>
            </div>
        </v-navigation-drawer>
        <JournalTimeline v-if="selectedParticipantId !== null && participants[selectedParticipantId]" mode="coach" class="pt-0 timeline-wrapper" :userId="participants[selectedParticipantId].userId"/>
        <div v-else>
            <h3 class="ma-5">Select an athlete to display journal entries</h3>
        </div>
    </div>
</template>

<script>
import Loading from '@/components/loading'
import ParticipantProfileImage from '@/components/ParticipantProfileImage'
import { mapActions, mapState } from 'vuex';
import JournalTimeline from '@/Journal/JournalTimeline'

import JournalEntryEditor from './JournalEntryEditor.vue'

export default{
    props: [],
    components:{
        Loading,
        ParticipantProfileImage,
        JournalTimeline,
        JournalEntryEditor,
    },
    computed: {
        ...mapState({
            participants:           state => state.participantStore.data,
            participantList:        state => state.participantStore.listByFirstName,
            participantStatus:      state => state.participantStore.status,

            sessions:               state => state.sessionStore.data,
            sessionList:            state => state.sessionStore.list,
            sessionStatus:          state => state.sessionStore.status,

            roles:                  state => state.roleStore.data,
            roleStatus:             state => state.roleStore.status,

            selectedTryout:         state => state.navigatorStore.selectedTryout,
            selectedNav:            state => state.navigatorStore.selectedNav,
            selectedSeason:         state => state.navigatorStore.selectedSeason,

            teams:                  state => state.teamStore.data,

            tryouts:                state => state.tryoutStore.data,

            episodes:               state => state.episodeStore.data,
            episodeList:            state => state.episodeStore.list,

            account:                state => state.account.user.details,
        }),
        loading(){
            return this.participantStatus.loading ||
                   !this.dataReady ||
                false;
        },
        isAthlete(){
            if(this.selectedNav==='Team'){
                return (this.teams[this.selectedTryout].athlete && this.participants[this.selectedParticipantId].userId === this.account.id)
            } else {
                return (this.tryouts[this.selectedTryout].athlete && this.participants[this.selectedParticipantId].userId === this.account.id)
            }
        },
        participantNameList(){
            const results=[]
            for(var i=0;i<this.participantList.length;i++){
                results.push({
                    id: this.participantList[i],
                    name: `${this.participants[this.participantList[i]].firstName} ${this.participants[this.participantList[i]].lastName}`
                })
            }
            return results
        },
        selectedParticipantId:{
            get(){
                return this.i_selectedParticipantId
            },
            set(newValue){
                this.setSelectedParticipant(newValue)
                this.i_selectedParticipantId = newValue
            }

        }
    },
    methods: {
        ...mapActions('participantStore',[
            'loadAllParticipantsForEvent',
            'loadAllParticipantsForTeam',
        ]) ,
        ...mapActions('journalentryStore',[
            'createNewJournalEntry',
            'createTeamJournalEntry',
            'toggleReadActivated',
        ]),
        ...mapActions('sessionStore', [
            'loadAllSessionsForEvent'
        ]),
        ...mapActions('roleStore', [
            'loadAllRolesForEvent',
            'loadAllRolesForTeam',
        ]),
        ...mapActions('awsStore', {
            deleteAsset: 'deleteAsset'
        }),
        ...mapActions('navigatorStore', [
            'setSelectedParticipant'
        ]),
        selectParticipant(participantId){

            this.selectedParticipantId=participantId
        },
        responsiveClass(className){
            if(this.$vuetify.breakpoint.smAndDown){
                return className+' mobile'
            }
            return className
        },

    },
    watch: {
        async selectedTryout(){
            if(this.selectedNav ==='Team'){
                this.dataReady= false
                await this.loadAllParticipantsForTeam({teamId: this.selectedTryout})
                await this.loadAllRolesForTeam({teamId: this.selectedTryout})
                this.dataReady= true
            } else {
                this.dataReady= false
                await this.loadAllParticipantsForEvent({eventId: this.selectedTryout})
                await this.loadAllRolesForEvent({eventId: this.selectedTryout})
                this.dataReady= true
            }

        }
    },
    async created(){
        if(this.selectedNav ==='Team'){
            await this.loadAllParticipantsForTeam({teamId: this.selectedTryout})
            await this.loadAllRolesForTeam({teamId: this.selectedTryout})
        } else {
            await this.loadAllParticipantsForEvent({eventId: this.selectedTryout})
            await this.loadAllRolesForEvent({eventId: this.selectedTryout})
        }
    },
    data(){
        return {
            i_selectedParticipantId: null,
            athleteDrawer: false,
            miniDrawer: this.$vuetify.breakpoint.smAndDown,
            dataReady: true,
        }
    }

}
</script>

<style>
.gold{
    background-color:gold; 
}

.timeline-wrapper {
    width: calc(100% - 56px);
}

.read{
    cursor:pointer;
    font-style:italic;
    color:blue;
}


.participant-list {
    height: 60vh;
    overflow-y:auto;
}

.content {
    width: 80vw;
}
.participant-bar{
    background-color: var(--cs1-lev2);
    padding-left:5px;
    margin-left:0px;
    margin-right:0px;
    margin-bottom:2px;
}
.participant-list-label{
    font-size: small;
    color: white;
    font-weight:600;
    padding-left:5px;
}

.paperclip{
    /*
    stroke: black;
    stroke-width:2;
    */
}

.paperclip:hover{
    stroke:blue;
}
</style>