<template>
        <div style="text-align:left;">
            <v-row v-if="false">
                <v-spacer/>
                <v-col cols="2" style="text-align:right">
                    <v-spacer/>
                    <v-btn @click.native.stop 
                        v-if="changes" 
                        x-small 
                        v-on:click="saveDetails" 
                        depressed 
                        color="primary" 
                        class="save-btn"
                    >
                        Save
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-text-field label="Title" :rules="[rules.required, rules.length]" outlined hide-details="auto" v-model="title"/>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-textarea label="Description" :rules="[rules.required]" outlined hide-details="auto" v-model="description"/>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-divider/>
                </v-col>
                <v-col cols="12" class="text-left">
                    <h5>Selection Trial Period</h5>
                    <span class="note">Note: This is the start and end of the selection timeframe. You will specifiy specific trial dates in the "Tryout Sessions" section</span>
                </v-col>
            </v-row>
            <v-row >
                <v-col cols="6">
                    <DateCombo title="Trial Start Date" :rules="[rules.required]" v-model="startDate"/>
                </v-col>
                <v-col cols="6">
                    <DateCombo title="Trial End Date" :rules="[rules.required]" v-model="endDate"/>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-divider/>
                </v-col>
                <v-col cols="12" class="text-left">
                    <h5>Offer Dates</h5>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="6">
                    <DateCombo title="Offer Deadline" :rules="[rules.required]" v-model="offerDeadline"/>
                </v-col>
            </v-row>
            <v-row>
                <v-divider></v-divider>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <h4>Data Confidence settings</h4>
                    <br/>
                    These settings control when player buttons change color based on data collected. <br/>All staff registered as selectors contribute, regardless of the number of "selectors" actually collecting data, so make sure you have the correct roles assigned to trial staff. <br/>Settings of 15% for minimum staff and 25% for minimum data usually give good indications with typical staff structures.

                </v-col>
                <v-col cols="3">
                    <v-text-field class="percent" hint="This is the minimum number of staff you need data from to consider the information to be usable" label="Minimum Staff for confidence" :rules="[rules.required, rules.percent]" outlined hide-details="auto" v-model="minStaff" suffix="%"/>
                </v-col>
                <v-col cols="3">
                    <v-text-field class="percent" hint="This is the minimum amount of data collected (the max is the # criteria x # staff) to be considered valuable" label="Minimum Data for confidence" :rules="[rules.required, rules.percent]" outlined hide-details="auto" v-model="minData" suffix="%"/>
                </v-col>
            </v-row>
            <v-row>
                <v-divider></v-divider>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <h4>Clear Tryout Data</h4>
                    <div>
                        <span style="color:red">Warning: This action is irreversible</span>
                    </div>
                    <v-dialog v-model="confirmClear" persistent max-width="600px">
                        <template v-slot:activator = "{on,attrs}">
                            <v-btn 
                                color="primary"
                                v-on="on"
                                v-bind="attrs"
                            >
                                Delete all tryout data
                            </v-btn>
                        </template>
                        <v-card>
                            <v-card-title>
                                <span class="text-h5">Are you sure?</span>
                            </v-card-title>
                            <v-card-text>
                                <v-row>
                                    <v-col>
                                        <span>Really, this action cannot be undone. We recommend to only use this to clear out data that has been used by staff to familiarise themselves with the system. </span>
                                        <span style="color:red">If you have an active tryout, active trial data for the tryout will be irretrievable deleted (athlete lists are retained)</span>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer/>
                                <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="confirmClear=false"
                                >
                                    Cancel
                                </v-btn>
                                <v-btn
                                    color="red darken-1"
                                    text
                                    @click="deleteTryoutData"
                                >
                                    Delete Trial Data Permanently
                                </v-btn>
                            </v-card-actions>
                        </v-card>

                    </v-dialog>
                </v-col>
            </v-row>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import {rules} from '@/_helpers/rules'
import moment from 'moment'
import DateCombo from '@/components/DateCombo.vue'

import {v4 as uuidv4} from 'uuid';
export default {
    props: [],
    components: {
        DateCombo

    },
    computed: {
        ...mapState({
            selectedTryout: state => state.navigatorStore.selectedTryout,
            tryouts: state => state.tryoutStore.data,
            tryoutStatus: state => state.tryoutStore.status,
            tryoutMeta: state => state.tryoutStore.meta,

        }),
        loading: {
            get() {
                return this.tryoutStatus.loading
            }
        },
        startDate: {
            get(){
                return this.tryoutMeta[this.selectedTryout].startDate
            },
            set(value){
                this.changes=true
                this.$root.$emit("settings-changes-made", this.componentId)
                this.updateData({field:'startDate', id: this.selectedTryout, value})
            }
        },
        endDate: {
            get(){
                return this.tryoutMeta[this.selectedTryout].endDate
            },
            set(value){
                this.changes=true
                this.$root.$emit("settings-changes-made", this.componentId)
                this.updateData({field:'endDate', id: this.selectedTryout, value})
            }
        },
        offerDeadline: {
            get(){
                return this.tryoutMeta[this.selectedTryout].offerDeadline
            },
            set(value){
                this.changes=true
                this.$root.$emit("settings-changes-made", this.componentId)
                this.updateData({field:'offerDeadline', id: this.selectedTryout, value})
            }
        },
        title: {
            get(){
                return this.tryouts[this.selectedTryout].title
            },
            set(value){
                this.changes=true
                this.$root.$emit("settings-changes-made", this.componentId)
                this.updateData({field: 'title',id: this.selectedTryout,  value})
            }
        },
        description: {
            get() {
                return this.tryouts[this.selectedTryout].description
            },
            set(value) {
                this.changes=true
                this.$root.$emit("settings-changes-made", this.componentId)
                this.updateData({field:'description', id: this.selectedTryout, value})
            }
        },
        minStaff: {
            get(){
                return this.tryouts[this.selectedTryout].minSelectorsForConfidence
            },
            set(newValue){
                this.$root.$emit("settings-changes-made", this.componentId)
                this.changes=true
                this.updateData({field: 'minSelectorsForConfidence',id: this.selectedTryout, value:newValue})
            }
        },
        minData: {
            get(){
                return this.tryouts[this.selectedTryout].minDataForConfidence
            },
           set(newValue){ 
                this.$root.$emit("settings-changes-made", this.componentId)
                this.changes=true
                this.updateData({field: 'minDataForConfidence',id: this.selectedTryout, value: newValue})
            }
        }

    },
    methods: {
        ...mapActions('tryoutStore', {
            updateData: 'update',
            updateTryout: 'updateTryout',
            clearTryout: 'clearTryout',
            loadTryout: 'loadTryout',

        }),
        formatDate: function(d) {
            return d
                ? moment(d).format("DD/MM/YYYY")
                : "";


        },
        formatDateTime: function(d) {
            return d
                ? moment(d).format('llll')
                : ""
        },
        deleteTryoutData: function(){
            this.clearTryout(this.selectedTryout)
            this.confirmClear=false
        },
        async saveDetails(){
            this.updateTryout(this.tryouts[this.selectedTryout])
            this.$root.$emit("settings-changes-cleared", this.componentId)
            this.$store.dispatch('alert/success', 'Tryout updated', {root: true})
            this.changes=false
        }
    },
    mounted(){
        this.$root.$on('settings-clear-changes-flags', ()=> {
            var self=this
            self.changes=false
        })

        this.$root.$on('submit-all-changes', () => {
            if(this.changes){
                this.saveDetails()
            }
        })

        this.$root.$on('backout-all-changes', () => {
            if(this.changes){
                this.loadTryout(this.selectedTryout)
            }
        })

    },
    created(){
        this.componentId = uuidv4()
    },
    data() {
        return {
            componentId:'',
            changes: false,
            rules: rules,
            startDateMenu: false,
            endDateMenu: false,
            offerDeadlineMenu: false,
            tempDate: null,
            confirmClear: false,
        }
    }

}
</script>

<style scoped>
.note{
    font-size: small;
    font-style:italic;
}

.percent input{
    text-align: right;
    color:green;
}
.percent {
    color:green;
}

</style>