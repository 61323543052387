<template>
    <v-expansion-panel>
        <v-expansion-panel-header disable-icon-rotate>
            <div :class="{strike: meta[attributeId].archive}">
                {{attributes[attributeId].title}}
            </div>
            <template v-slot:actions>
                <v-btn @click.native.stop
                    v-if="false&&changes"
                    x-small
                    v-on:click="saveDetails"
                    depressed
                    color="primary"
                    class="save-btn"
                >
                    Save
                </v-btn>
                <v-dialog v-model="confirmDeleteDialog" max-width="60%">
                    <template v-slot:activator="{on, attrs}">
                        <v-icon small color="red" v-on="on" v-bind="attrs">mdi-delete</v-icon>
                    </template>
                    <v-card>
                        <v-card-title>Confirm Athlete Attribute Deletion</v-card-title>
                        <v-card-text>
                            You cannot undo this operation. All information for this athlete attribute will be deleted permanently. Are you sure you want to continue?
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer/>
                            <v-btn text @click="confirmDeleteDialog=false">Close</v-btn>
                            <v-btn color="red darken-1" text @click="confirmedDelete">Delete</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <!-- 
                <v-icon small v-if="flagToDelete" @click.stop="unflagForDeletion">mdi-delete-restore</v-icon>
                <v-icon small v-else color="red" @click.stop="flagForDeletion">mdi-delete</v-icon>
                -->
                <v-icon>mdi-chevron-down </v-icon>
            </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>

            <v-text-field
                v-model="title"
                prepend-icon="mdi-rename-box"
                label="Title"
            />
            <v-text-field
                v-model="description"
                prepend-icon="mdi-information"
                label="Description"
            />
            <v-text-field
                v-model="units"
                prepend-icon="mdi-information"
                label="Units"
            />

        </v-expansion-panel-content>

    </v-expansion-panel>

</template>

<script>
import { mapState, mapActions} from 'vuex'
import {v4 as uuidv4} from 'uuid'

export default {
    name: "attribute-editor",
    props: ['attributeId'],
    components: {
    },
    computed: {
        ...mapState({
            attributes: state => state.attributeStore.data,
            list: state => state.attributeStore.list,
            meta: state => state.attributeStore.meta,
            status: state => state.attributeStore.status,
            error: state => state.attributeStore.error
        }),
        loading: function() {
            return this.status.loading
        },

        title: {
            get() {
                return this.attributes[this.attributeId].title
            },
            set(value) {
                this.flagChanges()
                return this.propertyUpdate({field: 'title', id: this.attributeId, value})
            }
        },
        description: {
            get() {
                return this.attributes[this.attributeId].description
            },
            set(value) {
                this.flagChanges()
                return this.propertyUpdate({field: 'description', id: this.attributeId, value})
            }
        },
        units: {
            get() {
                return this.attributes[this.attributeId].units
            },
            set(value) {
                this.flagChanges()
                return this.propertyUpdate({field: 'units', id: this.attributeId, value})
            }
        },

        flagToDelete: {
            get() {
                return this.meta[this.attributeId].archive
            },
            set(value) {
                this.flagChanges()
                const result =  this.propertyUpdate({field: 'archive', id: this.attributeId, value})
                return result
            }
        },
    },
    mounted(){
        this.$root.$on('settings-clear-attribute-changes-flags', ()=> {
            var self=this
            self.changes=false
        })
        this.$root.$on('submit-all-changes', () => {
            var self=this
            if(this.changes){
                this.saveDetails()
                self.changes=false
            }
        })
    },
    methods: {
        ...mapActions('attributeStore', {
            propertyUpdate: 'propertyUpdate',
            updateAttribute: 'update',
            archiveAttribute: 'archive',
        }),
        flagForDeletion: function() {
            this.flagToDelete=true
        },
        unflagForDeletion: function() {
            this.flagToDelete=false
        },
        flagChanges(){
            this.changes=true
            this.$root.$emit("settings-changes-made", this.componentId)
//            this.$root.$emit("settings-attribute-changes-made", this.componentId)
        },
        async saveDetails(){
            this.changes=false
            this.updateAttribute(this.attributes[this.attributeId])
            this.$root.$emit("settings-attribute-changes-cleared", this.componentId)
            this.$root.$emit("settings-changes-cleared", this.componentId)
            this.$store.dispatch('alert/success', 'Tryout Updated', {root:true})
        },
        confirmedDelete(){
            this.archiveAttribute(this.attributeId)
            this.confirmDeleteDialog=false
            this.$store.dispatch('alert/success', 'Athlete attribute deleted', {root:true})
        },
    },
    created(){
        this.componentId = uuidv4()
    },
    data(){
        return {
            confirmDeleteDialog:false,
            componentId: 0,
            changes: false,
        }
    }

}

</script>
<style>


</style>