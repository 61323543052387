<template>
    <loading v-if='loading'/>
    <div v-else>
        <v-app-bar class="participant-bar" color="var(--cs1-lev2)">
            <v-row class="no-boundaries">
                <v-col v-if="$vuetify.breakpoint.smAndDown" cols="1" style="padding-left:2px;color:white;font-size:x-small;font-weight:600;margin-top:auto;margin-bottom:auto;">
                    Episode Overview
                </v-col>
                <v-col v-else cols="1" style="padding-left:2px;color:white;font-weight:600;margin-top:auto;margin-bottom:auto;">
                    Episode Overview
                </v-col>
                <v-col cols="5">
                    <v-select :class="responsiveClass('participant-select')" dark label="Coach" small color="white" hide-details outlined dense v-model="selectedCoachId" :items="coachNameList" item-text="name" item-value="id" :value="selectedCoachId">
                    </v-select>
                </v-col>
            </v-row>
        </v-app-bar>


        <h3>Coach Episode List</h3>
        <v-list v-if="episodeList.length>0">
            
            <v-simple-table dense>
                <template v-slot:default>
                    <thead>
                        <tr>
                            <td class="text-left tablehead">Published</td>
                            <td class="text-left tablehead">Date</td>
                            <td class="text-left tablehead">Coach</td>
                            <td class="text-left tablehead">{{ capitalize($pluralize(events[selectedEvent].participantPronoun)) }}</td>
                            <td v-for="n in events[selectedEvent].numSummaryQuestions" :key="n" class="text-left tablehead">
                                {{ questions[questionList['devform'][n-1]].question }}
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="episode in episodes" :key="'a'+episode.id" @click="viewEpisode(episode.id)">
                            <td class="text-left">
                                <div v-if="episode.published" class="role-pill " style="text-align:center;width:60px;padding-right:20px;background-color: green;margin-top:auto;margin-bottom:auto">Published</div>
                                <div v-else class="role-pill" style="text-align:center;width:60px;padding-right: 20px; background-color: purple; margin-top: auto; margin-bottom: auto;">Not Published</div>
                            </td>
                            <td class="text-left">{{new Date(episode.date).toLocaleString()}}</td>
                            <td class="text-left">
                                <div v-for="staff in episode.staffList" :key="staff.id" style="font-size:x-small;text-align:left">{{ staff.firstName }} {{ staff.lastName }}</div>
                            </td>
                            <td>
                                <div v-for="participant in episode.participantList" :key="participant.id" style="font-size:x-small;text-align:left">{{ participants[participant.participantId].firstName }} {{ participants[participant.participantId].lastName }}</div>
                                <!--
                                <div v-for="participant in episode.participantList" :key="participant.id" style="font-size:x-small;text-align:left">{{ participant.participantId}} </div>
                            -->
                            </td>
                            <td v-for="n in events[selectedEvent].numSummaryQuestions" :key="n" class="text-left">
                                {{ renderResponse(episode.id, questionList['devform'][n-1])}}
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-list>
        <div v-else style="margin-top:20px;">
            There are no episodes available at this time
        </div>


    </div>
</template>

<script>
import Loading from '@/components/loading'

import {mapState, mapActions} from 'vuex' 

export default{
    props: [],
    components:{
        Loading
    },
    computed: {
        ...mapState({
            episodes:               state => state.episodeStore.data,
            episodeList:            state => state.episodeStore.list, 
            questions:              state => state.appquestionStore.data,
            questionList:          state => state.appquestionStore.listByContext,
            responses:              state => state.qresponseStore.individual,
            events:                 state => state.tryoutStore.data,
            account:                state => state.account.user.details,

            staff:                  state => state.staffStore.data,
            staffList:              state => state.staffStore.list,
            participants:           state => state.participantStore.data,

            selectedEvent:          state => state.navigatorStore.selectedTryout,
        }),
        loading(){
            return !this.dataReady||
                false;
        },
        coachNameList(){
            const results=[]
            for(var i=0;i<this.staffList.length;i++){
                console.log(this.staffList[i])
                console.log(this.staff[this.staffList[i]])
                results.push({
                    id: this.staff[this.staffList[i]].id,
                    name: `${this.staff[this.staffList[i]].firstName} ${this.staff[this.staffList[i]].lastName}`
                })
                console.log('Pushed')
            }
            return results
        },

    },
    methods: {
        ...mapActions('episodeStore', [
            'loadAllEpisodesForUserAndEvent',
            'loadAllEpisodesForEvent'
        ]),
        ...mapActions('navigatorStore', [
            'setSelectedEpisode',
            'selectClubPane',
        ]),
        ...mapActions('appquestionStore', [
            'loadAllQuestionsForEvent',
        ]),
        ...mapActions('qresponseStore',[
            'loadAllQResponsesForEvent',
            'loadQResponses',
        ]),
        ...mapActions('staffStore', [
            'findAllStaffForEvent',
        ]),
        ...mapActions('participantStore', [
            'loadAllParticipantsForEvent'
        ]),
        viewEpisode(episodeId){
            this.setSelectedEpisode(episodeId)
            this.selectClubPane('DevStreamEpisode')
        },
        renderResponse(episodeId, responseId){

            if(this.responses[episodeId][episodeId][responseId]===null) return '---'
            const response = this.responses[episodeId][episodeId][responseId].response 
            if(response === null || !responseId) return '---'

            const type = this.questions[this.responses[episodeId][episodeId][responseId].questionId].type
            var options=[]

            switch(type){
                case 'short': 
                    return response
                case 'select': 
                    options = JSON.parse(this.questions[this.responses[episodeId][episodeId][responseId].questionId].options)
                    return options[response].text
                case 'checkbox':
                    return response?"Yes":"No"
            } 

        },
        responsiveClass(className){
            if(this.$vuetify.breakpoint.smAndDown){
                return className+' mobile'
            }
            return className
        },

    },
    watch: {
        async selectedCoachId(){
            this.dataReady=false
            const data=[]
            // Fetch the data dor this participant
            await this.loadAllEpisodesForUserAndEvent({eventId: this.selectedEvent, userId: this.selectedCoachId})
            if(this.episodeList.length>0){
                for(var i=0;i<this.episodeList.length;i++){
                    const node1 = {
                        eventId: this.episodeList[i],
                        participantId: this.episodeList[i],
                        questionId: this.questionList['devform'][0]
                    }
                    const node2 = {
                        eventId: this.episodeList[i],
                        participantId: this.episodeList[i],
                        questionId: this.questionList['devform'][1]
                    }
                    const node3 = {
                        eventId: this.episodeList[i],
                        participantId: this.episodeList[i],
                        questionId: this.questionList['devform'][2]
                    }
                    const node4 = {
                        eventId: this.episodeList[i],
                        participantId: this.episodeList[i],
                        questionId: this.questionList['devform'][3]
                    }
                    data.push(node1)
                    data.push(node2)
                    data.push(node3)
                    data.push(node4)
                }
                await this.loadQResponses({eventId: this.selectedEvent, data: JSON.stringify(data)})
            }
            
            this.dataReady=true
        }
    },
    
    async created(){
        this.dataReady=false
        await this.loadAllEpisodesForUserAndEvent({eventId: this.selectedEvent, userId: this.account.id})
        //await this.loadAllEpisodesForEvent({eventId: this.selectedEvent})
        await this.loadAllQuestionsForEvent({eventId: this.selectedEvent})
        await this.findAllStaffForEvent({eventId: this.selectedEvent})
        await this.loadAllParticipantsForEvent({eventId: this.selectedEvent})
        const data=[]

        if(this.episodeList.length>0){
            for(var i=0;i<this.episodeList.length;i++){
                const node1 = {
                    eventId: this.episodeList[i],
                    participantId: this.episodeList[i],
                    questionId: this.questionList['devform'][0]
                }
                const node2 = {
                    eventId: this.episodeList[i],
                    participantId: this.episodeList[i],
                    questionId: this.questionList['devform'][1]
                }
                const node3 = {
                    eventId: this.episodeList[i],
                    participantId: this.episodeList[i],
                    questionId: this.questionList['devform'][2]
                }
                const node4 = {
                    eventId: this.episodeList[i],
                    participantId: this.episodeList[i],
                    questionId: this.questionList['devform'][3]
                }
                data.push(node1)
                data.push(node2)
                data.push(node3)
                data.push(node4)
            }
            await this.loadQResponses({eventId: this.selectedEvent, data: JSON.stringify(data)})
        }
        this.dataReady=true
    },
    data(){
        return {
            dataReady:false,
            selectedCoachId: null,

        }
    }

}
</script>

<style scoped>
.tablehead{
    font-weight: 600;
    font-size: small;
}

</style>
