<template>
    <loading v-if="loading"/>
    <div v-else class="coachsight-pane"> 
        <div width="100%" class="club-tiles">
            <v-app-bar
                color="var(--cs1-lev2)"
                class="mb-5 coachsight-pane-content"
            >
                <v-toolbar-title class="text-h6 white--text pl-0">
                    Club Rooms
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <div v-if="$vuetify.breakpoint.smAndDown">
                    <v-menu>
                        <template v-slot:activator="{on, attrs}">
                            <v-icon v-on="on" v-bind="attrs" color="white">mdi-menu</v-icon>
                        </template>
                        <v-list>
                            <v-list-item @click="findDialog=true;">
                                <v-list-item-title  class="header-links">
                                    Find a Club
                                </v-list-item-title>
                            </v-list-item>
                            <v-list-item v-if="allowCreate" @click="createNewClub">
                                <v-list-item-title class="header-links">
                                    Create a New Club
                                </v-list-item-title>
                            </v-list-item>

                        </v-list>
                    </v-menu>
                </div>
                <div v-else>
                    <v-btn color="primary" small class="coachsight-pane-button " @click="findDialog=true">
                        <v-icon>
                            mdi-magnify
                        </v-icon>
                        Find a club
                    </v-btn>
                     <v-btn v-if="allowCreate" small color="primary" dark @click="createNewClub" class="coachsight-pane-button" >
                        Create a new club
                    </v-btn>
                
                </div>
            </v-app-bar>

            <v-dialog v-model="newClubDialog" persistent width="80%" >
                <ValidationObserver ref="observer" v-slot="{ invalid, handleSubmit , reset}">
                    <form  style="margin-top:0px" ref="form" @submit.prevent="handleSubmit(submit(reset))" @reset.prevent="reset">
                        <v-card>
                            <v-card-title>
                                <span class="text-h5">Club Details</span>
                            </v-card-title>
                            <v-card-text>
                                    <v-row dense >
                                        <v-col cols="12">
                                            <ValidationProvider v-slot="{errors}" name="ClubName" rules="required" >
                                                <v-text-field label="Full Club Name" v-model="name" />
                                                <div class="field-error">{{errors[0]}}</div>
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>
                                    <v-row dense class="no-boundaries">
                                        <v-col cols="6" class="no-boundaries">
                                            <v-row class="no-boundaries" dense>
                                                <v-col cols="12" >
                                                    <ValidationProvider v-slot="{errors}" name="Country" rules="nonEmptyObject">
                                                        <v-select label="Country" :items="countries" item-text="name" item-vale="id" return-object v-model="country" @focus="$event.target.click()"/>
                                                        <div class="field-error">{{errors[0]}}</div>
                                                    </ValidationProvider>
                                                </v-col>
                                                <v-col cols="12" >
                                                    <ValidationProvider v-slot="{errors}" name="State" rules="nonEmptyObject">
                                                        <v-select :disabled="cscLoading" label="State" :items="states" item-text="name" item-value="id" return-object v-model="state" />
                                                        <div class="field-error">{{errors[0]}}</div>
                                                    </ValidationProvider>
                                                </v-col>
                                                <v-col cols="12" >
                                                    <ValidationProvider v-slot="{errors}" name="City" >
                                                        <v-select label="City" :items="cities" item-text="name" item-value="id" return-object v-model="city" />
                                                        <div class="field-error">{{errors[0]}}</div>
                                                    </ValidationProvider>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="6" class="no-boundaries">
                                            <v-col cols="12" >
                                                <ValidationProvider v-slot="{errors}" name="Short Club Name" rules="required">
                                                    <v-text-field label="Short Code for Club" hint="Try the initials of your club" v-model="shortCode" />
                                                    <div class="field-error">{{errors[0]}}</div>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="12" >
                                                <ValidationProvider v-slot="{errors}" name="Sport" rules="required">
                                                    <v-select :items="sportsArray" label="Your Sport" chips v-model="selectedSport" />
                                                    <div class="field-error">{{errors[0]}}</div>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="12" >
                                                <v-text-field label="Other Sports" hint="Tell us if you sport is not listed here" persistent-hint v-model="otherSport" />
                                            </v-col>
                                        </v-col>
                                    </v-row>
                                    <v-row dense class="no-boundaries">
                                        <v-col cols="12" > 
                                            <ValidationProvider v-slot="{errors}" name="ClubDesc" rules="max:2000">
                                                <v-textarea outlined v-model="description" label="Description of your club" counter />
                                                <div class="field-error">{{errors[0]}}</div>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-text-field label="Club Website" hint="The full URL for your club's website" persistent-hint v-model="website" />
                                        </v-col>
                                        <v-col cols="6">
                                            <v-text-field label="Text for website link" hint="The text that will appear in place of the full URL for your home website" persistent-hint v-model="linkText" />
                                        </v-col>
                                        <v-col cols="12" class="text-left">
                                            <ImageUpload delaySave  preview label="Club Logo" type="Club Logo" :cropper="true" />
                                        </v-col>
                                    </v-row>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="blue darken-1"
                                    text
                                    @click="closeDialog(reset)"
                                >
                                    Close
                                </v-btn>
                                <v-btn
                                    color="blue darken-1"
                                    text
                                    type="submit"
                                    :disabled="invalid"
                                >
                                    Save
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </form>
                </ValidationObserver>
            </v-dialog>
            <v-dialog 
                v-model="findDialog"
                persistent
                max-width="600px"
            >
                <v-card>
                    <v-card-title>
                        <span class="text-h5">Find a club</span>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="closeFindDialog">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <v-text-field
                                    label="Club Name"
                                    v-model="findName"
                                >
                                </v-text-field>
                                
                            </v-col>
                        </v-row>
                    </v-container>

                    <v-simple-table v-if="searchList.length">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th> </th>
                                    <th>Club Name</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="id in searchList" :key="id">
                                    <td>
                                        <v-avatar
                                            size="25"
                                            class="elevation-10"
                                        >
                                            <img :src="clubs[id].logo">
                                        </v-avatar>
                                    </td>
                                    <td class="text-left">
                                        {{clubs[id].name}}
                                    </td>
                                    <td>
                                        <v-btn x-small
                                            @click="goToClub(id)" 
                                        >
                                            Visit Club
                                        </v-btn>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card>
            </v-dialog>
            <template v-if="clubList.length>0">
                <v-row class="coachsight-content hero">
                    <v-col v-for="clubId in clubList" :key="`o`+clubId" xl="1" lg="2" md="2" sm="6" xs="6" style="margin-top:0px">
                        <v-hover v-slot="{hover}">
                            <v-card
                                :elevation="hover?12:0" 
                                :class="{'on-hover':hover, 'hover-card':true}"
                                rounded
                                @click="goToClub(clubId)"
                            >
                                <v-img 
                                    :src="clubs[clubId].logo?clubs[clubId].logo:sports[clubs[clubId].sportId].logo"
                                    class="white--text align-end"
                                    gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,0.5)"
                                    height="200px"
                                >
                                </v-img>
                                <v-card-actions>
                                    <v-card-title class="card-title">{{ clubs[clubId].name }}</v-card-title>
                                </v-card-actions>
                            </v-card>
                        </v-hover>
                    </v-col>
                </v-row>
            </template>
            <template v-else>
                <v-row class="coachsight-content hero">
                    <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <div class="welcome-frame">

                            <div class="welcome-info-frame">
                                <div class="welcome-head">
                                    Welcome to CoachSight!
                                </div>

                                <div class="welcome-info">
                                    We're guessing you are new around these parts since you're not attached to any clubs yet. 

                                </div>
                                <div class="welcome-info">

                                Coming soon, we will have some training videos here for you to learn more about what coachsight can do to make your life as a coach, a club administrator or a trial participant better. In the meantime, sit tight until your club administrator gets back to you on next steps.
                                </div>
                            </div>
                        </div>

                    </v-col>
                    <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                            <div class="welcome-image">
                                <v-img src="https://s3.ap-southeast-2.amazonaws.com/www.coachsight.net/app_assets/hockey-field-coach.png"></v-img>
                                <v-img class="welcome-logo" src="https://s3.ap-southeast-2.amazonaws.com/www.coachsight.net/app_assets/Totally-White-animated-icon.gif"/>
                            </div>
                    </v-col>
                </v-row>
            </template>
        </div>
    </div>
</template>



<script>
import {mapState, mapActions} from 'vuex'
import Loading from "@/components/loading"
import Vue from 'vue'
import {router} from '../_helpers'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import ImageUpload from '@/components/ImageUpload'

import {
  VExpansionPanels
} from 'vuetify/lib'
Vue.component('VExpansionPanels', VExpansionPanels)


export default  {
    name: "club-panel",
    props: [],
    components: {
        Loading,
        ValidationObserver,
        ValidationProvider,
        ImageUpload,
    },
    computed: {
        ...mapState({
            account: state => state.account,
            clubs: state => state.clubStore.data,
            clubStatus: state => state.clubStore.status,
            clubList: state => state.clubStore.list,
            searchList: state => state.clubStore.searchList,

            sports:         state => state.sportStore.data,
            sportsList:     state => state.sportStore.list,

            countries:      state=> state.cscStore.countries,
            states:         state => state.cscStore.states,
            cities:         state => state.cscStore.cities,
            cscStatus:          state => state.cscStore.status,

            authData:       state => state.authStore.data,
            authStatus:     state => state.authStore.status,
        }),

        loading: function() {
            return this.clubs.loading || this.account.status.loading 
        },
        allowCreate(){
            return this.account.user.details.beta
        },
        cscLoading: function(){
            return this.cscStatus.loading
        },
        sportsArray: function(){
            var sportsObjs=[]
            for(var idx in this.sportsList){
                sportsObjs.push({
                    text: this.sports[this.sportsList[idx]].name,
                    value: this.sportsList[idx]
                })

            }
            sportsObjs.push({
                text: 'Other',
                value: '0'
            })
            return sportsObjs
        },
        sport: function() {
            // is other a part of this?
            var sports=''

            if(this.selectedSport.includes('Other')){
                sports = sports.concat(this.selectedSport.toString(), ',', this.otherSport)
            } else {
                sports=this.selectedSport.toString()
            }
            return sports
        },
        validForm: function(){
            return this.errors.items.length <=0
        },
        country: {
            get(){
                return this.thiscountry
            },
            set(newValue){
                this.thiscountry = newValue
            }
        },
        loadingCSC(){
            return this.cscStatus.loading
        }
    },
    methods: {
        ...mapActions('authStore', {
            preAuth: 'preAuth'
        }),
        ...mapActions('navigatorStore', {
            selectClub: 'selectClub',
            selectClubPane: 'selectPane',
            selectSeason: 'selectSeason',
            selectNav: 'selectNav',

        }),
        ...mapActions('seasonStore', {
            findAllSeasonsForClub: 'findAllForClub',
            createSeason: 'create',
        }),
        ...mapActions('clubStore', {
            findAllClubsForUser: 'findAllForUser',
            createClub: 'create',
            updateClub: 'update',
            clubPropertyUpdate: 'propertyUpdate',
            searchClubs: 'searchForName',
            clearSearch: 'clearSearch',
        }),
        ...mapActions('cscStore', {
            findAllCountries: 'findAllCountries',
            findAllStates: 'findAllStates',
            findAllCities: 'findAllCities'
        }),
        ...mapActions('tryoutStore', {
            clearTryoutData: 'clearTryout',
        }),
        closeDialog(reset){
            this.newClubDialog=false
            this.file=null
            this.thiscountry= null,
            this.state= null,
            this.city=  null,
            this.name= '',
            this.description= '',
            this.selectedSport= null,
            this.otherSport= '',
            this.shortCode= '',
            this.website='',
            this.linkText='',
            this.detailedDescription='',
            this.subscription='',
            reset()
//            this.$refs.observer.reset()
        },
        uploadFile(fieldName, files){
            this.file=files[0]
        },
        async goToClub(id){
           this.selectClub(id)
           this.selectClubPane('Club')
           this.selectNav('Club')
           await this.findAllSeasonsForClub({clubId:id ,isAdmin:false})
           router.push('/clubhouse/'+id) 
        },
        closeFindDialog(){
            this.clearSearch()
            this.findDialog=false
            this.findName=''
        },
        clubLogo(clubId){
            if(this.clubs[clubId].logo) return this.clubs[clubId].logo
            return this.sports[this.clubs[clubId].sportId].logo
        },
        async submit(reset){
            await this.save(reset)
            this.newClubDialog=false
        },
        async createNewClub(){
            // Do a pre-authorisation to make sure this user is able to create a new club first...

            await this.preAuth({authQuery: 'createClub'}) 
            if(this.authData.createClub){
                this.findAllCountries()
                this.newClubDialog = true
            } else    
               Vue.$toast.error("You have reached your club limit. Please contact us for information on how to increase the number of clubs you can own or manage")
        },
        async save(reset) {
            // first create the competition
            await this.createClub({
                name: this.name,
                shortName: this.shortCode,
                description: this.description,
                country: this.country.name,
                countryCode: this.country.iso2,
                state: this.state.name,
                city: this.city.name,
                sportId: this.selectedSport,
                otherSport: this.otherSport,
                website: this.website,
                linkText: this.linkText,
                detailedDescription: this.detailedDescription,
            })
//            this.selectClub(this.clubStatus.id)
            // Now we have the ID we can save the logo in AWS
            
            // If a file has been setup...

            this.$root.$emit("image-upload-save", this.clubStatus.id )

            reset()
            this.newClubDialog=false;
        }
        
    },
    created() {
        if(this.account.user) {
            this.findAllClubsForUser({userId: this.account.user.details.id})
        }
    },
    data() {
        return {
            newClubDialog : false,
            findDialog: false,
            findName: '',
            rules: {
                required: value => !!value || 'Required',
                size: value => !value || value.size < 500000 || 'Logo size must be under 500kB',
                descMaxLength: value => value.length <2000 || 'Description can be at most 2000 characters',
                descMinLength: value => value.length > 1 || 'Description must be provided'
            },
            file: null,
            thiscountry: null,
            state: {},
            city:  '',
            name: '',
            description: '',
            selectedSport: null,
            otherSport: '',
            shortCode: '',
            website:'',
            linkText:'',
            detailedDescription:'',
            subscription:'',
            minArray: {
                min: {length:1}
            },

        }
    },
    watch: {
        findName: function(){
            if(this.findName.length>=2) {
                this.searchClubs({name:this.findName})
            }
        },
        country: async function() {
            if(this.thiscountry) await this.findAllStates({ciso: this.country.iso2})
        },
        state: async function() {
            if(this.state) await this.findAllCities({ciso: this.country.iso2, siso: this.state.iso2})
        }
    }
}
</script>


<style scoped >
v-expansion-panels {
    width: 80%;
}

.club-tiles {
    width: 100%;
}

.coachsight-pane {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    margin: 0vw 0vw 0vw 0vw;
    width: 100vw;
}

.coachsight-pane-button{
    margin: 5px;
    margin-right: 0px;
}
.coachsight-pane-content{
    padding-left: 1vw;
    padding-right: 1vw;
}

.coachsight-content{
    margin: 1vw;
}

.card-title{
    font-size:medium;
    font-weight:600;
    text-align:left;
    font-family: 'Varela Round';
    word-break: break-word;
}
.hover-card {
  transition: opacity .4s ease-in-out;
}

.hover-card:not(.on-hover) {
  opacity: 0.6;
 }

.show-btns {
  color: rgba(255, 255, 255, 1) !important;
}

.welcome-head{
    font-size:x-large;
    color: var(--cs1-lev1);
    font-family: "Varela Round";
    font-weight:600;

}

.welcome-info{
    margin:20px;
    text-align:left;
    font-size: large;
    font-family: "Varela Round"


}
.welcome-frame{
    display:flex;
    flex-flow: row;
}

.welcome-info-frame{
    display:flex;
    flex-flow:column;
}

.welcome-image{
    position:relative;
    display:inline;

}

.welcome-logo{
    position:absolute;
    bottom:0;
    transform: scale(0.5);
}
</style>

