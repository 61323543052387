var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loading)?_c('loading'):_c('div',{staticStyle:{"padding":"0px","width":"100%"}},[_c('v-row',{staticStyle:{"width":"100%"}},[_c('v-col',{attrs:{"cols":"11"}}),_c('v-col',{attrs:{"rows":"1"}},[_c('v-spacer'),(_vm.isAdminUser())?_c('v-btn',{attrs:{"color":"primary","x-small":""},on:{"click":function($event){return _vm.selectPane('Settings')}}},[_vm._v(" Edit ")]):_vm._e()],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('img',{attrs:{"src":_vm.clubs[_vm.selectedClub].logo,"height":"60px"}}),_c('h3',[_vm._v(_vm._s(_vm.clubs[_vm.selectedClub].name))]),(_vm.clubs[_vm.selectedClub].website)?_c('div',[_c('h4',[_vm._v("Club Information")]),_c('p',[_vm._v("You can find more information about this club at their website")]),(_vm.clubs[_vm.selectedClub].linkText)?_c('div',[_c('a',{attrs:{"href":_vm.clubs[_vm.selectedClub].website}},[_vm._v(_vm._s(_vm.clubs[_vm.selectedClub].linkText))])]):_c('div',[_c('a',{attrs:{"href":_vm.clubs[_vm.selectedClub].website}},[_vm._v("Click here")])])]):_vm._e()]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-divider',{staticStyle:{"padding-top":"40px"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('div',[_c('v-row',[_c('v-col',{staticStyle:{"text-align":"left"},attrs:{"cols":"7"}},[_c('h3',[_vm._v("Active Seasons")])]),_c('v-col',{staticStyle:{"text-align":"right"},attrs:{"cols":"5"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.isAdminUser())?_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","x-small":""}},'v-btn',attrs,false),on),[_vm._v(" Add a new season ")]):_vm._e()]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('form',{staticStyle:{"margin":"0px","padding":"0px"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.save)}}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Season Details")])]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('ValidationProvider',{attrs:{"name":"Season Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Season Name"},model:{value:(_vm.newname),callback:function ($$v) {_vm.newname=$$v},expression:"newname"}}),_c('div',{staticClass:"form-error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('v-col',[_c('DateCombo',{attrs:{"title":"Start Date","rules":[]},model:{value:(_vm.newstartDate),callback:function ($$v) {_vm.newstartDate=$$v},expression:"newstartDate"}})],1),_c('v-col',[_c('DateCombo',{attrs:{"title":"End Date","rules":[]},model:{value:(_vm.newendDate),callback:function ($$v) {_vm.newendDate=$$v},expression:"newendDate"}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.closeDialog(reset)}}},[_vm._v("close")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.createNewSeason(reset)}}},[_vm._v("Save")])],1)],1)],1)]}}])})],1)],1)],1),(_vm.activeSeasonList.length === 0)?_c('v-row',[_vm._v(" No active seasons ")]):_c('v-row',[_vm._l((_vm.activeSeasonList),function(season){return _c('v-col',{key:season,attrs:{"cols":"12","xl":"2","lg":"2","md":"2","sm":"6","xs":"6"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{class:{'on-hover': hover, 'hover-card':true },attrs:{"elevation":hover?12:0,"rounded":""}},[_c('v-img',{staticClass:"white--text align-end",attrs:{"src":_vm.seasonLogo(_vm.seasons[season].logo, _vm.seasons[season].sportId),"gradient":"to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)","height":"200px"},on:{"click":function($event){return _vm.setSeason(season)}}}),_c('v-card-actions',[_c('v-card-title',{staticClass:"card-title",attrs:{"dense":""}},[_vm._v(_vm._s(_vm.seasons[season].name))])],1)],1)]}}],null,true)})],1)}),(_vm.isAdminUser())?_c('v-col',[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{class:{'on-hover': hover, 'hover-card':true},attrs:{"elevation":hover?12:0,"rounded":""}})]}}],null,false,483881273)})],1):_vm._e()],2)],1)]),(_vm.account.user.details.id === _vm.clubs[this.selectedClub].ownerId)?_c('v-col',[_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-divider')],1),_c('v-col',{staticStyle:{"text-align":"left"},attrs:{"cols":"12"}},[_c('h3',[_vm._v("Inactive Seasons")])])],1),(_vm.inactiveSeasonList.length===0)?_c('v-row',[_vm._v(" No inactive seasons ")]):_vm._e(),_c('v-row',_vm._l((_vm.inactiveSeasonList),function(season){return _c('v-col',{key:season,attrs:{"xl":"2","lg":"2","md":"2","sm":"6","xs":"6"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{class:{'on-hover': hover, 'hover-card':true },attrs:{"elevation":hover?12:0,"rounded":""}},[_c('v-img',{staticClass:"white--text align-end",attrs:{"src":_vm.seasonLogo(_vm.seasons[season].logo, _vm.seasons[season].sportId),"gradient":"to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)","height":"200px"},on:{"click":function($event){return _vm.setSeason(season)}}}),_c('v-card-actions',[_c('v-card-title',{staticClass:"card-title",attrs:{"dense":""}},[_vm._v(_vm._s(_vm.seasons[season].name))])],1)],1)]}}],null,true)})],1)}),1)],1)]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }