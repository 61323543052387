<template>
    <div style="text-align:left;padding: 0px 10px 0px 10px">
        <ValidationObserver v-slot="{}" ref="form">

        <v-stepper v-model="importPage">
            <v-stepper-header>
                <v-stepper-step :complete="importPage>1" step="1">
                    Upload import data
                </v-stepper-step>
                <v-divider/>
                <v-stepper-step :complete="importPage>2" step="2">
                    Obtain registrations
                </v-stepper-step>
                <v-divider/>
                <v-stepper-step :complete="importPage>3" step="3">
                    Process import data
                </v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
                <v-stepper-content step="1">
                    <v-card>
                        <v-card-text>
                            <v-overlay :value="showProgress">
                                <v-card>
                                    <v-card-text>
                                        <div class="processing">Reading your data file</div>
                                        <v-progress-circular
                                            indeterminate
                                            color="primary"
                                            >
                                        </v-progress-circular>  

                                    </v-card-text>
                                </v-card>
                            </v-overlay>
                            <span class="instruction-heading"> Upload instructions: </span><br/>
                            <ul>
                                <li>Upload your import data as an excel spreadsheet that follows the rules below, and make any recommended changes before uploading it again</li>
                                <li>Pay for any additional registrations you require for the file upload</li>
                                <li>Process your new registrations</li>
                            </ul>
                            <span class="instruction-heading">Spreadsheet Rules:</span><br/>

                            <ul>
                                <li>Your workbook needs to have a sheet with the same name as your tryout (or matches the first 31 characters)</li>
                                <li>Your worksheet needs a heading row on the first line, with the following column headings in any order:</li>
                                <v-row>
                                    <v-col cols="6" style="margin-top:0px;">
                                        Required:
                                        <ul>
                                            <li><b>Participant Number</b></li>
                                            <li><b>First Name</b></li>
                                            <li><b>Last Name</b></li>
                                        </ul>
                                    </v-col>
                                    <v-col cols="6" style="margin-top:0px;">
                                        Optional:
                                        <ul>
                                            <li><b>Email Address</b></li>
                                            <li><b>Phone Number</b></li>
                                            <li><b>Year Of Birth</b></li>
                                            <li><b>Parent Name</b></li>
                                            <li><b>Parent Phone</b></li>
                                            <li><b>Parent Email</b></li>
                                            <li><b>Q1, Q2</b>,... for each question defined in yout application questions </li>
                                        </ul>
                                    </v-col>
                                </v-row>
                                <li>New entries in the "Participant Number" column must be a unique value of {{currentLargestParticipantNumber+1}} or higher</li>
                                <li>Do not include blank lines in your file - this will cause processing to stop early</li>
                                <li>You may have data for multiple trials in the one file, but each trial must be on its own worksheet, and imported seperately</li>

                            </ul>
                            <div class="text-left">
                                <v-btn text color="primary" href="https://s3.ap-southeast-2.amazonaws.com/www.coachsight.net/CoachSight+Manual+Athlete+Import+Template.xlsx" ><v-icon>mdi-file-excel-outline</v-icon> Download an excel spreadsheet template for data entry</v-btn>
                            </div>
                            <div class="warnings" v-if="warnings.length>0">
                                The following warnings were identified with your spreadsheet:
                                <ul>
                                    <li v-for="(warning,n) in warnings" :key="'w'+n">{{ warning }}</li>
                                </ul>
                                You can ignore these warnings, but pay attention to the consequences.
                            </div>
                            <div class="errors" v-if="errors.length>0">
                                The following errors were identified with your spreadsheet:
                                <ul>
                                    <li v-for="error in errors" :key="'e'+error">{{ error }}</li>
                                </ul>
                                Import cannot continue. Please correct these errors and try your import again.
                            </div>
                            <div>
                                <v-expansion-panels v-if="modelFile" accordian style="margin-bottom:20px;">
                                    <v-expansion-panel>
                                        <v-expansion-panel-header>Existing Registrations ({{duplicateParticipants.length}} Entries)</v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <template>
                                                <v-data-table
                                                    :headers="dupHeaders"
                                                    :items="duplicateParticipants"
                                                    :items-per-page="10"
                                                    class="elevation-1"
                                                ></v-data-table>
                                            </template>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                    <v-expansion-panel>
                                        <v-expansion-panel-header>New Registrations ({{newParticipants.length}} Entries)</v-expansion-panel-header>
                                        <v-expansion-panel-content>
                                            <v-data-table
                                                :headers="newHeaders"
                                                :items="newParticipants"
                                                :items-per-page="10"
                                                class="elevation-1"
                                            ></v-data-table>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>

                            </div>
                            <ValidationProvider>
                                <v-file-input v-model="modelFile" label="Athlete data file" @change="readFile" @click:clear="cancel" accept=".xls,.xlsx"/>
                            </ValidationProvider>
                            <div v-if="importStatusMessage">
                                {{importStatusMessage}}
                            </div>

                        </v-card-text>
                        <v-card-actions>
                            <v-spacer/>
                            <v-btn v-if="file===null" text color="primary" :disabled="true">Upload your data file to continue</v-btn>
                            <v-btn v-else-if="errors.length>0" text color="primary" :disabled="true" @click="importStep=2">Correct the errors above to continue</v-btn>
                            <v-btn v-else-if="numNewParticipants ===0" text color="primary" :disabled="true" @click="importStep=2">There are no new registrations in the file you provided</v-btn>
                            <v-btn v-else text color="primary" @click="importPage='2'">Continue</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-stepper-content>
                <v-stepper-content step="2">
                    <v-card>
                        <v-card-title>
                            You're about to import {{numNewParticipants}} athletes...
                        </v-card-title>
                        <v-card-text>
                            <div class="leftover-registrations" v-if="numNewParticipants<=clubs[selectedClub].freeRegistrations">
                                Your club will have {{clubs[selectedClub].freeRegistrations - numNewParticipants}} registrations left after this import.
                            </div>
                            <div v-else class="new-registrations">
                                ... and your club needs <b>{{numNewParticipants - clubs[selectedClub].freeRegistrations}}</b> additional athlete registrations to import this data.
                                <div class="price-panel">

                                    <div class="packages">
                                        <div class="package" v-for="pricePackage in packages" :key="pricePackage.name">
                                            <div class="discount" v-if="pricePackage.discount>0">
                                                <b>Special Deal</b><br/>
                                                {{pricePackage.discount}}% off
                                            </div>
                                            <div class="best-value" v-else-if="pricePackage.content===250">
                                                <b>Best Value</b>
                                            </div>
                                            <div class="no-label" v-else>
                                            </div>
                                            <div class="package-top">
                                                <div class="package-name">{{pricePackage.name}}</div>
                                            </div>
                                            <div class="package-middle">
                                                <div class="package-desc">Includes {{pricePackage.content}} registrations</div>
                                                <div v-if="pricePackage.discount>0" class="package-cost">Cost: 
                                                        <span class="discount-old">{{(pricePackage.cost/100).toLocaleString(tryouts[selectedTryout].localLanguage, {style:'currency', currency: tryouts[selectedTryout].localCurrency})}} </span>
                                                        <span class="discount-new">{{(pricePackage.cost/100*(100-pricePackage.discount)/100).toLocaleString(tryouts[selectedTryout].localLanguage, {style:'currency', currency: tryouts[selectedTryout].localCurrency})}} </span>

                                                        <br/><span style="font-size:small;">{{pricePackage.conditions}}</span></div>
                                                <div v-else class="package-cost">Cost: {{(pricePackage.cost/100).toLocaleString(tryouts[selectedTryout].localLanguage, {style:'currency', currency: tryouts[selectedTryout].localCurrency})}} <br/><span style="font-size:small;">{{pricePackage.conditions}}</span></div>
                                                <div class="required-packages">You will need {{Math.ceil((numNewParticipants - clubs[selectedClub].freeRegistrations)/pricePackage.content)}} packages to process these registrations</div>
                                            </div>
                                            <div class="package-bottom">
                                                <div v-if="pricePackage.discount>0" class="cost-per-reg">Cost per registration: {{(pricePackage.cost/pricePackage.content/100*(100-pricePackage.discount)/100).toLocaleString(tryouts[selectedTryout].localLanguage, {style:'currency', currency: tryouts[selectedTryout].localCurrency}) }}</div>
                                                <div v-else class="cost-per-reg">Cost per registration: {{(pricePackage.cost/pricePackage.content/100).toLocaleString(tryouts[selectedTryout].localLanguage, {style:'currency', currency: tryouts[selectedTryout].localCurrency}) }}</div>
                                                <div v-if="pricePackage.discount>0" class="total-cost">Total cost: {{(Math.ceil((numNewParticipants - clubs[selectedClub].freeRegistrations)/pricePackage.content)*pricePackage.cost/100*(100-pricePackage.discount)/100).toLocaleString(tryouts[selectedTryout].localLanguage, {style:'currency', currency: tryouts[selectedTryout].localCurrency})}}</div>
                                                <div v-else class="total-cost">Total cost: {{(Math.ceil((numNewParticipants - clubs[selectedClub].freeRegistrations)/pricePackage.content)*pricePackage.cost/100).toLocaleString(tryouts[selectedTryout].localLanguage, {style:'currency', currency: tryouts[selectedTryout].localCurrency})}}</div>
                                                <div class="conditions"> {{pricePackage.conditions}}</div>
                                            </div>
                                            <v-btn x-small color="primary" @click="buyPackage(pricePackage.id,Math.ceil((numNewParticipants - clubs[selectedClub].freeRegistrations)/pricePackage.content) )">
                                                Buy now
                                            </v-btn>
                                        </div>
                                    </div>
                                    <div class="expiry-note">
                                        <b>Note:</b> Unused registrations do not expire, and can be used for other tryouts your club runs now or in the future.
                                    </div>
                                    
                                </div>
                            </div>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn text color="secondary" @click="importPage='1'">Back</v-btn>
                            <v-spacer/>
                            <v-btn v-if="numNewParticipants > clubs[selectedClub].freeRegistrations>0" :disabled="true" text color="primary" >You need to a registration pack to continue this import</v-btn>
                            <v-btn v-if="numNewParticipants > clubs[selectedClub].freeRegistrations<=0" text color="primary" @click="processFile">Process Athletes</v-btn>
                        </v-card-actions>
                    </v-card>

                </v-stepper-content>
                <v-stepper-content step="3">
                    <v-card>
                        <v-card-title>
                            Processing Athlete Imports
                        </v-card-title>
                        <v-card-text>
                            <v-progress-linear v-if="percentProgress < 100" color="primary" :value="percentProgress" striped height="10"></v-progress-linear>
                            <v-icon v-if="percentProgress===100" width="200" color="green" >mdi-check-circle</v-icon>
                            <div v-if="percentProgress===100">
                                Import Complete. {{ newUsers }} athletes were imported. 
                            </div>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" :disabled="percentProgress < 100" @click="reset()">Close</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-stepper-content>
            </v-stepper-items>
        </v-stepper>
    </ValidationObserver>
    </div>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import * as XLSX from 'xlsx'

import { v4 as uuidv4} from 'uuid'
import {rc_participants} from '@/_helpers/responseCodes'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {validateEmail} from '@/_helpers/service-tools'
import moment from 'moment'

export default {
    props: [],
    components: {
        ValidationObserver, ValidationProvider

    },
    computed: {
        ...mapState({
            tryouts: state=>state.tryoutStore.data,
            tryoutList: state=>state.tryoutStore.list,
            tryoutStatus: state=>state.tryoutStore.status,

            userData: state => state.userStore.all,

            selectedTryout: state => state.navigatorStore.selectedTryout,
            selectedClub    : state => state.navigatorStore.selectedClub,

            appquestions:   state => state.appquestionStore.data,
            appquestionsList: state => state.appquestionStore.list,

            participants:       state=> state.participantStore.data,
            participant:        state => state.participantStore.participant,
            participantStatus:  state => state.participantStore.status,
            participantError:   state => state.participantStore.error,

            responses:          state => state.qresponseStore.responses,
            qresponses:         state => state.qresponseStore.data,
            qresponseList:      state => state.qresponseStore.list,


            clubs:              state => state.clubStore.data,

            np : state => state.participantStore.newParticipant,
            users: state => state.userStore.all,
            users2: state => state.userStore.data,

            packages:           state => state.packageStore.data,
            stripe:       state => state.stripeStore.data,
            stripeError:        state => state.stripeStore.error,
        }),
        percentProgress(){
            return this.progress/this.numNewParticipants*100
        },
        loading: function() {
            return this.tryoutStatus.loading
        },
        orderedList: {
            get() {
                return this.list
            },
            set(value) {
                this.updateListOrder(value)
            }
        },
        currentLargestParticipantNumber: {
            get() {
                var max=0;
                for(var key in this.participants) {
                    if(this.participants[key].participantNumber > max){
                        max = this.participants[key].participantNumber
                    }
                }
                return max

            }
        },
        
    },
    mounted(){
        this.$root.$on('tryoutRefresh', async() => {
            this.modelFile=null
            this.errors=[]
            this.warnings=[]
            this.numEntrants=0
            this.importPage=1
            this.duplicateParticipants=[]
            this.numNewParticipants=0
            this.newParticipants=[]

        })
    },
    sockets:{
        connect(){
            this.$socket.emit('join-room', `payments-${this.selectedClub}`, localStorage.getItem('jwt'))
        },  
        async 'PCPaymentSuccess'(){
            // Reload the club info and check the current number of registrations

            await this.reloadClub(this.selectedClub)
            this.$toast.success('Your payment was successfull, and your package has been added to the club.')
        }
    },
    methods: {
        ...mapActions('clubStore',{
            reloadClub: 'reload' 
        }),
        ...mapActions('userStore', {
            manualUserCreate: 'createManual',
        }),
        ...mapActions('participantStore', {
            createManualParticipant: 'addManual',
            findAllParticipantsForTryout: 'quietFindAllForEvent',
            findParticipant: 'find',
            findParticipantByName: 'findByName',
            updateParticipantProp: 'propertyUpdate',
            updateParticipant: 'update',
        }),
        ...mapActions('qresponseStore',{
            createResponse: 'add',
            findAllResponsesForParticipant: 'findAllForParticipant',
            updateResponseProp: 'propertyUpdate',
            updateResponse: 'update',
        }),
        ...mapActions('userStore',{
            findUser: 'getByEmail',
            findUserById: 'getById',
            updateUserProp: 'propertyUpdate',
            updateUser: 'update',
        }),
        ...mapActions('appquestionStore', {
            addQuestion: 'addQuestion',
            updateListOrder: 'updateListOrder',
            findAllQuestionsForEvent: 'loadAllQuestionsForEvent',
            updateQuestion: 'updateQuestion',
            deleteQuestion: 'delete'
        }),
        ...mapActions('packageStore', {
            findActivePackages: 'findActive'
        }),
        ...mapActions('stripeStore', {
            buyRegPackage: 'packageCharge',
        }),
        cancel(){
            this.newParticipants=[]
            this.duplicateParticipants=[]
            this.errors=[]
            this.warnings=[]
        },
        async buyPackage(packageId,qty){
            await this.buyRegPackage({clubId: this.selectedClub, packageId: packageId, qty: qty})

            if(this.stripeError) {
                this.$toast.error('Your payment transaction could not be processed')

                this.wait=false
                this.loadingPayment=false
            } else {
                // Send us off to the Stripe payment interface
                window.open(this.stripe.paymentURL, "_blank")
            }
        },
        async readFile(selectedFile) {

                if(!selectedFile) {
                    return
                }
                this.progress=0
                this.warnings=[]

                /*
                this.duplicateParticipants=[]
                this.numNewParticipants=0
                this.newParticipants=[]
                this.importStatusMessage=null
                this.modelFile=null
                this.errors=[]
                this.warnings=[]

                this.newUsers=0
                */


                this.errors=[]
                this.file = selectedFile
                var reader = new FileReader()


                reader.onload = async (e) => {
                    this.showProgress=true
                    this.showFileInput=false
                    //var data = e.target.result;
                    this.workbook = XLSX.read(e.target.result, {defval:""})
                    this.wsnames = this.workbook.SheetNames

                    if(!this.wsnames.includes(this.tryouts[this.selectedTryout].title.substring(0,31))){
                        this.setError(`Your file does not contain a worksheet with the name of this tryout event (${this.tryouts[this.selectedTryout].title})`)
                        this.disableProcess=true
                        this.showProgress=false
                        return 
                    }

                    this.worksheetArray = XLSX.utils.sheet_to_json(this.workbook.Sheets[this.tryouts[this.selectedTryout].title.substring(0,31)], {defval:""})

                    // Lets make sure the data we're looking for is in there...
                    const firstEntry = this.worksheetArray[0]
                    if(!Object.prototype.hasOwnProperty.call(firstEntry,'First Name') ) this.setError('There is no column called "First Name"')
                    if(!Object.prototype.hasOwnProperty.call(firstEntry,'Last Name') ) this.setError('There is no column called "Last Name"')
                    if(!Object.prototype.hasOwnProperty.call(firstEntry,'Email Address') ) this.setWarning('There is no column called "Email Address". None of these athletes will receive registration or offer emails')
                    if(!Object.prototype.hasOwnProperty.call(firstEntry,'Phone Number') ) this.setWarning('There is no column called "Phone Number". All participants will be assigned no data for this field')
                    if(!Object.prototype.hasOwnProperty.call(firstEntry,'Year Of Birth') ) this.setWarning('There is no column called "Year Of Birth. All participants will be assigned 1900 as a Year of Birth"')
                    if(!Object.prototype.hasOwnProperty.call(firstEntry,'Parent Name') ) this.setWarning('There is no column called "Parent Name". All participants will be assigned no data for this field')
                    if(!Object.prototype.hasOwnProperty.call(firstEntry,'Parent Phone') ) this.setWarning('There is no column called "Parent Phone". All participants will be assigned no data for this field')
                    if(!Object.prototype.hasOwnProperty.call(firstEntry,'Parent Email') ) this.setWarning('There is no column called "Parent Email". All participants will be assigned no data for this field')

                    // Now the questions...

                    for(var idx in this.appquestionsList){
                        const qid = this.appquestionsList[idx]
                        const order = this.appquestions[qid].order+1 // Display from 1, store from 0
                        const question = this.appquestions[qid].question
                        if(!Object.prototype.hasOwnProperty.call(firstEntry,`Q${order}`) ) this.setWarning(`There is no column called "Q${order}" for the question "${question}"`)
                    }

                    // Check for duplicate or bad email addresses before processing...

                    var count =0;
                    this.newParticipants=[]
                    this.numNewParticipants=0
                    this.duplicateParticipants=[]

                    var juniorsAllowed = false

                    for(var key in this.worksheetArray) {
                        const item = this.worksheetArray[key]
                        count=count+1

                        const parentNeeded = moment(new Date()).diff(moment(new Date(this.ExcelDateToJSDate(item.DOB))), 'years') < this.tryouts[this.selectedTryout].juniorAge

                        try{

                            var errState=1

                            // First, if this event allows juniors, make sure we have a YOB for the applicant

                            if(this.tryouts[this.selectedTryout].allowJuniors){
                                juniorsAllowed=true
                                // If DOB was not provided, flag as error
                                if(parentNeeded && !Object.prototype.hasOwnProperty.call(item,'DOB') ){
                                    //return moment(this.tryouts[this.tryoutId].startDate).format("dddd MMMM Do")
                                    this.setError(`${item['First Name']} ${item['Last Name']}: This participant does not have a valid date of birth provided. Participants where juniors are permitter require a DOB`)
                                    continue
                                }
                            }



                            // is the name already in this trial?
                            await this.findParticipantByName({
                                firstName: item['First Name'].trim(),
                                lastName: item['Last Name'].trim(),
                                eventId: this.selectedTryout,
                                clubId: this.selectedClub,
                            })
                            switch(this.participantStatus.code){
                                case rc_participants.PARTICIPANT_FOUND:
                                    // Add to the existing participant bucket
                                    this.duplicateParticipants.push(this.participant)
                                    continue 
                                case rc_participants.NO_MATCH:
                                    // No special action here - fall through.
                                    //console.info('No match found!')
                                    break;
                            }





                            if(parentNeeded && Object.prototype.hasOwnProperty.call(item, 'Parent Email') && item['Parent Email'] !== '' && !validateEmail(item['Parent Email'])){
                                this.setWarning(`${item['First Name']} ${item['Last Name']} has an invalid email for their parent. This could impact their ability to receive emails`)
                            }

                            var validParent=false

                            // Has the email been provided?

                            if(parentNeeded && !Object.prototype.hasOwnProperty.call(item, 'Email Address') || !validateEmail(item['Email Address'])) {
                                // No - warn the admin that emails may not be received immediately

                                this.setWarning(`${item['First Name']} ${item['Last Name']} has an invalid or no email address submitted, and will not receive email correspondence until they check-in with a valid email address`)
                            } else {
                                // Yes - Is parent email provided?
                                /*
                                if(!Object.prototype.hasOwnProperty.call(item, 'Parent Email') || !validateEmail(item['Parent Email'])){
                                    // No - Does the email already exist?

                                    await this.findUser(item['Email Address'])
                                    console.log('Checking if found')
                                    if(this.userData.user.message === 'Found'){
                                        console.log('FOund')
                                       const origFirstName = this.userData.user.users[0].firstName 
                                       const origLastName = this.userData.user.users[0].lastName 

                                       if ((origFirstName.localeCompare(item['FirstName'], undefined, {sensitivity: 'base'}) !== 0) || (origLastName.localeCompare(item['Last Name'], undefined, {sensitivity: 'base'}) !== 0)){
                                            // No- warn that email has already been used and emails won't be received until they check in with a valid email
                                            this.setWarning(`${item['First Name']} ${item['Last Name']} has provided an email that has been used by another participant in the past. They will not receive email until after they have checked in with a valid email address`)
                                       } 
                                    }
                                    console.log('Not found')
                                    // If not fall through
                                } else {
                                    //Drop through if yes
                                } 
                                    */
                                



                                // If the parent email is provided, check it's valid
                                if(parentNeeded && Object.prototype.hasOwnProperty.call(item, 'Parent Email')) {
                                    // Is it valid?
                                    if(!validParent){
                                        console.error('Invalid Parent')
                                    }
                                    if(item['Parent Email'] && validateEmail(item['Parent Email'])){ 
                                        validParent=true
                                    } else {
                                        this.setWarning(`${item['First Name']} ${item['Last Name']} has an invalid parent email address provided. This parent will not receive emails until this is corrected`)
                                    }
                                
                                } 

                                // Did we require a parent email?
                                if(parentNeeded && !(juniorsAllowed && this.parentNeeded(item) && validParent)){
                                    this.setError(`${item['First Name']} ${item['Last Name']} does not have a valid parent email, and this event requires one for juniors`)
                                }



                            }
                             // Add to the new participant bucket
                            this.numNewParticipants++
                            item.id=key
                            this.newParticipants.push(item)
                        } catch(error) {
                            console.error(`Error processing participant: ${item['First Name']} ${item['Last Name']}`)
                            console.error(`Error state: ${errState}`)
                            console.error(error)
                        }
                    }

                    //this.importStatusMessage = `There are ${this.duplicateParticipants.length} existing entries and ${this.numNewParticipants} new entries in your data file.`

                    this.numEntrants=count
                    /*
                    if(min <= this.currentLargestParticipantNumber){
//                        this.importStatusMessage = `Your import data needs to have participant numbers greater than ${this.currentLargestParticipantNumber} `
                        this.setError(`Your import data needs to have participant numbers greater than ${this.currentLargestParticipantNumber} `)
                        this.disableProcess=true
                        this.showProgress=false
                        return
                    }
                    */

                    this.showFees=true
//                    this.disableProcess=false
                    this.showProgress=false

                }
                reader.readAsArrayBuffer(this.file); 
        },
        setError(message){
            this.errors.push(message)
            this.disableProcess=true
        },
        setWarning(message){
            this.warnings.push(message)
        },
        ExcelDateToJSDate(serial) {
            var utc_days  = Math.floor(serial - 25569);
            var utc_value = utc_days * 86400;                                        
            var date_info = new Date(utc_value * 1000);

            var fractional_day = serial - Math.floor(serial) + 0.0000001;

            var total_seconds = Math.floor(86400 * fractional_day);

            var seconds = total_seconds % 60;

            total_seconds -= seconds;

            var hours = Math.floor(total_seconds / (60 * 60));
            var minutes = Math.floor(total_seconds / 60) % 60;

            return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds);
        },
        parentNeeded(item){

            const dateOfBirth = this.ExcelDateToJSDate(item.DOB)
            const today = new Date()

            const mDOB = moment(dateOfBirth)
            const mToday = moment(today)
            const diff = mToday.diff(mDOB, 'years')

            if(diff >= this.tryouts[this.selectedTryout].juniorAge) return false
            return true
        },

        async processFile() {
            this.importPage="3"
            this.showProgress=true
            this.progress=0
            this.disableProcess=true

            var generated = true
            var verified=false

            var response=null

            // for every line in the table
//            this.worksheetArray.forEach(async element => {

            for (var idx in this.newParticipants){
                const element = this.newParticipants[idx]
                this.newUsers++
                this.progress++

                const parentNeeded = moment(new Date()).diff(moment(new Date(this.ExcelDateToJSDate(element.DOB))), 'years') < this.tryouts[this.selectedTryout].juniorAge

                var yob=1900
                if(Object.prototype.hasOwnProperty.call(element,'Year Of Birth') && element['Year Of Birth'] !== '') yob = element['Year Of Birth']

                var parentName = (Object.prototype.hasOwnProperty.call(element, 'Parent Name') && element['Parent Name']!== '') ? element['Parent Name'] : 'Not Provided'
                var parentEmail = (Object.prototype.hasOwnProperty.call(element, 'Parent Email') && element['Parent Email']!== '') ? element['Parent Email'] : 'Not Provided'
                var parentPhone = (Object.prototype.hasOwnProperty.call(element, 'Parent Phone') && element['Parent Phone']!== '') ? element['Parent Phone'] : 'Not Provided'
                var phoneNumber = (Object.prototype.hasOwnProperty.call(element, 'Phone Number') && element['Phone Number']!== '') ? element['Phone Number'] : 'Not Provided'

                if(parentNeeded && !parentEmail || !validateEmail(parentEmail)){
                    parentEmail = 'NOEMAIL-'+uuidv4()+'@coacyyhsight.net'
                }
                // if the email is provided, check that it doesn't already exist

                var email = null
                var user={}

                // If email has been provided
                if(Object.prototype.hasOwnProperty.call(element,'Email Address') && element['Email Address'] !== '') {
                    email = element['Email Address']
                    if(!email || !validateEmail(email)) {
                        email = 'NOEMAIL-'+uuidv4()+'@coachsight.net'
                    }

                    // Has the parent Email been provided
                    if(Object.prototype.hasOwnProperty.call(element, 'Parent Email') && element['Parent Email'] !== ''){
                        if(element['Parent Email'].localeCompare(email, undefined, {sensitivity:'base'}) === 0){
                            // If they're the same, generate a new email address for the participant
                            email = 'NOEMAIL-'+uuidv4()+'@coacyyhsight.net'
                            generated=true
                            verified = false
                        } else {
                            // Parent and participant email addresses are valid and distinct
                            generated = false
                            verified = false
                        }
                    } else {
                        // No parent email

                        // Does this email address already exist in the system?

                        response = await this.findUser(element['Email Address'])
                        
                        if(response.message === 'Found'){
                            const origFirstName = response.users[0].firstName 
                            const origLastName = response.users[0].lastName 

                            if ((origFirstName.localeCompare(element['FirstName'], undefined, {sensitivity: 'base'}) !== 0) || (origLastName.localeCompare(element['Last Name'], undefined, {sensitivity: 'base'}) !== 0)){
                                // No match - Generate a new email address for this user...
                            
                                email = 'NOEMAIL-'+uuidv4()+'@coachsight.net'
                                generated=true
                                verified = false

                            } else {
                                user = response.users[0]
                            }
                        }
                    }
                } else {
                    email = 'NOEMAIL-'+uuidv4()+'@coachsight.net'
                    generated=true
                    verified = false

                }
                if(user != {} ){
                    // Create a new user 
                    await this.manualUserCreate({
                        email,
                        phone: phoneNumber,
                        firstName: element['First Name'].trim(),
                        lastName: element['Last Name'].trim(),
                        eventId: this.selectedTryout,
                        yearOfBirth:yob,
                        generated:generated,
                        updateDetails: true,
                        verified: verified,
                    })
                    user = response.user
                }



                // Does the user already exist?
                await this.findUser(email)
                if(this.userData.user.message !== 'Found'){
                    await this.manualUserCreate({
                        email,
                        phone: phoneNumber,
                        firstName: element['First Name'].trim(),
                        lastName: element['Last Name'].trim(),
                        eventId: this.selectedTryout,
                        yearOfBirth:yob,
                        generated:1,

                    })
                    user = this.userData.user.user
                } else {
                    // refer to the existing user
                    user = this.userData.user.users[0]
                }
                




                // Check that there is not already a participant with a matching name


                try{
                    await this.findParticipantByName({
                        firstName: element['First Name'].trim(),
                        lastName: element['Last Name'].trim(),
                        eventId: this.selectedTryout,
                        clubId: this.selectedClub,
                    })
                    if(this.participantStatus.code===rc_participants.PARTICIPANT_FOUND){
                       continue
                    }
                } catch(error) {
                    console.error('Error looking up participant')
                    console.error(error)
                }


                // create a new participant.
                var newParticipant = null 

                try {
                    await this.createManualParticipant({
                        userId: user.id,
                        firstName: element['First Name'].trim(),
                        lastName: element['Last Name'].trim(),
                        eventId: this.selectedTryout,
                        parentConsent: true,
                        imageConsent: false,
                        parentName: parentName.trim(),
                        parentPhone: parentPhone,
                        parentEmail: parentEmail,
                        junior: (new Date().getFullYear() - yob) < this.tryouts[this.selectedTryout].juniorAge ,
                        feeType: 0,
                        //participantNumber: element['Participant Number'],
                    })
                    newParticipant = this.np.participant

                } catch (error) {
                    console.error(`Problem processing athlete: ${element['First Name'].trim()} ${element['Last Name'].trim()}`)
                    console.error(error)
                }
                this.importStatusMessage = `${this.newUsers} new users have been added to your tryout`
                // Now the questions...

                for(var idx2 in this.appquestionsList){
                    const qid = this.appquestionsList[idx2]
                    const order = this.appquestions[qid].order+1 // Display from 1, store from 0

                    if(Object.prototype.hasOwnProperty.call(element,`Q${order}`) ) {
                        this.createResponse({
                            participantId: newParticipant.id,
                            eventId: this.selectedTryout,
                            questionId: qid,
                            response: element[`Q${order}`]
                        })
                    }
                }

                

            }
            // Reload the club to account for registration consumption

            await this.reloadClub(this.selectedClub)

        },
        reset(){
            this.importPage=1
            this.duplicateParticipants=[]
            this.numNewParticipants=0
            this.newParticipants=[]
            this.importStatusMessage=null
            this.modelFile=null
            this.showProgress=false
            this.errors=[]
            this.warnings=[]
            this.newUsers=0
        }
    },
    async created (){
        await this.findActivePackages()
        await this.findAllParticipantsForTryout(this.selectedTryout)
        await this.findAllQuestionsForEvent({eventId: this.selectedTryout})
    },
    data(){
        return {
            importPage: 1,
            duplicateParticipants: [],
            numNewParticipants:0,
            newParticipants:[],


            showFees: false,
            showFileInput: true,
            noParentName: false,
            noParentEmail: false,
            noParentPhone: false,
            numEntrants:0,
            showProgress: false,
            newUsers: 0,
            errors: [],
            warnings: [],
            worksheetArray: null,
            file: null,
            modelFile:null,
            wsnames: null,
            workbook: null,
            disableProcess:true,
            importStatusMessage: null,
            progress:0,
            dupHeaders: [
                { text: 'Participant #', value: 'participantNumber' },
                {
                    text: 'First Name',
                    align: 'start',
                    sortable: true,
                    value: 'firstName',
                },
                { text: 'Last Name', value: 'lastName' },
                { text: 'Email Address', value: 'email' },
            ],
            newHeaders: [
                {
                    text: 'First Name',
                    align: 'start',
                    sortable: true,
                    value: 'First Name',
                },
                { text: 'Last Name', value: 'Last Name' },
                { text: 'Email Address', value: 'Email Address' },
            ],
        }
    },
}

</script>

<style scoped>
ul {
    list-style: circle;
}
li {
    padding:0px;
}
.warnings{
    background-color: orange;
    border-radius: 10px;
    text-align: left;
    padding: 10px;
    font-size: small;
    margin: 10px;
}
.errors{
    margin: 10px;
    background-color: pink;
    border-radius: 10px;
    text-align: left;
    padding: 10px;
    font-size: small;
}
.packages{
    display:flex;
    flex-flow: row;
    width:100%;
    margin:0px;
    padding:0px;
    text-align:center;
    margin-top:10px;
}
.package-top{
    border-style: solid solid none solid;
    background-color:var(--cs1-lev2);
    font-weight:600;

    border-radius: 10px 10px 0px 0px;
    margin: 0px 5px 0px 5px;
}
.package-middle{
    border-style: none solid none solid;
    margin: 0px 5px 0px 5px;
    border-radius: 0px;
    background-color: beige;
}
.package-bottom{
    border-style: none solid solid solid;
    border-radius: 0px 0px 10px 10px;
    margin: 0px 5px 0px 5px;
    background-color:var(--cs1-lev3);
}
.package-name{
    font-weight:600;
    color:white;
    text-align:center;
}

.package-desc{
    text-align:left;
    display: list-item;
    margin-left: 30px;
}

.package-cost{
    text-align:left;
    display: list-item;
    margin-left: 30px;

}

.cost-per-reg{
    text-align: center;
    font-size: x-small;
}

.required-packages{
    margin: 10px 10px 0px 10px;
}

.conditions {
    font-size: x-small;
    text-align:center;
}

.total-cost{
    font-weight:600;
    background-color:white;
    margin:auto;
    width:70%;
    border-radius:10px;
    text-align: center;

}

.new-registrations {
    text-align:left;
    margin-left:30px;
}
.remaining-registrations{
    text-align:left;
    display:list-item;
    margin-left:70px;
}

.instruction-heading{
    font-weight:600;
    font-family:"varela round";
}

.discount {
    border-radius: 10px 10px 0px 0px;
    width:70%;
    margin:auto;
    margin-bottom:0px;
    background-color:green;
    color:white;
    height:3em;
}

.best-value{
    height:3em;
    border-radius: 10px 10px 0px 0px;
    width:70%;
    margin:auto;
    margin-bottom:0px;
    background-color: blue;
    color:white;
}

.no-label {
    height:3em;
}

.discount-old {
    text-decoration: line-through;
}

.discount-new{
    font-weight:600;
    color:red;
}

.expiry-note{
    border-radius:10px;
    border-style: solid;
    margin-top:20px;
    text-align:center;
}

.processing{
    font-size:large;
    font-family: 'varela round';
}
</style>