import Vue from 'vue'
import validator from 'validator'
//import {dns} from 'dns'

const apiUrl = process.env.VUE_APP_CSAPI


const TOAST_ID ='721hhjk217689'

function logout (options={quiet:false}) {
    // remove user from local storage to log them out
    localStorage.removeItem('user')
    localStorage.removeItem('jwt')
    localStorage.clear();
    sessionStorage.clear();
    if(!options.quiet){
        window.location.assign(process.env.VUE_APP_VUE_LINK_PREFIX)

    }
}

async function handleResponse (response) {
    return await response.text()
    .then (text => {
        const data = text && JSON.parse(text)

        if(!response.ok) {
            if(response.status === 401) {
                // auto logout if unauthorized response is received
                logout()
                location.reload(true);
            }
            const error = (data && data.message) || response.statusText
            return Promise.reject({error, data});
        }
        return Promise.resolve(data)
    })
}

function stdHeaders(localUser) {
    if(localUser) {
        return {
            'Content-Type': 'application/json',
            'Authorization' : 'Bearer '+ localUser.token
            /*
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            'Pragma': 'no-cache',
            'Expires': '0'
            */
        }
    }
    return {
        'Content-Type': 'application/json'
    }
}

async function sendMessage(thisMethod, thisPath, query, body) {
    // Check the network connection state


    var localUser = localStorage.getItem('user')
    if(localUser === 'undefined') localUser=null

    const requestOptions = {
        method: thisMethod,
        headers: stdHeaders(JSON.parse(localUser)),
    }

    if(body){
        requestOptions['body']= JSON.stringify(body)
    }

    var url = new URL(`${apiUrl}/${thisPath}`)


    if(query){
        for(const [key, value] of Object.entries(query)) {
            url.searchParams.append(key, value)
        }
    }

    try{
        const response = await fetch(url, requestOptions)
        const data = await response.json()

        if(!response.ok){
            if(response.status === 401) {
                logout()
                location.reload(true)
            }
            return Promise.reject(data)
        }
        return Promise.resolve(data);

    } catch(e) {
        if(e.message === 'Failed to fetch'){
            Vue.$toast.error('Network error: Try again shortly - CoachSight will reconnect when the network becomes available.', {id: TOAST_ID})
        }
        console.error('Error sending message')
        console.error(e)
    }
    
}

function responsiveClass(className){
    if(this.$vuetify.breakpoint.smAndDown){
        return className+' mobile'
    }
    return className
}

function validateEmail(emailAddress){
    const trimmedEmail = emailAddress.trim()
    if (!validator.isEmail(trimmedEmail)) 
        return false
    return true
}
export {
    logout,
    handleResponse,
    stdHeaders,
    sendMessage,
    responsiveClass,
    validateEmail,
}