<template>
  <v-system-bar v-if="account.status.loggedIn" style="padding:0px; height:50px;" >
    <v-row :class="barStyle">
      <v-col class="no-boundaries" cols="6" style="display:flex;flex-direction:row;align-items: center;">
        <div>
          <router-link to="/" >
            <img src="https://s3.ap-southeast-2.amazonaws.com/www.coachsight.net/app_assets/Multi-Sport-Logo-short-2.gif" color="white" height="30px" width="auto" role="img"/>
          </router-link>
        </div>
        <div>
          <div :class="$vuetify.breakpoint.smAndDown?'cs-title-sm-down':'cs-title'">CoachSight</div>
          <div :class="$vuetify.breakpoint.smAndDown?'cs-subtitle-sm-down':'cs-subtitle'">Team Selector</div>
        </div>
      </v-col>
      <!--
      <v-col cols="2" v-if="debug" style="background-color:white;padding-left:20px;padding-right:20px;color:var(--cs1-lev1)">
        <span style="font-family:'Varela Round';font-size: x-small;font-weight:600;;">DEV BUILD {{ build }}</span><span style="margin-left:20px;">{{ breakpoint }}</span>
        <div v-if="account && account.user && account.user.details" style="display:none">{{ account.user.details.firstName }}</div>
      </v-col>
    -->
      <v-col class="text-right" style="margin-top:auto;margin-bottom:auto;">
        <v-icon v-if="networkStatus" medium color="#0fff00">mdi-wifi</v-icon>
        <v-icon v-else medium :color="alertColor[currentAlertState]">mdi-wifi-off</v-icon>
      </v-col>
      <v-col  style="margin-top:auto;margin-bottom:auto;display:flex;flex-flow:row;">
        <v-menu v-if="account.user">
          <template v-slot:activator="{on, attrs}">
              <!--
              <v-avatar v-if="account.user.details && account.user.details.profileImage" size="40" v-on="on">
              -->
              <v-avatar v-if="status.loggedIn===true" size="40" v-on="on">
                <v-img v-if="account.user.details.profileImage" :src="account.user.details.profileImage"/>
                <v-icon v-else v-on="on" v-bind="attrs" medium style="color:white;padding-right:10px;">{{icons.mdiAccountCircle}}</v-icon>
              </v-avatar>
          </template>
          <v-list>
            <v-list-item>
              <v-list-item-title>
                <router-link class="header-links" to="/manageuser">
                  Account Settings
                </router-link>
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title><v-divider/></v-list-item-title>
            </v-list-item>
            <v-list-item>
                <v-list-item-title class="header-links" @click="fullLogout">
                    Sign Out
                </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <div style="font-size:xx-small;color:white;margin:0px;padding:0px;">
          <div class="text-right">
            {{ buildMajor }}
          </div>
          <div class="text-right">
            {{ buildMinor }}
          </div>
        </div>
      </v-col>
    </v-row>
  </v-system-bar>
</template>

<script lang="ts">


import { mapState, mapActions} from 'vuex'
import {
  mdiAccountCircle 
} from '@mdi/js'
import { router } from '../_helpers'
import {logout } from '../_helpers/service-tools'

export default {
    props: ['menuState'],
    data() {
        return {
          debug: process.env.VUE_APP_DEV_DEBUG==="1",
          icons: {
            mdiAccountCircle,
          },
          alertColor: [
            'red',
            'black'
          ],
          currentAlertState: 0,
          alertPoll:null,
        }
    },
    watch:{
      networkStatus(){
        if(this.networkStatus){
          clearInterval(this.alertPoll)
        } else {
          this.alertPoll = setInterval(()=>{
            const self=this
            self.currentAlertState = 1-self.currentAlertState
          }, 1000)
        }
      }
    },
    components: {
    },
    computed: {
        ...mapState({
            account: state => state.account,
            status: state => state.account.status,
            networkStatus: state => state.navigatorStore.networkStatus,
        }),
        greeting: function() {
          if(this.account.status.loggingIn) {
            return ("Welcome Back!")
          } else {
              var title = this.account.user.details.role.indexOf("coach")>-1?"Coach ": ""
              return this.account.user && this.account.user.details.firstName
                  ? `Hey, ${title}${this.account.user.details.firstName}`
                  : "Welcome Back!"
          }
        },
        initials: function() {
            return this.account.user.value && [
                this.account.user.details.firstName.substr(0,1), 
                this.account.user.value.lastName.substr(0,1)]
                .filter((e) => !!e)
                .join("")
        },
        firstName: function() {
            return this.$store.state.account.user.details.firstName;
        },
        breakpoint(){
          return (this.$vuetify.breakpoint.name) 
        },
        barStyle(){
          if(this.debug) return "no-boundaries coachsight-header debug"
          return "no-boundaries coachsight-header"
        },
        build(){
          return process.env.VUE_APP_BUILD_NUM_MAJOR+"."+process.env.VUE_APP_BUILD_NUM_MINOR
        },
        buildMajor(){
          return process.env.VUE_APP_BUILD_NUM_MAJOR
        },
        buildMinor(){
          return process.env.VUE_APP_BUILD_NUM_MINOR
        },
    },
    methods: {
      ...mapActions('navigatorStore',{
        toggleBurgerMenu: 'toggleMenu'
      }),
      logout: logout,
      toggleMenu: function() {
        this.toggleBurgerMenu()
      },
      fullLogout() {
        this.logout()
        router.push('/') 
      }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
:root {
    --cscolor1: #4c3f91;
    --cscolor2: #9145b6;
    --cscolor3: #b958a5;
    --cscolor4: #ff5677;
}

</style>
<style scoped>
.container{
  margin:0px;
  padding:0px;
}

.v-list-item__title:hover{
  cursor: pointer;
}

.col{
  padding:0px;
}

.row{
  margin:0px;
}
.main-bar {
  color: var(--cs1-lev1);
  padding:0px;
}
.cs-title {
    font-family: 'Varela Round';
    font-weight: 600;
    font-size:medium;
    padding-left:10px;
    color:white;
}

.cs-title-sm-down {
    font-family: 'Varela Round';
    font-weight: 600;
    font-size:medium;
    padding-left:10px;
    color:white;
}

.cs-subtitle{
    font-family: 'Varela Round';
    font-size: small;
    margin-top: -5px;
    padding-left:20px;
    color: white;

}
.cs-subtitle-sm-down{
    font-family: 'Varela Round';
    font-size: x-small;
    margin-top: -5px;
    padding-left:20px;
    color: white;

}

h1 img {
  padding: 10px;
}

.coachsight-header {
    width:100%; 
    display: -webkit-box;  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
    display: -ms-flexbox;  /* TWEENER - IE 10 */
    display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
    background-color:var(--cs1-lev1); 
    text-align: left;
    border-radius: 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100vw;
    height:50px;
    padding-left:5px;
    padding-right:5px;

}
/*
.coachsight-header-mobile {
    width:100%; 
    display: -webkit-box;  
    display: -ms-flexbox;  
    display: -webkit-flex; 
    background-color:var(--cs1-lev1); 
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 1vw;
    width: 97vw;

}

.coachsight-header-mobile.debug{
    background-color:red;
}
*/
.coachsight-header.debug{
    background-color:red;
}

img {
  max-width:80%;
  max-height: 80%;
  display: block;
}


.account-icon {
    padding: 15px;
    color: white;
}
.account-envelope {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
}

.cs-logo {
  object-fit: contain;
  max-height: 80%;
  padding: 0px 0px 0px 10px;
}

.logo-cell {
  margin-top: 0px;
  margin:0px;
  padding:0px;
}

.header-links {
  text-decoration: none;
  color: var(--cs1-lev1);
  font-weight: bold; 
}
.welcome{
  color: white;

}
</style>