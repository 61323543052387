<template>
    <loading v-if="loading"></loading>
    <div v-else class="regpane">
        <v-row>
            <v-col cols="4" class="details-box">
                <span class="panetitle">{{tryouts[selectedTryout].title}}</span>
                <div class="info-head">Description</div>
                <div class="info-text">{{tryouts[selectedTryout].description}}</div><br/>
                <div class="info-head">Sessions</div>
                <v-expansion-panels style="margin:10px !important; padding:10px!important">
                    <v-expansion-panel v-for="id in sessionList" :key="id">
                        <v-expansion-panel-header>
                            <span :class="{strike: oldDate(sessions[id].startDateTime)}">{{ moment(sessions[id].startDateTime).format('ddd Do MMM, YYYY') }} </span><span class="session-title">{{   sessions[id].title}}</span>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <div class="info-head">Venue</div>
                            <div class="info-text">{{ sessions[id].venue }}</div>
                            <div class="info-head">Times</div>
                            <div class="info-text"> {{moment(sessions[id].startDateTime).format('HH:mm') }} - {{ moment(sessions[id].endDateTime).format('HH:mm') }} </div>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>
            <v-col v-if="participant.participantNumber" style="margin-top:0px">
                <div class="section-title">Your application details</div><br/>
                <div class="info-head">Your Athlete Number</div>
                <div class="athlete-id">{{participant.participantNumber}}</div>

                <span class="info-head">Your application responses</span>
                <QuestionResponseShortText v-for="(id, idx) in questionList" :key="id" :questionNum="idx+1" :questionId="id"/>

            </v-col>
        </v-row>

      
    </div>


</template>

<script>
import {mapState, mapActions} from 'vuex'
import Loading from '@/components/loading'
import QuestionResponseShortText from '../components/QuestionResponseShortText.vue'

export default {
    props: {
        partId: {
            default:null
        }
    },
    components: {
    Loading,
    QuestionResponseShortText,
},
    computed: {
        ...mapState({
            selectedTryout: state => state.navigatorStore.selectedTryout,
            tryouts: state => state.tryoutStore.data,
            tryoutStatus: state => state.tryoutStore.status,
            formStep: state => state.tryoutregStore.step,
            participant: state => state.participantStore.participant,
            participantStatus: state => state.participantStore.status,

            formResponses: state => state.qresponseStore.responses,
            formResponseStatus: state => state.qresponseStore.status,

            questionList: state => state.appquestionStore.list,
            questions: state => state.appquestionStore.data,
            questionStatus: state => state.appquestionStore.status, 

            sessions:   state => state.sessionStore.data,
            sessionList: state => state.sessionStore.list,
            sessionStatus: state => state.sessionStore.status,

            navInitialising: state => state.navigatorStore.initialising,

        }),
        questionLabel: function(id) {
            return `Q${this.questions[id].order+1}: ${this.questions[id].question}`
        },
        loading: {
            get() {
                return false
                    || this.participantStatus.loading
                    || this.tryoutStatus.loading
                    || this.navInitialising
                    || this.questionStatus.loading
                    || this.formResponseStatus.loading
                    || this.selectedTryout===''
            }
        },
        participantId(){
            if(this.partId) return this.partId
            return this.tryouts[this.selectedTryout].participantId
        }

    },
    methods: {
        ...mapActions('appquestionStore', {
            findQuestionsForEvent: 'loadAllQuestionsForEvent',
        }),
        ...mapActions('sessionStore', {
            findAllSessions: 'findAllForEvent',
        }),
        ...mapActions('tryoutregStore', {
            setStep: 'setStep',
        }),
        ...mapActions('participantStore', {
            findParticipant: 'find',
        }),
        ...mapActions('qresponseStore',{
            findResponses: 'findAllForParticipant',
        }),
        oldDate(date){
            return new Date(date) < new Date()
        },
    },
    mounted() {
    },
    async created() {
            await this.findParticipant({participantId: this.participantId})
            await this.findQuestionsForEvent({eventId: this.selectedTryout})
            await this.findResponses(this.participantId)
            await this.findAllSessions(this.selectedTryout)
    },
    data() {
        return {
            notReady: true,
        }
    }
}


</script>

<style scoped>

    .details-box {
        border-style: solid;
        border-width: 1px;
        border-color: var(--cs1-lev1);
        border-radius: 10px;
        margin-top:0px;
    }

    .strike {
        color: red;
        text-decoration: line-through;
    }
    .regpane {
        padding:10px;
    }

    .finishedEvent {
        color:var(--error-red);
        text-decoration: line-through;
    }
    .panetitle {
        font-weight: 900;
        font-size: larger;
        line-height: 200%;
        text-align:left;
    }
    .panedate {
        font-weight: 500;
        line-height: 200%;
    }
    .session-title {
        display: block;
        text-align: right;
        font-size: medium;
        margin-right:50px;
    }
    .section-title {
        display: block;
        text-align: left;
        font-weight: 900;
        font-size: large;
    }

    .description {
        text-align: left;
    }

    .fullpane {
        height: 100%;
        margin: 10px;
    }

    .buttons {
        margin-top:10px;
    }
    .contact-name {
    font-weight:600;
    }

    .contact-phone {
        line-height: 50%;
    }

    .contact-email {
        line-height: 50%
    }

    .athlete-id {
        display: flex;
        justify-content: center;
        width: 5%;

        margin-left: 30px;
        margin-right: 0;
        margin-top: 10px;
        margin-bottom: 30px;
        padding: 10px;

        font-size:20px;
        color: white;
        font-weight: 900;

        border-radius: 10px;
        border-style: solid;

        background: blue;
    }

    .info-head{
        display: block;
        text-align: left;
        font-weight: 900;
        font-size:small;
        margin-bottom:10px;
    }
    .info-text {
        display: block;
        text-align: left;
        font-size: small;
        margin-left:30px;
    }
</style>
