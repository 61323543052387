import { participantService } from '../_services'
import Vue from 'vue'

import {rc_participants} from '@/_helpers/responseCodes'

const dataService = participantService

function initialiseState(){
    return {
        status: {},
        list: [],
        listByFirstName: [],
        listByLastName: [],
        listByUserId:{},
        data: {},
        meta: {},
        error: null,
        selection: {},
        participant: null,
        newParticipant: {},
        internal: {},
        displayList:[]
    }
}

const state = initialiseState()

const actions = {
    purgeData({commit}){
        commit('purgeData')
    },
    // eslint-disable-next-line
    "SOCKET_ParticipantUpdated"({commit},data){

        commit('fetchUpdateRequest')
        commit('fetchUpdateSuccess', data)
    },
    async fetchUpdate({commit},payload){
        try{
            const {participantId} = payload
            commit('fetchUpdateRequest')
            const response = await dataService.find(participantId)
            commit('fetchUpdateSuccess', response)
        } catch(error) {
            commit('fetchUpdateFailure', error)
        }
    },
    async loadParticipant({commit}, payload){
        try{
            const {participantId} = payload
            commit('findRequest')
            const response = await dataService.find(participantId)
            commit('findSuccess', response)
            return response.data
        } catch(error) {
            commit('findFailure', error)
        }
    },
    /*** Retire *** */
    async find({commit}, payload){
        try{
            const {participantId} = payload
            commit('findRequest')
            const response = await dataService.find(participantId)
            commit('findSuccess', response)
            return response.data
        } catch(error) {
            commit('findFailure', error)
        }
    },
    async findByName({commit}, payload){
        try {
            commit('findRequest')
            const response = await dataService.findByName({firstName: payload.firstName, lastName: payload.lastName , eventId: payload.eventId, clubId: payload.clubId})
            commit('findSuccess', response)
        } catch(error) {
            commit('findFailure', error)
            console.error('Error findByName: ')
            console.error(error)
        }
    },
    //eslint-disable-next-line
    async acceptOffer({commit, dispatch}, id){
        try{
            commit('updateRequest')
            const response=await dataService.acceptOffer(id)
            commit('updateSuccess', response)
            dispatch('alert/success',"That's awesome. We'll let the club know that you've accepted.", {root: true})
        } catch(error) {
            commit('updateFailure', error)
        }
    },
    async declineOffer({commit, dispatch}, id){
        try{
            commit('updateRequest')
            const response = await dataService.declineOffer(id)
            commit('updateSuccess', response)
            dispatch('alert/error',"We're sorry to hear you've declined our offer. We wish you all the best for your year, and hope to see you again next season!", {root: true})
        } catch(error) {
            commit('updateFailure', error)
        }
    },
    async clearOffer({commit, dispatch}, id){
        try{
            commit('updateRequest')
            const response = await dataService.clearOffer(id)
            commit('updateSuccess', response)
            dispatch('alert/error',"The offer for this participant has been revoked", {root: true})
        } catch(error) {
            commit('updateFailure', error)
        }
    },
    async loadAllParticipantsForTeam({commit},payload) {
        try{
            commit('loadRequest')
            const response = await dataService.loadAllParticipantsForTeam(payload)
            commit('loadSuccess', response)
            return response
        } catch(error) {
            commit('loadFailure', error)
        }
    },
    async loadParticipantsForEvent({commit},payload) {
        try{
            const {userId, eventId} = payload
            commit('loadRequest')
            const response = await dataService.inEvent(userId, eventId)
            commit('loadSuccess', response)
            return response
        } catch(error) {
            commit('loadFailure', error)
        }
    },
    /****(RETIRE) */
    async inEvent({commit},payload) {
        try{
            const {userId, eventId} = payload
            commit('loadRequest')
            const response = await dataService.inEvent(userId, eventId)
            commit('loadSuccess', response)
            return response
        } catch(error) {
            commit('loadFailure', error)
        }
    },
    async quietFindAllForEvent({dispatch, commit}, id) {
        try{
            commit('quietFindAllRequest')
            const response = await dataService.findAllForEvent(id)
            commit('findAllSuccess', response)
        } catch(error) {
            commit('findAllFailure', error)
            dispatch('alert/error', error.error, {root: true})
        }
    },

    async loadAllParticipantsForEvent({dispatch, commit}, payload) {
        try{
            commit('findAllRequest')
            const response = await dataService.findAllForEvent(payload.eventId)
            commit('findAllSuccess', response)
            return response
        } catch(error) {
            commit('findAllFailure', error)
            dispatch('alert/error', error.error, {root: true})
        }
    },
    /* RETIRE*/
    async findAllForEvent({dispatch, commit}, id) {
        try{
            commit('findAllRequest')
            const response = await dataService.findAllForEvent(id)
            commit('findAllSuccess', response)
        } catch(error) {
            commit('findAllFailure', error)
            dispatch('alert/error', error.error, {root: true})
        }
    },
    async publicParticipantList({commit}, payload) {
        try{
            commit('findAllRequest')
            const response = await dataService.findPublic({eventId: payload.eventId})
            commit('findAllSuccess', response)
            return response
        } catch(error) {
            commit('findAllFailure', error)
        }
    },

    async addManual({dispatch, commit} ,data) {
        try{
            commit('addManualRequest')
            const response=await dataService.addManual(data)
            commit('addManualSuccess' , response.data)
            return response
        } catch(error) {
            commit('addManualFailure', error)
            dispatch('alert/error', error.message, {root:true})
            throw error
        }
    },
    async add({dispatch, commit}, data) {
        try{
            commit('addRequest')
            const response=await dataService.add(data)
            commit('addSuccess',response.participant)
        } catch(error) {
            commit('addFailure',error)
            dispatch('alert/error', error.error, {root: true})
            throw new Error(error)
        }
    },
    async updateParticipant({dispatch, commit}, data) {
        try{
            commit('updateRequest')
            const response = await dataService.update(data)
            setTimeout(() => {
                commit('updateSuccess', response)
            })

        } catch(error) {
            setTimeout(() => {
                commit('updateFailure', error)
                dispatch('alert/error', error.error, {root: true})
            })
        }
    },
    /**** RETIRE ** */
    async update({dispatch, commit}, data) {
        try{
            commit('updateRequest')
            const response = await dataService.update(data)
            setTimeout(() => {
                commit('updateSuccess', response)
            })

        } catch(error) {
            setTimeout(() => {
                commit('updateFailure', error)
                dispatch('alert/error', error.error, {root: true})
            })
        }
    },
    async updateLocal({commit}, payload){
        commit('updateLocalRequest', payload)
    },
    async archive({commit}, data){
        try{
            commit('archiveRequest', data)
            const response = await dataService.archive(data)
            commit('archiveSuccess', response)
        } catch(error) {
            commit('archiveFailure', error)
        }
    },
    async unarchive({commit}, data){
        try{
            commit('unarchiveRequest', data)
            const response=await dataService.unarchive(data)
            commit('unarchiveSuccess', response)
        } catch(error) {
            commit('unarchiveFailure', error)
        }
    },
    updateParticipantProp({commit},payload) {
        commit('propertyUpdate', payload)
    },
    propertyUpdate({commit},payload) {
        commit('propertyUpdate', payload)
    },
    setDisplayList({commit}, payload) {
        commit('setDisplayList', payload)
    },

}

function addToLists(data) {
    const id = data.id
    if(!state.list.includes(id)){
        state.list.push(id)
        state.listByFirstName.push(id)
        state.listByLastName.push(id)

    }

    Vue.set(state.listByUserId, data.userId, id)
    Vue.set(state.data, id, data)
    Vue.set(state.meta, id, {
            delete : false,
            selected: false,
    })
}


function sortListByOrder() {
        state.list.sort((a,b) => {
            return state.data[a].participantNumber - state.data[b].participantNumber
        })
}

function sortListByLastname() {
    state.listByLastName = state.listByLastName.sort((a,b) => {
        return state.data[a].lastName.localeCompare(state.data[b].lastName)
    })
}
function sortListByFirstname() {
    state.listByFirstName = state.listByFirstName.sort((a,b) => {
        return state.data[a].firstName.localeCompare(state.data[b].firstName)
    })
}

const mutations = {
purgeData(state){
    const data = initialiseState()
        for(var idx in Object.keys(data)){
            const key = Object.keys(data)[idx]
            state[key]=data[key]
        }
    },
       setDisplayList(state, payload) {
        state.displayList = payload
    },
    archiveRequest(state, data){
        state.internal.pid = data.participantId 
    },
    //eslint-disable-next-line
    archiveSuccess(state, data){
        const id=state.internal.pid

        const newData = state.data[id]
        newData.archived=true
        Vue.set(state.data, id, newData)

    },
    archiveFailure(state, error){
        state.error = error
    },
    unarchiveRequest(state, data){
        state.internal.pid = data.participantId 
    },
    //eslint-disable-next-line
    unarchiveSuccess(state, data){
        const id=state.internal.pid

        const newData = state.data[id]
        newData.archived=false
        Vue.set(state.data, id, newData)

    },
    unarchiveFailure(state, error){
        state.error = error
    },
    // eslint-disable-next-line
    fetchUpdateRequest(state){
        state.status.loading=true;
    },
    // eslint-disable-next-line
    fetchUpdateSuccess(state, payload){

        const data = payload
        const id = data.id

        if(!state.list.includes(id)){
            state.list.push(id)
            Vue.set(state.listByUserId, data.userId, id)
        }
    
        if(!state.listByFirstName.includes(id)) state.listByFirstName.push(id)
        if(!state.listByLastName.includes(id)) state.listByLastName.push(id)
    
        Vue.set(state.data, id, data)
        state.status.loading=false
    },
    fetchUpdateFailure(state, error){
        state.error = error
    },



    findRequest(state){
        state.status={loading: true}
    },
    findSuccess(state, response){

        state.participant=response.data.result
        state.status = {code: response.data.code}
        if (response.data.code === rc_participants.PARTICIPANT_FOUND){
            addToLists(response.data.result)
        }
       // state.data={}
       // state.list=[]
       // addToLists(data)
    },
    findFailure(state, error) {
        state.status={}
        state.error=error
    },
    addManualRequest(state) {
        state.status={loading:true}
        state.error=null
    },
    addManualSuccess(state, participant){
        state.status={}
        state.participant = participant
        state.newParticipant = {participant: participant}
    },
    addManualFailure(state, error) {
        state.status={}
        state.error=error

    },
    addRequest(state) {
        state.status={loading: true}
        state.error=null
        state.list=[]
        state.data={}

    },
    //eslint-disable-next-line
    addSuccess(state, data) {
        state.status = {added: data}
        addToLists(data)
        sortListByOrder()
        sortListByLastname()
    },
    addFailure(state, error) {
        state.status = {}
        state.error = error
    },

    //--------- FIND ALL -------\\

    quietFindAllRequest(state) {
        state.status = {}
        state.error=null
    },
    findAllRequest(state) {
        state.status = {loading: true}
        state.error=null
    },
    findAllSuccess(state, data) {
        state.list=[]
        state.listByFirstName=[]
        state.listByLastName=[]
        state.data={}

        for(var idx in data) {
//            data[idx].participantNumber = data[idx].participantNumber % 100
            addToLists(data[idx])
        }
        sortListByOrder()
        sortListByLastname()
        sortListByFirstname()
        state.status = {}
    },
    findAllFailure(state, error) {
        state.status = {}
        state.error = error
    },

    //------ UPDATE -------\
    updateRequest(state) {
        state.internal.status={loading: true}
        state.error=null
    },
    //eslint-disable-next-line
    updateSuccess(state, data){
        state.internal.status={updated: true}
        state.error=null
    },
    updateFailure(state, error) {
        state.internal.status={}
        state.error=error
    },
    updateLocalRequest(state, payload){
        // Only update the fields that have been presented...

        const keys = Object.keys(payload)
        for(var idx in keys){
            state.data[payload.id][keys[idx]] = payload[keys[idx]]
        }
    },

    // ------------- loadTryout ------------- \
    
    loadRequest(state) {
        state.status={loading: true}
        state.error=null
        state.data={}
        state.list=[]
        state.listByFirstName=[]
        state.listByLastName=[]

    },
    loadSuccess(state, data) {
        state.data={}
        state.list=[]
        state.listByFirstName=[]
        state.listByLastName=[]
        for(var idx in data) {
            addToLists(data[idx])
        }
        sortListByOrder()
        sortListByLastname()
        sortListByFirstname()
        state.status={}
    },
    loadFailure(state, error) {
        state.status={}
        state.error = error
    },

    //------------ update data -------- \\
    propertyUpdate(state, payload) {
        const metaFields = "delete, selected "
        const {field, id, value} = payload

        if(id===0){
            Vue.set(state.participant, field, value)
            return
        }

        if(metaFields.includes(field)){
            state.meta[id][field]=value
            // Manage special fields...
            //switch (field) {
            //}
        } else {
            const data = state.data[id]
            data[field]=value
            Vue.set(state.data, id, data)
        }
    }

}
export const participantStore = {
    namespaced: true,
    state,
    actions,
    mutations
}