import Vue from 'vue';
import Router from 'vue-router';

//import HomePage from '../home/HomePage'
import ClubHome from '../home/ClubHome'
import LoginPage from '../login/LoginPage'
import RegisterPage from '../register/RegisterPage'
import ManageUserPage from '../manageuser/ManageUserPage'
//import TryoutPage from '../tryout/TryoutPage'
//import AddTryoutPage from '../tryout/AddTryoutPage'
//import EditTryoutPage from '../tryout/EditTryoutPage'
import ParticipantRegister from '../participant/ParticipantRegister'
import RegistrationSuccess from '../participant/RegistrationSuccess'
import AlreadyRegistered from '../participant/AlreadyRegistered'
import VerificationFailed from '../login/VerificationFailed'
import ExportTryoutData from '../admin_pages/ExportTryoutData'
//import RunTryout from '../tryout/RunTryout'
//import SkillCapture from '../tryout/SkillCapture'
import RecoverPassword from '../login/RecoverPassword'
import CheckYourEmail from '../login/CheckYourEmail'
import ResetPassword from '../login/ResetPassword'
import Mailouts from '../admin_pages/Mailouts'
import OfferMailout from '../admin_pages/OfferMailout'
import ReviewOffer from '../participant/ReviewOffer'
//import ParticipantList from '../participant/ParticipantList'
import ClubRooms from '../home/ClubRooms'
import TCPaymentSuccess from '../ClubPanes/TryoutRegistrationPanels/TCPaymentSuccess'
import TCPaymentFailure from '../ClubPanes/TryoutRegistrationPanels/TCPaymentFailure'
import TCPaymentCancelled from '../ClubPanes/TryoutRegistrationPanels/TCPaymentCancelled'
import OCPaymentSuccess from '../ClubPanes/OfferResponse/OCPaymentSuccess'
import OCPaymentFailure from '../ClubPanes/OfferResponse/OCPaymentFailure'
import OCPaymentCancelled from '../ClubPanes/OfferResponse/OCPaymentCancelled'
//import SessionSignIn from '../home/SessionSignIn'
import CheckinSession from '@/checkin/CheckinSession'
import DeskProcess from '../home/DeskProcess'
import SystemDashboard from '../SystemAdminPages/SystemDashboard'
import CompleteRegistration from '@/login/CompleteRegistration'
import FourOhFour from '@/components/FourOhFour'
import SessionPoster from '@/components/SessionPoster'

import PCPaymentSuccess from '@/ClubPanes/Payments/PCPaymentSuccess'
import PCPaymentFailure from '@/ClubPanes/Payments/PCPaymentFailure'
import PCPaymentCancelled from '@/ClubPanes/Payments/PCPaymentSuccess'

//import { sessionService } from '../_services';

Vue.use(Router);

export const router = new Router({
    mode: 'history',
    routes: [
        { path: '/', component: ClubRooms },
        { path: '/clubhouse/:clubId', component: ClubHome, props: true},
        { path: '/clubhouse/:clubId/:token', component: ClubHome, props: true},
        { path: '/login', component: LoginPage },
        { path: '/recoverpassword', component: RecoverPassword },
        { path: '/resetpassword/:token', component: ResetPassword, props: true},
        { path: '/resetpassword/:token/:newUser', component: ResetPassword, props: true},
        { path: '/checkyouremail', component: CheckYourEmail },
        { path: '/register', component: RegisterPage },
        { path: '/manageuser', component: ManageUserPage},
//        { path: '/tryouts', component: TryoutPage },
//        { path: '/addtryout', component: AddTryoutPage },
//        { path: '/edittryout/:id', component: EditTryoutPage, name: 'edittryout', props: true },
        { path: '/registerfortryout/:tryoutId', component: ParticipantRegister, name: 'participantregister', props: true},
        { path: '/registrationsuccess', component: RegistrationSuccess, name: 'regitrationsuccess', props: true},
        { path: '/alreadyregistered', component: AlreadyRegistered, name: 'alreadyregistered'},
        { path: '/verificationfailed', component: VerificationFailed, name: 'verificationfailed'},
        { path: '/exporttryoutdata/:tryoutId', component: ExportTryoutData, name: 'exporttryoutdata', props: true },
//        { path: '/runtryout', component: RunTryout, name: 'runtryout', props: true},
//        { path: '/skillcap/:sessionId', component: SkillCapture, name: 'skillcapture', props: true},
        { path: '/participantmailout', component: Mailouts, name:'participantmailout'},
        { path: '/offermailout/:eventId', component: OfferMailout, name:'offermailout', props: true},
        { path: '/reviewoffer/:participantId', component: ReviewOffer, name:'reviewoffer', props: true},
//        { path: '/partlist/:eventId', component: ParticipantList, name:'partlist', props:true},
        { path: '/stripe/onboard/success', component: ClubHome, props:true},
        { path: '/stripe/onboard/refresh', component: ClubHome, props:true},
        { path: '/stripe/tryoutcharge/success/:token/:type', component: TCPaymentSuccess, props:true},
        { path: '/stripe/tryoutcharge/failure', component: TCPaymentFailure, props: true},
        { path: '/stripe/tryoutcharge/cancelled', component: TCPaymentCancelled, props: true},
        { path: '/stripe/offercharge/success/:token/:type', component: OCPaymentSuccess, props:true},
        { path: '/stripe/offercharge/failure', component: OCPaymentFailure, props: true},
        { path: '/stripe/offercharge/cancelled', component: OCPaymentCancelled, props: true},

        { path: '/stripe/packagecharge/success/:token/:type', component: PCPaymentSuccess, props:true},
        { path: '/stripe/packagecharge/failure', component: PCPaymentFailure, props: true},
        { path: '/stripe/packagecharge/cancelled', component: PCPaymentCancelled, props:true},


//        { path: '/signinforsession/:sessionId/:eventId', component: SessionSignIn, props: true},
        { path: '/signinforsession/:sessionId/:eventId', component: CheckinSession, props: true},
        { path: '/signin2/:sessionId/:participantId', component: DeskProcess, props: true},
        { path: '/sysadmin/dashboard', component: SystemDashboard, props: true},
        { path: '/completeregistration/:token', component: CompleteRegistration, props: true},
        { path: '/sessionPoster/:sessionId/:eventId', component: SessionPoster, props:true},
        { path: '/404', component: FourOhFour, props: true},
        { path:  '*', redirect: '/' },
    ]
})

router.beforeEach((to, from, next) => {
    // redirect to login page is not logged in and trying to access a restricted page
    const publicPages = [
            'checkyouremail', 
            'completeregistration', 
            'recoverpassword', 
            'resetpassword', 
            'login', 
            'register', 
            'verificationfailed', 
            'signinforsession', 
            'stripe',
            '404'
        ];
    const pathBits=to.path.split('/')
    const pathRoot = pathBits[0]===''?pathBits[1]:pathBits[0]
    const authRequired = (!publicPages.includes(pathRoot) && (!to.path.startsWith('/resetpassword/')) && (!to.path.startsWith('/completeregistration/')))
    const localUser = localStorage.getItem('user')
    const loggedIn = (localUser !== null && localUser !== 'undefined') 

    if(to.fullPath && to.fullPath.includes('verified=1')) {
        sessionStorage.setItem('verified', true)
    }

        const field=to.redirectedFrom? 'redirectedFrom':'path'
        const pathElements = to[field].slice(1).split('/')
        sessionStorage.setItem('pathElements', JSON.stringify(pathElements))

    if(     (to.path && to.path.startsWith('/registerfortryout/')) 
        ||  (to.redirectedFrom && to.redirectedFrom.startsWith('/registerfortryout/'))
        ||  (to.path && to.path.startsWith('/reviewoffer/'))
        ||  (to.redirectedFrom && to.redirectedFrom.startsWith('/reviewoffer/')) 
        ||  (to.path && to.path.startsWith('/signin2/'))
        ||  (to.redirectedFrom && to.redirectedFrom.startsWith('/signin2/'))
        ) {


        const from= to[field]
        const id=to[field].slice(19)

        sessionStorage.setItem('redirectPath', from)
        sessionStorage.setItem('tryoutId', id)
    } 

    if(authRequired && !loggedIn) {
        return next('/login')
    }
    next()
})