<template>
    <loading v-if="loading"></loading>
    <div v-else style="width:100%">
        <template>
            <PermanentConfirm v-if="tryouts[deleteTryoutId]" :show="deleteDialog" :confirm="delTryout" :cancel="closeDeleteDialog" actionName="Delete Tryout" :identifier="tryouts[deleteTryoutId].title">
                <template v-slot:consequences>
                    All data, including tryout structure, details and participant data will be deleted and not retreivable. 
                </template>
            </PermanentConfirm>
            <PermanentConfirm v-if="teams[deleteTeamId]" :show="deleteTeamDialog" :confirm="delTeam" :cancel="closeDeleteTeamDialog" actionName="Delete Team" :identifier="teams[deleteTeamId].name">
                <template v-slot:consequences>
                    All data, including team recommendations, will be deleted and not retrievable. 
                </template>
            </PermanentConfirm>
            <v-dialog 
                v-model="dialog"
                persistent
                max-width="600px"
                activator="parent"
            >
                <ValidationObserver ref="form" v-slot="{handleSubmit}">
                        <v-card style="opacity:1">
                                <form @submit.prevent="handleSubmit(save)" style="margin-top:0px">
                            <v-card-text>
                                <h3>Add a new tryout</h3> 
                                    <div>
                                        <ValidationProvider name="Title" rules="required|min:7" v-slot="{errors}">
                                            <v-text-field label="Title" hide-details="auto" v-model="newTryout.title"/>
                                            <div class="form-error">{{ errors[0] }}</div>
                                        </ValidationProvider>
                                        <ValidationProvider name="Sport" rules="required" v-slot="{errors}">
                                            <v-select :items="sportsArray" label="Tryout Sport" chips v-model="newTryout.sportId"/>
                                            <div class="field-error">{{ errors[0] }}</div>
                                        </ValidationProvider>
                                        <ValidationProvider name="Description" rules="required" v-slot="{errors}">
                                            <v-textarea label="Description" hide-details="auto" v-model="newTryout.description"/>
                                            <div class="form-error">{{ errors[0] }}</div>
                                        </ValidationProvider>
                                        <div class="dates">
                                            <div class=date>
                                                <DateCombo title="Start Date" :rules="[]" v-model="newTryout.startDate"/>
                                            </div>
                                            <div class="date">
                                                <DateCombo title="End Date: " :rules="[]" v-model="newTryout.endDate"/>
                                            </div>
                                        </div>

                                    </div> 
                            </v-card-text> 
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn 
                                    color="blue darken-1"
                                    text
                                    @click="closeDialog"
                                >Close</v-btn>
                                <v-btn 
                                    color="blue darken-1"
                                    text 
                                    type="submit"
                                >Save</v-btn>
                            </v-card-actions>
                    </form>
                        </v-card>
                </ValidationObserver>
            </v-dialog>

            <!--
            | This dialog will be retired to a Team Home page 
            -->
            <v-dialog 
                v-model="editTeamDialog"
                persistent
                max-width="80%"
                style="padding-top:0px"
                width="600px"
            >
                <team-add-edit :teamId="selectedTeam"/>

            </v-dialog>
            <v-dialog
                v-model="editDevStreamDialog" 
                persistent
                max-width="80%"
                width = "900px"
            >
                <dev-stream-add-edit :devStreamId="null"/>
            </v-dialog>
        </template>
        <v-row>
            <v-col md="11" sm="12" xs="12" class="pane-heading">
                Season Home
            </v-col>
                
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-divider/>
            </v-col>
        </v-row>

        <v-row>
            <v-col v-if="isAdminUser() || inSeasonTeams.length>0 || inSeasonTryouts.length>0 || inSeasonDevStreams.length>0" cols="12">
                <v-tabs fixed-tabs v-model="tab" >
                    <v-tab v-if="isAdminUser() || inSeasonTryouts.length>0" key="1">
                        My Tryouts
                    </v-tab>
                    <v-tab v-if="isAdminUser() || inSeasonTeams.length>0" key="2">
                        My Teams
                    </v-tab>
                    <!-- 
                    <v-tab v-if="account.beta && (isAdminUser() || inSeasonDevStreams.length>0)" key="3">
                    -->
                    <v-tab v-if="(isAdminUser() || inSeasonDevStreams.length>0)" key="3">
                        My Development Programs
                    </v-tab>
                    <v-tab v-if="isAdminUser()" key="4">
                        Season Details
                    </v-tab>
                </v-tabs>
                <br/>
                <v-tabs-items v-model="tab">
                    <v-tab-item v-if="isAdminUser() || inSeasonTryouts.length>0" key="1">
                        <v-row >
                            <v-col
                                xs="12"
                                sm="6"
                                md="4"
                                lg="4"
                                xl="3"
                                v-for="tryoutId in inSeasonTryouts" 
                                :key="tryoutId"
                            >
                                <v-hover v-slot="{hover}">
                                    <v-card 
                                        :elevation="hover?12:1"
                                        :class="{'hover-card':true, 'on-hover': hover}"
                                        rounded
                                        max-width="200px"
                                    >

                                        <v-img 
                                            :src="sports[tryouts[tryoutId].sportId].cardImage" 
                                            class="white--text align-end"
                                            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                                            max-height="150px"
                                            @click="homeTryout(tryoutId)"
                                        >
                                            <v-card-title class="card-title">{{ tryouts[tryoutId].title }}</v-card-title>

                                        </v-img>
                                        <v-card-actions>
  
                                            <v-row class="no-boundaries">
                                                <div v-if="tryouts[tryoutId].headcoach" class="role-pill headcoach">Head Coach</div>
                                                <div v-if="tryouts[tryoutId].admin" class="role-pill admin">Admin</div>
                                                <div v-if="tryouts[tryoutId].owner" class="role-pill owner">Club Manager</div>
                                                <div v-if="tryouts[tryoutId].assistantcoach" class="role-pill assistantcoach">Assistant Coach</div>
                                                <div v-if="tryouts[tryoutId].selector" class="role-pill selector">Selector</div>
                                                <div v-if="tryouts[tryoutId].support" class="role-pill support">Support Staff</div>
                                                <div v-if="tryouts[tryoutId].athlete" class="role-pill athlete">{{ tryouts[tryoutId].participantPronoun }}</div>

                                            </v-row>
                                            <v-row class="no-boundaries">
                                                <v-col cols="12">
                                                    <v-spacer></v-spacer>

                                                    <v-tooltip v-if="tryouts[tryoutId].onlineReg && tryouts[tryoutId].athlete && tryouts[tryoutId].registered && tryouts[tryoutId].registrationStatus==='incomplete' && tryouts[tryoutId].feeExemption" bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            icon
                                                            >
                                                                <v-icon @click="completeReg(tryoutId)">mdi-file-document-check-outline</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>Complete Registration</span>
                                                    </v-tooltip>

                                                    <v-tooltip v-else-if="tryouts[tryoutId].onlineReg && tryouts[tryoutId].athlete && tryouts[tryoutId].registered && tryouts[tryoutId].registrationStatus==='incomplete' && !tryouts[tryoutId].feeExemption" bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            icon
                                                            >
                                                                <v-icon @click="completeReg(tryoutId)">mdi-account-credit-card</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>Complete and Pay for Tryout</span>
                                                    </v-tooltip>
                                                    <v-tooltip v-else-if="tryouts[tryoutId].onlineReg && tryouts[tryoutId].athlete && tryouts[tryoutId].registered" bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            icon
                                                            >
                                                                <v-icon @click="reviewReg(tryoutId)" >mdi-text-box-search-outline</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>View Your Application</span>
                                                    </v-tooltip>
                                                    <v-tooltip v-else-if="tryouts[tryoutId].onlineReg"  bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            icon
                                                            >
                                                                <v-icon @click="tryoutReg(tryoutId)" >mdi-file-sign</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>Register for Tryout</span>
                                                    </v-tooltip>

                                                    <v-tooltip v-if="clubs[selectedClub].owner || tryouts[tryoutId].admin" bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            icon
                                                            >
                                                                <v-icon @click="deleteTryoutId=tryoutId;deleteDialog=true">mdi-trash-can</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>Delete Tryout</span>
                                                    </v-tooltip>
                                                </v-col>
                                            </v-row>

                                        </v-card-actions>
                                    </v-card>
                                </v-hover>
                            </v-col> 
                            <v-col
                            xl="3"
                                lg="4"
                                md="4"
                                sm="6"
                                xs="12"
                            >
                            <v-hover v-if="clubs[selectedClub].owner" v-slot="{hover}">
                                    <v-card 
                                        :elevation="hover?12:1"
                                        :class="{'hover-card':true, 'on-hover': hover}"
                                        rounded
                                        max-width="200px"
                                    >
                                        <v-img 
                                            src="https://s3.ap-southeast-2.amazonaws.com/www.coachsight.net/app_assets/Multi-sport-logo-add.gif"
                                            class="white--text align-end"
                                            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                                            max-height="150px"
                                            contain
                                            @click="showAddDialog"
                                        >
                                        </v-img>
                                        <v-card-actions>
                                            <v-spacer/>
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    icon
                                                    >
                                                        <v-icon @click="showAddDialog">mdi-plus</v-icon>

                                                    </v-btn>
                                                </template>
                                                <span>New Tryout</span>
                                            </v-tooltip>
                                        </v-card-actions>

                                    </v-card>
                                </v-hover>
                        
                            </v-col>
                        </v-row>
                    </v-tab-item>

                    <v-tab-item v-if="isAdminUser() || inSeasonTeams.length>0" key="2">
                        <v-row>
                            <v-col 
                                v-for="teamId in inSeasonTeams"
                                :key="teamId"
                                xl="3"
                                lg="4"
                                md="4"
                                sm="6"
                                xs="12"
                            >
                                <v-hover v-slot="{hover}">
                                    <v-card :elevation="hover?12:0" :class="{'hover-card':true,'on-hover':hover}" rounded max-width="200px">
                                        <!--
                                        <v-img
                                            :src="teamLogo(teamId)"
                                            class="white--text align-end"
                                            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                                            max-height="150px"
                                            @click="editTeam(teamId)"
                                        >
                                    -->
                                        <v-img
                                            :src="teamLogo(teamId)"
                                            class="white--text align-end"
                                            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                                            max-height="150px"
                                            @click="teamHomeNav(teamId)"
                                        >
                                            <v-card-title class="card-title">{{ teams[teamId].name }}</v-card-title>
                                        </v-img>
                                        <v-card-actions>
                                            <v-row>
                                                <v-col cols="12">
                                                    <div v-if="teams[teamId].headcoach" class="role-pill headcoach">Head Coach</div>
                                                    <div v-if="teams[teamId].admin" class="role-pill admin">Admin</div>
                                                    <div v-if="teams[teamId].owner" class="role-pill owner">Club Manager</div>
                                                    <div v-if="teams[teamId].assistantcoach" class="role-pill assistantcoach">Assistant Coach</div>
                                                    <div v-if="teams[teamId].selector" class="role-pill selector">Selector</div>
                                                    <div v-if="teams[teamId].support" class="role-pill support">Support Staff</div>
                                                    <div v-if="teams[teamId].athlete" class="role-pill athlete">Athlete</div>

                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12">
                                                    <v-spacer/>
                                                    <v-tooltip v-if="clubs[selectedClub].owner" bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                icon
                                                            >
                                                                <v-icon @click="deleteTeamId=teamId;deleteTeamDialog=true">mdi-trash-can</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>Delete Team</span>
                                                    </v-tooltip>

                                                </v-col>
                                            </v-row>

                                        </v-card-actions>
                                    </v-card>
                                </v-hover>
                            </v-col>
                            <v-col
                                xl="3"
                                lg="4"
                                md="4"
                                sm="6"
                                xs="12"
                                v-if="clubOwnerOrAdmin"
                            >
                                <v-hover v-slot="{hover}">
                                    <v-card :elevation="hover?12:0" :class="{'on-hover':hover}" rounded max-width="200px">
                                        <v-img 
                                            src="https://s3.ap-southeast-2.amazonaws.com/www.coachsight.net/app_assets/Multi-sport-logo-add-new-team.gif"
                                            class="white--text align-end"
                                            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                                            max-height="150px"
                                            contain
                                            @click="showAddTeamDialog"
                                        >
                                        </v-img>
                                        <v-card-actions>
                                            <v-spacer/>
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    icon
                                                    >
                                                        <v-icon @click="showAddTeamDialog">mdi-plus</v-icon>

                                                    </v-btn>
                                                </template>
                                                <span>New Team</span>
                                            </v-tooltip>
                                        </v-card-actions>
                                    </v-card>
                                </v-hover>
                            </v-col>
                        </v-row>
                    </v-tab-item>

                    
                    <!--
                    <v-tab-item v-if="account.beta && (isAdminUser() || inSeasonDevStreams.length>0)" key="3">
                    -->
                    <v-tab-item v-if="(isAdminUser() || inSeasonDevStreams.length>0)" key="3">
                        <v-row class="no-boundaries">
                            <v-col 
                                v-for="devStreamId in inSeasonDevStreams"
                                :key="devStreamId"
                                xl="3"
                                lg="4"
                                md="4"
                                sm="6"
                                xs="12"
                            >
                                <v-hover v-slot="{hover}">
                                    <v-card :elevation="hover?12:0" :class="{'hover-card':true,'on-hover':hover}" rounded max-width="200px">
                                        <v-img
                                            :src="sports[tryouts[devStreamId].sportId].cardImage" 
                                            class="white--text align-end"
                                            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                                            max-height="150px"
                                            @click="devStreamHomeNav(devStreamId)"
                                        >
                                            <v-card-title class="card-title">{{ tryouts[devStreamId].title }}</v-card-title>
                                        </v-img>
                                        <v-card-actions>
                                            <v-row>
                                                <v-col cols="12">
                                                    <div v-if="tryouts[devStreamId].headcoach" class="role-pill headcoach">Head Coach</div>
                                                    <div v-if="tryouts[devStreamId].admin" class="role-pill admin">Admin</div>
                                                    <div v-if="tryouts[devStreamId].owner" class="role-pill owner">Club Manager</div>
                                                    <div v-if="tryouts[devStreamId].assistantcoach" class="role-pill assistantcoach">Assistant Coach</div>
                                                    <div v-if="tryouts[devStreamId].selector" class="role-pill selector">Selector</div>
                                                    <div v-if="tryouts[devStreamId].support" class="role-pill support">Support Staff</div>
                                                    <div v-if="tryouts[devStreamId].athlete" class="role-pill athlete">Athlete</div>

                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12">
                                                    <v-spacer/>
                                                    <v-tooltip v-if="clubs[selectedClub].owner" bottom>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                icon
                                                            >
                                                                <v-icon @click="deleteDevStreamId=devStreamIdId;deleteDevStreamDialog=true">mdi-trash-can</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span>Delete Development Program</span>
                                                    </v-tooltip>

                                                </v-col>
                                            </v-row>

                                        </v-card-actions>
                                    </v-card>
                                </v-hover>
                            </v-col>
                            <v-col
                                xl="3"
                                lg="4"
                                md="4"
                                sm="6"
                                xs="12"
                                v-if="clubOwnerOrAdmin"
                            >
                                <v-hover v-slot="{hover}">
                                    <v-card :elevation="hover?12:0" :class="{'on-hover':hover}" rounded max-width="200px">
                                        <v-img 
                                            src="https://s3.ap-southeast-2.amazonaws.com/www.coachsight.net/app_assets/Multi-sport-logo-add-new-team.gif"
                                            class="white--text align-end"
                                            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                                            max-height="150px"
                                            contain
                                            @click="showAddDevStreamDialog"
                                        >
                                        </v-img>
                                        <v-card-actions>
                                            <v-spacer/>
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    icon
                                                    >
                                                        <v-icon @click="showAddDevStreamDialog">mdi-plus</v-icon>

                                                    </v-btn>
                                                </template>
                                                <span>New Development Program</span>
                                            </v-tooltip>
                                        </v-card-actions>
                                    </v-card>
                                </v-hover>
                            </v-col>
                            
                        </v-row>
                    </v-tab-item>
                    <v-tab-item key="4" v-if="isAdminUser()">
                        <club-season-pane/>
                    </v-tab-item>
                </v-tabs-items>

            </v-col>
            <v-col v-else >
                Not in any tryouts or teams...
            </v-col>
        </v-row>
    </div>
    
    
</template>
    
<script>
    import {mapState, mapActions} from 'vuex'
    import {licenseAllows} from '@/_helpers/license'

    import ClubSeasonPane from '@/components/ClubSeasonPane'
    import Loading from '@/components/loading' 
    import {rules} from '@/_helpers/rules'
    import DateCombo from '@/components/DateCombo'
    import { ValidationObserver, ValidationProvider } from 'vee-validate'
    import TeamAddEdit from '@/ClubPanes/TeamAddEdit'
    import DevStreamAddEdit from '@/ClubPanes/DevelopmentStreams/DevStreamAddEdit'
    import PermanentConfirm from './PermanentConfirm.vue'


    export default {
        //eslint-disable-next-line
        components: { Loading, DateCombo, ValidationObserver, ValidationProvider, DevStreamAddEdit, TeamAddEdit, ClubSeasonPane, PermanentConfirm},
        computed: {
            ...mapState({
                menuState: state => state.navigatorStore.showBurgerMenu,
                tryouts: state => state.tryoutStore.data,
                tryoutList: state => state.tryoutStore.list,
                tryoutStatus: state => state.tryoutStore.status,
                selectedSeason: state => state.navigatorStore.selectedSeason,
                selectedClub: state => state.navigatorStore.selectedClub,
                clubs: state => state.clubStore.data,
                account: state => state.account.user.details,
                seasons: state => state.seasonStore.data,

                teams: state => state.teamStore.data,
                teamList: state => state.teamStore.list,
                teamStatus: state => state.teamStore.status,


                sports:         state => state.sportStore.data,
                sportsList:     state => state.sportStore.list,
                sportsStatus:   state => state.sportStore.status,
            }),
            loading: function() {
                return this.tryoutStatus.loading 
                || !this.stable
                || this.teamStatus.loading
                || this.sportsStatus.loading
            },
            sportsArray: function(){
                var sportsObjs=[]
                for(var idx in this.sportsList){
                    sportsObjs.push({
                        text: this.sports[this.sportsList[idx]].name,
                        value: this.sportsList[idx]
                    })

                }
                return sportsObjs
            },
            inSeasonDevStreams(){
                return this.tryoutList.filter(item=>{return this.tryouts[item].eventType==='devprogram' && this.tryouts[item].seasonId === this.selectedSeason && (
                    this.tryouts[item].admin || 
                    this.tryouts[item].support || 
                    this.tryouts[item].selector || 
                    this.tryouts[item].headcoach || 
                    this.tryouts[item].assistantcoach || 
                    this.tryouts[item].owner || 
                    this.tryouts[item].athlete 
                )})
            },
            inSeasonTryouts(){
                return this.tryoutList.filter(item=>{return this.tryouts[item].eventType==='tryout' && this.tryouts[item].seasonId === this.selectedSeason && (
                    this.tryouts[item].admin || 
                    this.tryouts[item].support || 
                    this.tryouts[item].selector || 
                    this.tryouts[item].headcoach || 
                    this.tryouts[item].assistantcoach || 
                    this.tryouts[item].owner || 
                    this.tryouts[item].athlete 
                )})
            },
            inSeasonTeams(){
                if(this.clubs[this.selectedClub].owner)
                    return this.teamList


                // Now is this user a staff member or athlete in a team?

                return this.teamList.filter(item=>{return this.teams[item].seasonId === this.selectedSeason && (
                    this.teams[item].admin ||
                    this.teams[item].support ||
                    this.teams[item].headcoach ||
                    this.teams[item].assistantcoach ||
                    this.teams[item].athlete
                )})
            },
            clubOwnerOrAdmin(){
                return this.clubs[this.selectedClub].owner || this.clubs[this.selectedClub].admin
            }
        },
        methods: {
            licenseAllows,
            ...mapActions('tryoutStore', [
                'loadAllTryoutsForSeason',
                'addTryout',
                'deleteTryout'
                /*
                findAllTryoutsForSeason: 'findAllForSeason',
                addTryout: 'addTryout',
                deleteTryout: 'deleteTryout'
                */
            ]),
            ...mapActions('navigatorStore', {
                selectTryout: 'selectTryout',
                selectPane: 'selectPane',
                selectNav: 'selectNav',
            }),
            ...mapActions('teamStore', {
                findAllTeamsForSeason: 'findAllForSeason',
                createTeam: 'create', 
                deleteTeam: 'del'
            }),
            ...mapActions('sportStore',{
                findAllSports: 'findAll',
            }),
            editTeam(teamId){
                this.selectedTeam=teamId
                this.editTeamDialog=true
            },
            devStreamHomeNav(id){
                this.selectNav('DevStream')
                this.selectPane('DevStreamHome')
                this.selectTryout(id) // This will select a "Team" into the "Tryout"
            },
            teamHomeNav(id){
                this.selectNav('Team')
                this.selectPane('TeamHome')
                this.selectTryout(id) // This will select a "Team" into the "Tryout"
            },
            closeDialog(){
                this.resetTryout()
                this.dialog=false
                this.$refs.form.reset()
            },
            registrationLink(id) {
                return '/registerfortryout/' + id
            },
            tryoutReg(id){
                this.selectTryout(id)
                this.selectPane('TryoutRegistration')
            },
            reviewReg(id){
                this.selectTryout(id)
                this.selectPane('TryoutStatus')
                this.selectNav('Tryout')

            },
            homeTryout(id){
                this.selectTryout(id)
                if(this.tryouts[id].admin || this.tryouts[id].owner){
                    this.selectPane('TryoutStatus')
                    this.selectNav('Tryout')
                } else {
                    this.reviewReg(id)
                }
            },
            manageTryout(id){
                this.selectTryout(id)
                this.selectPane('TryoutSettings')
                this.selectNav('Tryout')
            },
            completeReg(id){
                this.selectTryout(id)
                this.selectPane('TryoutRegistration')
            },

            collectData(id){
                this.selectTryout(id)
                this.selectPane('CollectData')
            },
            isAdminUser() {
               return (this.clubs[this.selectedClub].ownerId === this.account.id ) || this.clubs[this.selectedClub].admin
            },
            showAddDialog(){
                this.resetTryout()
                this.dialog=true
            },
            showAddTeamDialog(){
                this.resetTeam()
                this.selectedTeam=null
                this.editTeamDialog=true
            },
            showAddDevStreamDialog(){
                this.resetDevStream()
                this.selectedDevStream=null
                this.editDevStreamDialog=true
            },
            resetTryout(){
                const startDate = new Date()
                const endDate = new Date(); endDate.setDate(startDate.getDate()+7)
                this.newTryout = {
                    title: '',
                    description: '',
                    startDate: startDate.toISOString(),
                    endDate:   endDate.toISOString(),
                    offerDeadline:  endDate.toISOString(), 
                    sportId: this.clubs[this.selectedClub].sportId,
                    location: '',
                    clubId: '',
                    seasonId: '',
                }
            },
            resetDevStream(){
                this.newDevStreamName=null
                this.newDevStreamDescription=null
            },
            resetTeam(){
                this.newTeamName=null
                this.newTeamGender = 'Unspecified'
                this.newTeamDescription=null
            },
            async save() {
                this.submitted=true
                this.newTryout.location="Melbourne, AU"
                this.newTryout.clubId = this.selectedClub
                this.newTryout.seasonId = this.selectedSeason
                this.newTryout.offerDeadline = this.newTryout.endDate
                this.newTryout.aStartDate=this.newTryout.startDate
                await this.addTryout(this.newTryout)


                this.selectTryout(this.tryoutStatus.addedId)
                this.manageTryout(this.tryoutStatus.addedId)
                
                this.newTryout = {
                    title: '',
                    description: '',
                    startDate: (new Date(Date.now() - (new Date()).getTimezoneOffset()*60000)).toISO,
                    endDate:   (new Date(Date.now() - (new Date()).getTimezoneOffset()*60000)).toISO,
                    offerDeadline:   (new Date(Date.now() - (new Date()).getTimezoneOffset()*60000)).toISO,
                        
                    sport: this.clubs[this.selectedClub].sportId, // 'nosport0-0000-0000-0000-000000000000',
                    location: '',
                    clubId: '',
                    seasonId: '',
                }
                this.$refs.form.reset()
            },
            async delTryout(){
                try{
                    await this.deleteTryout({eventId: this.deleteTryoutId})
                    this.deleteDialog=false
                    this.deleteText=""

                } catch(error){
                    this.$toast.error('Your delete request could not be processed. Please try again soon.')
                    this.deleteDialog=false
                    this.deleteText=""
                }
            },
            async delTeam(){
                try {
                    this.deleteTeam({teamId: this.deleteTeamId, clubId: this.selectedClub})
                    this.deleteTeamDialog=false
                    this.deleteText=""
                } catch (error) {
                    this.$toast.error('Your delete request could not be processed. Please try again soon.')
                    this.deleteTeamDialog=false
                    this.deleteText=""
                }

            },
            closeDeleteDialog(){
                this.deleteDialog=false
                this.deleteText=""
            },
            closeDeleteTeamDialog(){
                this.deleteTeamDialog=false
                this.deleteText=""
            },
            teamLogo(teamId){
                if(this.teams[teamId].logo!==null) return this.teams[teamId].logo
                if(this.teams[teamId].gender==="Female") return "https://s3.ap-southeast-2.amazonaws.com/www.coachsight.net/app_assets/Team-Female.jpg"
                if(this.teams[teamId].gender==="Male") return "https://s3.ap-southeast-2.amazonaws.com/www.coachsight.net/app_assets/Team-Male.jpg"
                if(this.teams[teamId].gender==="Mixed") return "https://s3.ap-southeast-2.amazonaws.com/www.coachsight.net/app_assets/Team-Mixed.jpg"
                return "https://s3.ap-southeast-2.amazonaws.com/www.coachsight.net/app_assets/Team-Unspecified.jpg"
            }

        },
        watch: {
        },  
        mounted() {
            this.$root.$on('close-team-editor', () => {
                this.editTeamDialog=false
            })
            this.$root.$on('close-devstream-editor', () => {
                this.editDevStreamDialog=false
            })
        },
        data() {
            return {
                loadOnce: 0,
                mySports: null,
                deleteTryoutId:'',
                deleteText: null,
                deleteDialog: false,
                stable: false,
                tab: null,
                dialog: false,
                startDateMenu: false,
                endDateMenu: false,
                rules: rules,
                selectedSport:[],
                otherSport:'',
                newTryout: {},
                editTeamDialog: false,
                deleteTeamDialog: false,
                deleteDevStreamId: null,
                deleteDevStreamDialog: false,
                editDevStreamDialog: false,
                deleteTeamId: null,
                addTeamDialog: false,
                addDevStreamDialog: false,
                selectedTeam:null,
                selectedDevStream: null,
                newTeam: {},
                newTeamName: null,
                newTeamGender: 'Unspecified',
                newTeamDescription: null,
            }
        },
        async created() {
            this.stable=false

            //await this.findAllTryoutsForSeason(this.selectedSeason)

            if(this.loadOnce===0){
                this.loadOnce++
                /*()
                if(this.selectedSeason && this.tryoutList.length === 0) {
                    await this.findAllTryoutsForSeason(this.selectedSeason)
                }
                if(this.selectedSeason && this.teamList.length === 0) {
                    await this.findAllTeamsForSeason({seasonId: this.selectedSeason})
                }
                    */
            }
            this.stable=true
            this.newTryout= {
                title: '',
                description: '',
                startDate: (new Date(Date.now() - (new Date()).getTimezoneOffset()*60000)).toISO,
                endDate:   (new Date(Date.now() - (new Date()).getTimezoneOffset()*60000)).toISO,
                offerDeadline:   (new Date(Date.now() - (new Date()).getTimezoneOffset()*60000)).toISO,
                
                location: '',
                clubId: '',
                seasonId: '',
                sportId: this.clubs[this.selectedClub].sportId
            }
            this.newTeam={
                name: '',
                competition: '',
                description: '',
                seasonId: this.selectedSeason,
            }
//            await this.findAllSports()
        }


    
    
    }
    
</script>

<style scoped>

.pane-heading {
    text-align: left;
}
.card-title{
    font-size:medium;
    font-weight:600;
    font-family: 'Varela Round';
    text-shadow: 1px 1px 2px red, 0 0 1em blue, 0 0 0.2em blue;

}
.hover-card {
  transition: opacity .4s ease-in-out;
}

.hover-card:not(.on-hover) {
  opacity: 0.6;
}
.vv-card {
  transition: opacity .4s ease-in-out;
}

.vv-card:not(.on-hover) {
  opacity: 0.6;
 }

.show-btns {
  color: rgba(255, 255, 255, 1) !important;
}

.role-pill{
    padding: 1px 5px 1px 5px;
    margin: 2px;
    border-radius:5px;
    color:white;
    font-size:xx-small;
    font-weight:600;
}

.role-pill.headcoach{
    background-color: blue;
}

.role-pill.admin{
    background-color: grey;
}

.role-pill.owner{
    background-color: black;
}

.role-pill.assistantcoach{
    background-color: brown;
}

.role-pill.support{
    background-color: orchid;
}

.role-pill.selector{
    background-color: teal;
}

.role-pill.athlete{
    background-color: olive;
}
</style>