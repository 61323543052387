<template>
    <loading v-if='loading'/>
    <div v-else>
        <h3>Your Episode List</h3>
        <v-list v-if="episodeList.length>0">
            
            <v-simple-table dense>
                <template v-slot:default>
                    <thead>
                        <tr>
                            <td class="text-left tablehead">Published</td>
                            <td class="text-left tablehead">Date</td>
                            <td class="text-left tablehead">{{ capitalize($pluralize(events[selectedEvent].participantPronoun)) }}</td>
                            <td v-for="n in events[selectedEvent].numSummaryQuestions" :key="n" class="text-left tablehead">
                                {{ questions[questionList['devform'][n-1]].question }}
                            </td>
                            <td class="text-left tablehead">Actions</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="episode in episodes" :key="'a'+episode.id" @click="viewEpisode(episode.id)">
                            <td class="text-left">
                                <div v-if="episode.published" class="role-pill " style="text-align:center;width:60px;padding-right:20px;background-color: green;margin-top:auto;margin-bottom:auto">Published</div>
                                <div v-else class="role-pill" style="text-align:center;width:60px;padding-right: 20px; background-color: purple; margin-top: auto; margin-bottom: auto;">Not Published</div>
                            </td>
                            <td class="text-left">{{new Date(episode.date).toLocaleString()}}</td>
                            <td>
                                <div v-for="participant in episode.participantList" :key="participant.id" style="text-align:left;font-size:x-small">{{ participantName(participant.participantId)}}</div>
                            </td>

                            <td v-for="n in events[selectedEvent].numSummaryQuestions" :key="n" class="text-left">
                                {{ renderResponse(episode.id, questionList['devform'][n-1])}}
                            </td>
                            <td v-if="false"><div class="skill-pill" style="background-color: red">Delete</div></td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-list>
        <div v-else style="margin-top:20px;">
            There are no episodes available at this time
        </div>


    </div>
</template>

<script>
import Loading from '@/components/loading'

import {mapState, mapActions} from 'vuex' 

export default{
    props: [],
    components:{
        Loading
    },
    computed: {
        ...mapState({
            episodes:               state => state.episodeStore.data,
            episodeList:            state => state.episodeStore.list, 
            questions:              state => state.appquestionStore.data,
            questionList:          state => state.appquestionStore.listByContext,
            responses:              state => state.qresponseStore.individual,
            events:                 state => state.tryoutStore.data,
            account:                state => state.account.user.details,
            participants:           state => state.participantStore.data,

            selectedEvent:          state => state.navigatorStore.selectedTryout,
        }),
        loading(){
            return !this.dataReady||
                false;
        }

    },
    methods: {
        ...mapActions('episodeStore', [
            'loadAllEpisodesForUserAndEvent',
            'loadAllEpisodesForEvent'
        ]),
        ...mapActions('navigatorStore', [
            'setSelectedEpisode',
            'selectClubPane',
        ]),
        ...mapActions('appquestionStore', [
            'loadAllQuestionsForEvent',
        ]),
        ...mapActions('qresponseStore',[
            'loadAllQResponsesForEvent',
            'loadQResponses',
        ]),
        ...mapActions('participantStore', [
            'loadAllParticipantsForEvent'
        ]),
        viewEpisode(episodeId){
            this.setSelectedEpisode(episodeId)
            this.selectClubPane('DevStreamEpisode')
        },
        renderResponse(episodeId, responseId){

            if(this.responses[episodeId][episodeId][responseId]===null) return '---'
            const response = this.responses[episodeId][episodeId][responseId].response 
            if(response === null || !responseId) return '---'

            const type = this.questions[this.responses[episodeId][episodeId][responseId].questionId].type
            var options=[]

            switch(type){
                case 'short': 
                    return response
                case 'select': 
                    options = JSON.parse(this.questions[this.responses[episodeId][episodeId][responseId].questionId].options)
                    return options[response].text
                case 'checkbox':
                    return response?"Yes":"No"
            } 

        },


        participantName(participantId){
           return `${this.participants[participantId].firstName} ${this.participants[participantId].lastName}`
        },

    },
    
    async created(){
        this.dataReady=false
        await this.loadAllEpisodesForUserAndEvent({eventId: this.selectedEvent, userId: this.account.id})
        //await this.loadAllEpisodesForEvent({eventId: this.selectedEvent})
        await this.loadAllQuestionsForEvent({eventId: this.selectedEvent})
        await this.loadAllParticipantsForEvent({eventId: this.selectedEvent})

        const data=[]

        if(this.episodeList.length>0){
            for(var i=0;i<this.episodeList.length;i++){
                const node1 = {
                    eventId: this.episodeList[i],
                    participantId: this.episodeList[i],
                    questionId: this.questionList['devform'][0]
                }
                const node2 = {
                    eventId: this.episodeList[i],
                    participantId: this.episodeList[i],
                    questionId: this.questionList['devform'][1]
                }
                const node3 = {
                    eventId: this.episodeList[i],
                    participantId: this.episodeList[i],
                    questionId: this.questionList['devform'][2]
                }
                const node4 = {
                    eventId: this.episodeList[i],
                    participantId: this.episodeList[i],
                    questionId: this.questionList['devform'][3]
                }
                data.push(node1)
                data.push(node2)
                data.push(node3)
                data.push(node4)
            }
            await this.loadQResponses({eventId: this.selectedEvent, data: JSON.stringify(data)})
        }
        this.dataReady=true
    },
    data(){
        return {
            dataReady:false

        }
    }

}
</script>

<style scoped>
.tablehead{
    font-weight: 600;
    font-size: small;
}

</style>
