<template>
    <div >
        <h1>Your registration was successful!</h1> 
        <div>
            Your participant number is: 
        </div>
        <div class="athlete-id"> 
            {{participantNumber}}
        </div>
        <div>
            <p>
            <!--
            <em>Tryouts start {{whenTryoutsStart}} !</em>
            -->
            </p>
            Please check your email for further details about the tryout sesssions. 
            <p>
            <br/>
            <!--
            You will need to register your attendance at each session using the QR code at the venue, and make sure your player number is clearly visible on your left leg.
            -->
            </p>
            <br/>
            <p>
            <br/>
            We are looking forward to seeing you at the tryouts! If you have any further questions, contact Andy Davison on 0408 255 761
            </p>
        </div>
    </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import moment from 'moment'

export default {
    components: {
    },
    computed: {
        ...mapState({
        }),
        whenTryoutsStart : {
            get() {
                return moment(this.startDate).fromNow()
            }
        },
        participantNumber : {
            get() {
                return this.$route.query.participantNumber
            }
        },
        startDate: {
            get() {
                return this.$route.query.startDate
            }
        }
    },
    methods: {
        ...mapActions({

        }),

    },
    created() {
    }
}
</script>

<style>
.athlete-id {
    display: flex;
    justify-content: center;
    border-style: solid;
    width: 20%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: 30px;
    font-size:64px;
    border-radius: 36px;
    background: blue;
    color: white;
    font-weight: 900;
    padding: 40px;
}
</style>