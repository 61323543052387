<template>
    <v-expansion-panel>
        <v-expansion-panel-header disable-icon-rotate>
            <div :class="{strike: sessionMeta[sessionId].delete}">
                {{sessions[sessionId].title}} 
            </div>
            <template v-slot:actions>
                <v-btn @click.native.stop
                    v-if="false &&changes"
                    x-small
                    v-on:click="saveDetails"
                    depressed
                    color="primary"
                    class="save-btn"
                >
                    Save
                </v-btn>
                <v-dialog v-model="confirmSessionDeleteDialog" max-width="60%">
                    <template v-slot:activator="{on, attrs}">
                        <v-icon small color="red" v-on="on" v-bind="attrs">mdi-delete</v-icon>
                    </template>
                    <v-card>
                        <v-card-title>Confirm Session Deletion</v-card-title>
                        <v-card-text>
                            You cannot undo this operation. All information for this session will be deleted permanently. Are you sure you want to continue?
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer/>
                            <v-btn text @click="confirmActivityDeleteDialog=false">Close</v-btn>
                            <v-btn color="red darken-1" text @click="confirmedDeleteSession">Delete</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <!--
                <v-icon small v-if="flagToDelete" @click.stop="unflagForDeletion">mdi-delete-restore</v-icon>
                <v-icon small v-else color="red" @click.stop="flagForDeletion">mdi-delete</v-icon>
                -->

                <v-icon>mdi-chevron-down </v-icon>
            </template>

        </v-expansion-panel-header>
        <v-expansion-panel-content>
            <v-row>
                <v-col cols="12" sm="12" md="6" lg="6" xl="6" style="margin-top:0px">
                    <v-row>
                        <v-text-field
                            v-model="sessionTitle"
                            prepend-icon="mdi-label"
                            label="Session Name"
                            />
                    </v-row>
                    <v-row style="padding-left:0px">
                        <v-col cols="12" style="padding-left:0px">
                            <div class="date-times">
                                <div class="date">

                                    <v-menu
                                        v-model="showDate"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="auto"
                                        class="date-menu"
                                    >
                                        <template v-slot:activator="{ on,attrs }">
                                            <v-text-field
                                                v-model="date"
                                                label="Session Date"
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on"
                                                :rules="[rules.required]"
                                            />
                                        </template>
                                        <v-date-picker
                                            v-model="date"
                                            @input="showDate=false"
                                        />
                                    </v-menu> 
                                </div>
                            </div>

                        </v-col>
                        <v-col cols="12" sm="12" md="6" lg="6" xl="6" style="padding-left:0px; margin-top:0;display:flex;flex-direction:row">
                            <v-icon>mdi-clock</v-icon>
                            <div class="time" style="margin-top:0 ;padding-left:5px">
                                <div class="v-label v-label--active theme--light" style="font-size:small">Start Time:</div>
                                <VueTimepicker prepend="mdi-clock" drop-direction="up" :minute-interval="15" v-model="startTime"/>
                            </div>
                        </v-col>
                        <v-col cols="12" sm="12" md="6" lg="6" xl="6" style="padding-left:0px;margin-top:0;display:flex;flex-direction:row;">
                            <v-icon v-if="$vuetify.breakpoint.smAndDown">mdi-clock</v-icon>
                            <div class="time" style="margin-top:0 ;padding-left:5px">
                                <div class="v-label v-label--active theme--light" style="font-size:small">End Time:</div>
                                <VueTimepicker drop-direction="up" :minute-interval="15" v-model="endTime"></VueTimepicker>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-text-field
                            v-model="sessionVenue"
                            prepend-icon="mdi-map-marker"
                            label="Venue"
                        />
                    </v-row>
                    <v-row>
                        <v-col cols="12"  sm="12" md="12" lg="12" xl="12" style="margin-top:0px">
                                <div class="qrheading">Link for check-in</div>
                                <div class="qrdesc">This link can be sent to athletes to checkin to the session.</div>
                                <div class="qrlink">
                                    <div style="word-break:break-all;" class="no-boundaries">
                                        {{ signinLink }} 
                                    </div>
                                    <div class="no-boundaries text-right">
                                        <v-tooltip top >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon v-on=on v-bind=attrs style="margin-left:20px;" @click="copyToClipboard">mdi-content-copy</v-icon>
                                            </template>
                                            <span>Copy to clipboard</span>
                                        </v-tooltip>
                                    </div>
                                </div>
            
                        </v-col>
                    </v-row>
                </v-col>
                <v-col>
                    <div>
                        <div class="qrheading">QRCode for Tryout Session check-in</div>
                        <div class="qrdesc">This code can be used to register an athlete's presence at this session.</div>
                        <qr-code
                            :width="codeSize.large"
                            :height="codeSize.large"
                            :value="signinLink"
                            :qrOptions="{ typeNumber: 0, mode: 'Byte', errorCorrectionLevel: 'H' }"
                            :imageOptions="{ hideBackgroundDots: true, imageSize: 0.4, margin: 0}"
                            :dotsOptions="{
                                type: 'extra-rounded',
                                color: '#26249a',
                                gradient: {
                                    type: 'linear',
                                    rotation: 0,
                                    colorStops: [
                                        { offset: 0, color: '#9245B7' },
                                        { offset: 1, color: '#032681' },
                                    ],
                                },
                            }"
                            :backgroundOptions="{ color: '#ffffff' }"
                            :cornersSquareOptions="{ 
                                type: 'extra-rounded', 
                                color: '#000000' ,
                                gradient: {
                                    type: 'linear',
                                    rotation: 0,
                                    colorStops: [
                                        { offset: 0, color:'#000000'},
                                        { offset: 1, color: '#2197F3'}
                                    ]

                                }
                            }"
                            :cornersDotOptions="{ type: 'dot', color: '#000000' }"
                            fileExt="png"
                            :download="true"
                            /> 
                        <div>
                            <v-btn color="primary" @click="showPoster()" text>Printable Sign</v-btn>
                        </div>

                    </div>
                </v-col>
            </v-row>
            <v-row>
                <v-col style="margin:0px;padding:0px;">
                    <div class="qrheading">Criteria relevant to this session</div>
                    <span>Only the checked criteria will be visible if the session is selected by your selection staff. If no criteria are selected, all criteria will be displayed for this session.</span>
                </v-col>
            </v-row>
            <v-row>
                <v-col v-for="criterionId in criterionList" :key="criterionId" style="margin-top:0px;margin-bottom:auto;margin-left:auto;margin-right:auto;width:100%;">
                    <!-- 
                    <v-checkbox dense v-on:click="blur" v-model="sessionCriteria[criterionId]" :label="criteria[criterionId].description"> 
                    -->
                    <v-checkbox dense v-on:click="blur" v-model="sessionCriteria[criterionId]" style="margin-bottom:0px;" hide-details> 
                        <!-- 
                        <template v-slot:label="{item}">
                            <br/>
                            A{{ item }}
                        </template>
                    -->
                    </v-checkbox>
                    <div style="font-size:small;margin-top:0px;">
                        {{ criteria[criterionId].description }}
                    </div>
                </v-col>
            </v-row>

            <v-row>
                <div class="qrheading">Athlete instructions for this Tryout Session</div>
                <v-expansion-panels>
                <v-expansion-panel>
                    <v-expansion-panel-header style="font-size:small">
                        <b>Editor Instructions</b>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="text-left;;">
                        <v-row style="font-size:small">
                            You can define the instructions the athletes will see for this session here. There are special values you can insert in your message:<br/>
                        </v-row>
                        <v-row style="font-size:small">
                            <v-col>
                                <ul>
                                    <li ><b>$fname </b>: The Athlete's first name</li>
                                    <li ><b>$lname</b>: The Athletes last name</li>
                                    <li ><b>$clubname</b>: The name of your club</li>
                                    <li ><b>$clublogo</b>: Your club logo (if it has been uploaded)</li>
                                    <li><b>$number</b>: The Athlete's number</li>
                                    <li><b>$qrcode</b>: A code that can be used to register gear pickup or other activities</li>
                                </ul>

                            </v-col>
                            <v-col>
                                The following values can also he inserted if they have been defined for the tryout in "Settings"<br/>
                                <ul>
                                    <li><b>$cname</b>: The name of the tryout contact person</li>
                                    <li><b>$cphone</b>: The phone number for the tryout contact person</li>
                                    <li><b>$cemail</b>: The email for the tryout contact person</li>
                                </ul>
                        

                            </v-col>

                        </v-row>
                        

                    </v-expansion-panel-content>
                </v-expansion-panel>

            </v-expansion-panels>
            </v-row>
            <v-row>
                <v-col style="margin:0px;padding:0px;">
                    <vue-editor 
                        v-model="sessionInstructions" 
                        :editorOptions="editorSettings" 
                        width="100%"
                        @blur="onEditorBlur"
                    />   
                </v-col>
            </v-row>
            <v-row>
                <div class="qrheading">Session Reminder Email</div>
                <v-expansion-panels>
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            Editor Instructions
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row style="font-size:small">
                                You can define the instructions the athletes will see for this session here. There are special values you can insert in your message:<br/>
                            </v-row>
                            <v-row style="font-size:small">
                                <v-col>
                                    <ul>
                                        <li ><b>$fname </b>: The Athlete's first name</li>
                                        <li ><b>$lname</b>: The Athletes last name</li>
                                        <li ><b>$clubname</b>: The name of your club</li>
                                        <li ><b>$clublogo</b>: Your club logo (if it has been uploaded)</li>
                                    </ul>

                                </v-col>
                                <v-col>
                                    The following values can also he inserted if they have been defined for the tryout in "Settings"<br/>
                                    <ul>
                                        <li><b>$cname</b>: The name of the tryout contact person</li>
                                        <li><b>$cphone</b>: The phone number for the tryout contact person</li>
                                        <li><b>$cemail</b>: The email for the tryout contact person</li>
                                    </ul>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-row>
            <v-row>
                <v-col style="margin:0px;padding:0px;">
                    <vue-editor 
                        v-model="sessionReminder" 
                        :editorOptions="editorSettings" 
                        width="100%"
                        @blur="onEditorBlur"
                    />   
                </v-col>
            </v-row>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import moment from "moment"

import {rules} from '@/_helpers/rules'

import {VueEditor} from 'vue2-editor'
import StylesQRCode from '@/components/StyledQRCode'

import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'
import {v4 as uuidv4} from 'uuid'


export default  {
    name: "session-editor",
    props: ['sessionId'],
    components: {
//eslint-disable-next-line
        VueEditor,
        'qr-code': StylesQRCode,
        VueTimepicker,
    },
    computed: {
        ...mapState({
            criteria: state => state.criterionStore.data,
            criterionList: state => state.criterionStore.list,
            criterionMeta: state => state.criterionStore.meta,
            criterionSessions: state => state.criterionStore.sessions,

            status: state => state.sessionStore.status,
            sessions: state => state.sessionStore.data,
            sessionMeta: state => state.sessionStore.meta,

            selectedTryout: state => state.navigatorStore.selectedTryout,

        }),
        loading: function() {
            return this.status.loading
        },
        sessionTitle: {
            get() {
                    return this.sessions[this.sessionId].title
            },
            set(value) {
                this.flagChanges()
                this.updateSessionProp({field: 'title', id: this.sessionId, value})
            }
        },
        sessionVenue: {
            get() {
                return this.sessions[this.sessionId].venue
            },
            set(value) {
                this.flagChanges()
                this.updateSessionProp({field: 'venue', id: this.sessionId, value})
            }
        },
        sessionInstructions: {
            get() {
                return this.sessions[this.sessionId].participantNote
            },
            set(value) {
                this.flagChanges()
                this.updateSessionProp({field: 'participantNote', id: this.sessionId, value: value})
            }
        },
        sessionReminder: {
            get() {
                return this.sessions[this.sessionId].sessionReminder
            },
            set(value) {
                this.flagChanges()
                this.updateSessionProp({field: 'reminderEmail', id: this.sessionId, value: value})
            }
        },
        signinLink(){
            return process.env.VUE_APP_VUE_LINK_PREFIX + "/signinforsession/"+this.sessionId+"/"+this.selectedTryout
        },
        delete: {
            get() {
                return this.sessionMeta[this.sessionId].delete
            },
            set(value) {
                this.flagChanges()
                this.updateSessionProp({field: 'delete', id: this.sessionId, value})
            }
        },
        date: {
            get() {
                return this.sessionMeta[this.sessionId].date
            },
            set(value) {
                this.flagChanges()
                this.updateSessionProp({field: 'date', id: this.sessionId, value})
            }
        },
        startTime: {
            get() {

                return this.formatTime(this.sessionMeta[this.sessionId].startTime)
            },
            set(value) {
                this.flagChanges()
                return this.updateSessionProp({field: 'startTime', id: this.sessionId, value})
            }
        },
        endTime: {
            get() {
                return this.formatTime(this.sessionMeta[this.sessionId].endTime)
            },
            set(value) {
                this.flagChanges()
                return this.updateSessionProp({field: 'endTime', id: this.sessionId, value })
            }
        },
        flagToDelete: {
            get() {
                return this.sessionMeta[this.sessionId].delete
            },
            set(value) {
                this.$root.$emit("settings-changes-made", this.componentId)
                return this.update({field: 'delete', id: this.sessionId, value})
            }
        },

    },
    methods: {

        ...mapActions('sessionStore', { 
            updateTitle: 'updateTitle',
            updateSessionProp: 'update',
            updateSession: 'updateSession',
            deleteSession : 'deleteSession',
        }),
        blur(){
        },
        showPoster(){
            window.open(`/sessionPoster/${this.sessionId}/${this.selectedTryout}`, '_blank')
        },

        async copyToClipboard(){
            await navigator.clipboard.writeText(this.signinLink);
        },
        flagChanges(){
            this.changes=true
            //this.$root.$emit("settings-sessions-changes-made", this.componentId)
            this.$root.$emit("settings-changes-made", this.componentId)
        },
        async saveDetails(){
            this.changes=false

            // INcorporate the sessionCriteria flags into the session data

            this.sessions[this.sessionId].criteria=[]
            for(var idx in this.criterionList){
                const criterionId = this.criterionList[idx]
                if(this.sessionCriteria[criterionId]){
                    this.sessions[this.sessionId].criteria.push(criterionId)
                }
            }

            this.updateSession(this.sessions[this.sessionId])
            this.$root.$emit("settings-sessions-changes-cleared", this.componentId)
            this.$store.dispatch('alert/success', 'Tryout Updated', {root:true})
        },

        confirmedDeleteSession(){
            this.deleteSession(this.sessionId)
            this.confirmSessionDeleteDialog=false
            this.$store.dispatch('alert/success', 'Session deleted', {root:true})

        },

        formatTime: function(t) {
            return t
        },
        allowedStep: m => m % 15 === 0,
        formatDate: function(d) {
            return d
                ? moment(d).format("DD/MM/YYYY")
                : "";
        },
        flagForDeletion: function() {
            this.delete=true
        },
        unflagForDeletion: function() {
            this.delete=false
        },
        formatDateTime: function(d) {
            return d
                ? moment(d).format('llll')
                : ""
        },
        saveStartTime: function(value) {
            return this.updateSessionProp({field: 'startTime', id: this.sessionId, value})
        },
        saveEndTime: function(value) {
            return this.updateSessionProp({field: 'endDateTime', id: this.sessionId, value})
        },
        //eslint-disable-next-line
        onEditorBlur(quill) {
            return this.updateSessionProp({field: 'participantNote', id: this.sessionId, value: this.sessionInstructions})
        },
        /*
        onEditorFocus(quill) {
            
        },

        onSelectionChange(range) {
        }
        */

    },
    created: function() {
        this.componentId=uuidv4()

        // Create the table of sessions and criteria
        for(var idx in this.criterionList){
            if(this.sessions[this.sessionId].criteria.includes(this.criterionList[idx])){
                this.sessionCriteria[this.criterionList[idx]]=true
            } else {
                this.sessionCriteria[this.criterionList[idx]]=false
            }
        }

    },
    mounted(){
        this.$root.$on('settings-sessions-clear-changes-flags', ()=> {
            var self=this
            self.changes=false
        })
        this.$root.$on('submit-all-changes', () => {
            var self=this
            if(this.changes){
                this.saveDetails()
                self.changes=false
            }
        })
    },
    /*
    watch: {
        sessionCriteria: {
            handler(newValue, oldValue){
                console.info('sessionCriteria changed')
            },
            immediate: true,
            deep: true,
        }
    },
    */
    data() {
        return {
            sessionCriteria: {},
            confirmSessionDeleteDialog:false,
            componentId: 0,
            changes:false,
            rules: rules,
            showDate: false,
            showStart: false,
            showEnd: false,
            codeSize: {
                small: 100,
                medium: 200,
                large: 300
            },
            qrCodeMessage: 'If you want to include an admin QR Code, insert <QR> into your instructions',
            customToolbar: [

                [{header: []}],
                ["bold","italic","underline",{ "color": []} ],
                [{list: "ordered"}, {list: "bullet"}],
                [{align: []}],
                [{'cname': '3'}],
                ["close-button"],
                ],
            editorSettings: {
                modules:{
                    divider: true,
                    toolbar: {
                        container: 
                        [
                            [{header: []}],
                            ["bold","italic","underline",{ "color": []} ],
                            [{list: "ordered"}, {list: "bullet"}],
                            [{align: []}],
                            ["divider", "link"],
                            ["closeButton"],

                        ],
                        handlers: {
                            closeButton: () => {
                            },
                            customBtn: () => {
                                var range = this.quill.getSelected()
                                if(range) {
                                    this.quill.insertText('ArgH!')

                                }
                            }
                        }
                    }
                }
            },
        }
    }
}
</script>

<style>
/* Default override (not using "append-to-body") */
.vue__time-picker .dropdown ul li:not([disabled]).active {
  background: blue;
}

/* When using "append-to-body" */
.vue__time-picker-dropdown ul li:not([disabled]).active {
  background: blue;
}

</style>
