<template>
    <loading v-if="loading"/>
    <div v-else>
        <v-app-bar class="participant-bar" color="var(--cs1-lev2)">
            <v-row class="no-boundaries">
                <v-col v-if="$vuetify.breakpoint.smAndDown" cols="1" style="padding-left:2px;color:white;font-size:x-small;font-weight:600;margin-top:auto;margin-bottom:auto;">
                    Event Reports
                </v-col>
                <v-col v-else cols="1" style="padding-left:2px;color:white;font-weight:600;margin-top:auto;margin-bottom:auto;">
                    Event Reports
                </v-col>
                <v-col cols="5">
                    <v-select :class="responsiveClass('participant-select')" dark label="Participant" small color="white" hide-details outlined dense v-model="selectedParticipantId" :items="participantNameList" item-text="name" item-value="id" :value="selectedParticipantId">
                    </v-select>
                </v-col>
                <v-col style="display:flex;flex-flow:row;margin-top:auto;margin-bottom:auto" >
                    <v-spacer/>
                </v-col>
            </v-row>
        </v-app-bar>
        <v-card v-if="selectedParticipantId" width="80%" style="margin-left:auto;margin-right:auto; margin-top:20px;">
            <v-card-text>
                <v-row>
                    <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                        <div style="display: flex; flex-flow: row;">
                            <img height="50" :src="clubs[selectedClub].logo"/>
                            <div>
                                <div style="text-align:center">
                                    <h3>{{ events[selectedEvent].title }}</h3>
                                </div>
                                <div>
                                    {{ seasons[events[selectedEvent].seasonId].name }}
                                </div>
                            </div>

                        </div>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" lg="6" xl="6" style="margin-top:auto;margin-bottom:auto">
                        <h3>
                            {{ capitalize(events[selectedEvent].eventNoun) }} Report
                        </h3>
                    </v-col>
                </v-row>
                <v-divider style="margin-top:20px;margin-bottom:20px;"/>
                <v-row>
                    <v-col style="display:flex; flex-flow:row">
                        <h3>{{ capitalize(events[selectedEvent].participantPronoun) }}</h3>: {{ participants[selectedParticipantId].firstName }} {{  participants[selectedParticipantId].lastName }}
                    </v-col>
                </v-row>
                <v-row class="mt-10">
                    <h3>Criteria Histograms</h3>
                </v-row>
                <v-row>
                    <v-col cols="3" v-for="id in criterionList" :key="id">

                        <Bar 
                            :chart-options="chartOptions(id)"
                            :chart-data="chartData(id)"
                            chart-id="criterion-bar-chart"
                            dataset-id-key="label"
                        />
                    </v-col>
                </v-row>
                <v-row class="mt-10">
                    <h3>Development Progress</h3>
                </v-row>
                <v-row>
                    <v-col>
                        <LineChart ref="linechart" :chart-data="timeSeriesData()" :chart-options="timeSeriesOptions()" chart-id="timeseries-chart" dataset-id-key="timeseries"/>
                    </v-col>
                </v-row>

            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import Loading from '@/components/loading'
//import {ValidationObserver } from 'vee-validate'
import {mapState,mapActions} from 'vuex'

import { Bar, Line as LineChart} from 'vue-chartjs/legacy'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    LineElement, 
    PointElement,
    CategoryScale,
    LinearScale
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, LineElement, PointElement, BarElement, CategoryScale, LinearScale)
ChartJS.register(ChartDataLabels)

export default{
    props: [],
    components:{
        Loading,
       // ValidationObserver,
       Bar,
       LineChart
    },
    computed: {
        ...mapState({
            clubs:                  state => state.clubStore.data,
            events:                 state => state.tryoutStore.data,
            seasons:                state => state.seasonStore.data,
            episodes:               state => state.episodeStore.data,
            episodeStatus:          state => state.episodeStore.status,
            roles:                  state => state.roleStore.data,
            roleStatus:             state => state.roleStore.status,
            participants:           state => state.participantStore.data,
            participantList:        state => state.participantStore.listByFirstName,
            participantStatus:      state => state.participantStore.status,
            questions:              state => state.appquestionStore.data,
            questionListByContext:           state => state.appquestionStore.listByContext,
            qresponseByParticipant: state => state.qresponseStore.byParticipant,

            criteria:          state => state.criterionStore.data,
            criterionList:     state => state.criterionStore.list,

            allStaffSkills              :   state => state.playerskillStore. allStaffData,

            selectedClub:           state => state.navigatorStore.selectedClub,
            selectedEvent:          state => state.navigatorStore.selectedTryout,
            selectedEpisode:        state => state.navigatorStore.selectedEpisode,
            nSelectedParticipant:   state => state.navigatorStore.selectedParticipant,

            histogram       : state => state.playerskillStore.histogram,
            timeSeries      : state=> state.playerskillStore.timeSeries,

        }),
        loading(){
            return !this.dataReady ||
                false;
        },
        coachName(){
            return `${this.episodes[this.selectedEpisode].staffList[0].firstName} ${this.episodes[this.selectedEpisode].staffList[0].lastName}`
        },
        startDate(){
            return new Date(this.episodes[this.selectedEpisode].date).toLocaleDateString()
        },
        startTime(){
            return (new Date(this.episodes[this.selectedEpisode].date).toLocaleTimeString())


        },
        participantNameList(){
            const results=[]
            for(var i=0;i<this.participantList.length;i++){
                results.push({
                    id: this.participantList[i],
                    name: `${this.participants[this.participantList[i]].firstName} ${this.participants[this.participantList[i]].lastName}`
                })
            }
            return results
        },

        selectedParticipantId:{
            get(){
                return this.i_selectedParticipantId
            },
            set(newValue){
                this.setSelectedParticipant(newValue)
                this.i_selectedParticipantId = newValue
            }

        }


    },
    methods: {
        ...mapActions('participantStore', [
            'loadAllParticipantsForEvent'
        ]),
        ...mapActions('episodeStore', [
            'loadEpisode',
            'updateEpisode',
            'episodePropertyUpdate'
        ]),
        ...mapActions('roleStore', [
            'loadAllRolesForEvent'
        ]),
        ...mapActions('qresponseStore',[
            'loadAllQResponsesForEvent',
            'updateQResponseProperty',
            'addQResponse',
        ]),
        ...mapActions('appquestionStore', [
            'loadAllQuestionsForEvent'
        ]),
        ...mapActions('navigatorStore', [
            'setSelectedParticipant'
        ]),
        ...mapActions('journalentryStore', [
            'createNewJournalEntry',
        ]),
        ...mapActions('criterionStore', {
            findAllCriterionForEvent : 'findAllForEvent',
            quietFindAllCriterionForEvent : 'quietFindAllForEvent',
        }),
        ...mapActions('playerskillStore', {
            findAllPlayerSkillScores: 'findAllForEvent',
            findAllPlayerSkillScoresForStaffMember: 'findAllForEventAndStaff',
            quietFindAllPlayerSkillScores: 'quietFindAllForEventAndStaff',
            updateScore: 'updateScore',
            updateLocalScore: 'updateLocalScore',

        }),
        ...mapActions('playerskillStore', [
            'loadParticipantStats'
        ]),
        responsiveClass(className){
            if(this.$vuetify.breakpoint.smAndDown){
                return className+' mobile'
            }
            return className
        },
        chartData(criterionId){
            var labels=[]
            var data=[]
            var backgroundColor=[]
            var borderColor=[]

            for (var score=0 ;score<6;score++ ){
                const ratingStr = 'level'+score
                const label=this.criteria[criterionId][ratingStr]
                labels.push(label)

                const val = this.histogram[criterionId][score]
                data.push(val)
                backgroundColor.push('rgba(255, 99, 132, 0.2)')
                borderColor.push('rgb(255, 99, 132)')
            }

            const dataObj = {
                title: 'sss',
                labels,
                datasets: [
                    {
                        label: this.criteria[criterionId].description,
                        backgroundColor,
                        borderColor,
                        borderWidth:1,
                        data
                    }
                ]
            }
            console.log(dataObj)
            return dataObj

        },
        chartOptions(criterionId){
            return {
                elements: {
                    bar: {
                        borderRadius: 5
                    }
                },
                plugins: {
                    title: {
                        display: true,
                        text: this.criteria[criterionId].description
                    },
                    legend: {
                        display: false
                    },
                    datalabels: {
                        color: 'black',
                        display: false,
                        /*
                        display: function(context){
                            const index =  context.dataIndex
                            return context.dataset.data[index]>0
                        }
                        */
                    }
                },
                scales: {
                    y: {
                        min: 0,
                        labels:{ font: { size:0 } }
                    },
                    x: {
                        ticks: { font: { size: 8 } }
                    }
                },
                responsive: true,
                maintainAspectRatio: false

            }
        },
        timeSeriesData(){
            const dataSets=[]
            var labels=[]
            for(var i=0;i<this.criterionList.length;i++){
                labels=[]
                const criterion=this.criteria[this.criterionList[i]]
                const label=criterion.description
                const data=[]

                for(var j=0;j<this.timeSeries.length;j++){
                    labels.push(`Episode ${j+1}`)
                    const score = this.timeSeries[j][criterion.id]
                    console.log(`Score: ${score}`)
                    data.push(this.timeSeries[j][criterion.id])
                }
                dataSets.push({
                    label,
                    borderColor: this.backgroundColors[i],
                    backgroundColor: this.backgroundColors[i],
                    data ,
                    tension:0.4
                })

            }            

            console.log({datasets: dataSets})
            return { 
                labels,
                datasets: dataSets ,
            }
        },
        timeSeriesOptions(){
            return {
                events: ['click'],
                
                onClick: (e) => {

                    const clickData = e.chart.getElementsAtEventForMode(
                        e,
                        'nearest',
                        { intersect: true },
                        false
                    );
                    if(clickData.length>0 ){
                        console.log(`setting index to ${clickData[0].index}`)
                        this.selectedEpisodeId = clickData[0].index
                    }
                },
                
                plugins: {
                    title: {
                        display: true,
                        text: 'Progress over time'
                    },
                    legend: {
                        display: true
                    },
                    datalabels: {
                        color: 'black',
                        display: false,
                        /*
                        display: function(context){
                            const index =  context.dataIndex
                            return context.dataset.data[index]>0
                        }
                        */
                    }
                },
                scales: {
                    y: {
                        min: 0,
                        max:5,
                        labels:{
                            font: {
                                size:0
                            }
                        }
                    },
                    x: {
                        ticks: {
                            font: {
                                size: 8
                            }
                        }
                    }
                },
                responsive: true,
                maintainAspectRatio: false
            }
        }


    },
    watch: {
        i_selectedParticipantId(){
                // Fetch the data dor this participant
                this.loadParticipantStats({eventId: this.selectedEvent, participantId: this.i_selectedParticipantId})
        }
    },
    async created(){
        this.dataReady=false
        await this.loadAllParticipantsForEvent({eventId: this.selectedEvent})
        await this.loadAllRolesForEvent({eventId: this.selectedEvent})
        await this.findAllCriterionForEvent(this.selectedEvent)
        this.dataReady=true
    },
    data(){
        return {
            i_selectedParticipantId: null,
            selectedEpisodeId:null,
            dataReady: false,
            backgroundColors: [
                '#B1AFFF',
                '#BBE9FF',
                '#A1D6B2',
                '#FFE9D0',
            ]
            

        }
    }

}
</script>