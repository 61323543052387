<template>
    <loading v-if="loading"/>
    <div v-else-if="eventAllows(['admin','owner','support'])">
        <v-row style="width:90%;margin:auto;" >
            <div style="display:flex;flex-flow:row;margin:auto;">
                <img :src="clubs[tryouts[participant.eventId].clubId].logo" height="100px"/>
                <div style="margin-top:auto;margin-bottom:auto;" class="club-title">
                    {{clubs[tryouts[participant.eventId].clubId].name}}
                </div>

            </div>
        </v-row>
        <v-row>
            <v-col>
                <span class="text-h5">Sign-in Process</span>
            </v-col>
        </v-row>
        <v-row class="no-boundaries">
            <v-col class="no-boundaries">
                <h3>
                    {{ tryouts[participant.eventId].title }}
                </h3>
            </v-col>
        </v-row>
        <v-row class="no-boundaries">
            <v-col class="no-boundaries">
                {{  sessions[sessionId].title }}
            </v-col>
        </v-row>
        <v-row class="no-bounaries" style="justify-content:center;margin-top:20px;">
            <v-card elevation="10" rounded="xl" color="#ffe5c6" style="border-style:solid;border-width:3px;">
                <v-card-text>
                    <div style="display:flex;flex-flow:row">
                        <div style="margin-left:5px;">
                            <participant-profile-image :participantId="participantId" size="100"/>
                        </div>
                        <div style="margin-left:20px;">
                            <div class="text-left" style="flex-grow:2">
                                <span class="text-h6">{{ this.participant.firstName }} </span><br/>{{ this.participant.lastName }}
                            </div>
                            <div class="text-left">
                                <span class="text-h6">#{{ this.participant.participantNumber }} </span>
                            </div>
                        </div>
                    </div>
                </v-card-text>
            </v-card>
        </v-row>
        <v-row>
            <v-col>
                <div v-if="participant.deskProcessed">
                    <span class="text-h6">Athlete has been processed</span>
                </div>
                <div v-else>
                    <v-btn text color="primary" @click="processAthlete">Mark this athlete as desk processed</v-btn>
                </div>
            </v-col>
        </v-row>

    </div>
    <auth-fail v-else/>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Loading from '@/components/loading'
import ParticipantProfileImage from '@/components/NewParticipantProfileImage'

export default{
    props: ['participantId', 'sessionId'],
    components: {
        ParticipantProfileImage,
        Loading,
    },
    computed: {
        ...mapState({
            participant: state => state.participantStore.participant,
            participantStatus: state => state.participantStore.status,

            clubs:      state => state.clubStore.data,
            clubStatus: state => state.clubStore.status,
            tryouts: state => state.tryoutStore.data,
            tryoutStatus:   state => state.tryoutStore.status,
            sessions:   state => state.sessionStore.data,

        }),
        
        loading(){
            return false 
                || this.participantStatus.loading
                || this.clubStatus.loading
                || this.tryoutStatus.loading
                || !this.dataReady
        },
        alreadyProcessed(){
            return this.participant.deskProcessed
        }
    },
    methods: {
        ...mapActions('participantStore', {
            findParticipant: 'find',
            updateParticipantProperty: 'propertyUpdate',
            updateParticipant: 'update'
        }),
        ...mapActions('navigatorStore', {
            selectTryout: 'selectTryout',
        }),
        ...mapActions('tryoutStore', {
            loadTryout: 'loadTryout'
        }),
        ...mapActions('sessionStore',{
            loadSession: 'findOne',
        }),
        ...mapActions('clubStore',{
            loadClub: 'load'
        }),
        imageAllowed: function(){
            if(this.participant.junior){
                return this.participant.imageConsent
            } else {
                return true
            }
        },
        async processAthlete(){
            await this.updateParticipantProperty({field: 'deskProcessed', id: 0, value: true })
            await this.updateParticipant(this.participant)
        }
    },
    async created(){
        await this.findParticipant({participantId: this.participantId})
        await this.loadSession({sessionId: this.sessionId})
        await this.loadTryout(this.participant.eventId)
        await this.loadClub(this.tryouts[this.participant.eventId].clubId)
        this.selectTryout(this.participant.eventId)
        this.dataReady = true
   },
    data(){
        return {
            dataReady: false,
        }
    }

}
</script>