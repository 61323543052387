<template>
    <loading v-if="loading"/>
    <div v-else style="padding:0px;width:100%" >
        <v-row style="width: 100%">
            <v-col cols="11">

            </v-col>
            <v-col rows="1">
                <v-spacer/>
                <v-btn
                    v-if="isAdminUser()"
                    color="primary"
                    x-small
                    @click="selectPane('Settings')"
                    >
                    Edit
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <img :src="clubs[selectedClub].logo" height="60px"/>
                <h3>{{clubs[selectedClub].name}}</h3>
                <div v-if="clubs[selectedClub].website">
                    <h4>Club Information</h4>
                    <p>You can find more information about this club at their website</p>
                    <div v-if="clubs[selectedClub].linkText">
                        <a :href="clubs[selectedClub].website">{{clubs[selectedClub].linkText}}</a>
                    </div>
                    <div v-else>
                        <a :href="clubs[selectedClub].website">Click here</a>
                    </div>
                </div>
            </v-col>
            <v-col cols="12">
                <v-divider style="padding-top: 40px"/>
            </v-col>
            <v-col cols="12" >
                <div>
                    <v-row>
                        <v-col cols="7" style="text-align: left;">
                            <h3>Active Seasons</h3>
                        </v-col>
                        <v-col cols="5" style="text-align:right;">
                            <v-dialog
                                v-model="dialog" 
                                persistent
                                max-width="600px"
                            >
                                <template v-slot:activator="{on,attrs}">
                                    <v-btn
                                        v-if="isAdminUser()"
                                        color="primary"
                                        x-small
                                        v-bind="attrs"
                                        v-on="on"
                                        >
                                        Add a new season
                                    </v-btn>
                                    
                                </template>
                                <ValidationObserver v-slot="{handleSubmit,reset}" ref="form">
                                    <form @submit.prevent="handleSubmit(save)" style="margin:0px;padding:0px;">
                                        <v-card>
                                            <v-card-title>
                                                <span class="text-h5">Season Details</span>
                                            </v-card-title>
                                            <v-card-text>
                                                <v-row>
                                                    <v-col 
                                                        cols="12"
                                                    >
                                                        <ValidationProvider name="Season Name" rules="required" v-slot="{errors}">
                                                            <v-text-field label="Season Name" v-model="newname" > </v-text-field>
                                                            <div class="form-error">{{ errors[0] }}</div>
                                                        </ValidationProvider>
                                                    </v-col>
                                                    <v-col>
                                                        <DateCombo title="Start Date" v-model="newstartDate" :rules="[]" ></DateCombo>
                                                    </v-col>
                                                    <v-col>
                                                        <DateCombo title="End Date" v-model="newendDate" :rules="[]" ></DateCombo>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>
                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn 
                                                    color="blue darken-1"
                                                    text
                                                    @click="closeDialog(reset)"
                                                >close</v-btn>
                                                <v-btn 
                                                    color="blue darken-1"
                                                    text 
                                                @click="createNewSeason(reset)" 
                                                >Save</v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </form>
                                </ValidationObserver>
                            </v-dialog>
                        </v-col>
                    </v-row>
                    <v-row v-if="activeSeasonList.length === 0">
                        No active seasons
                    </v-row>
                    <v-row v-else>
                        <v-col cols="12" v-for="season in activeSeasonList"  :key="season" xl="2" lg="2" md="2" sm="6" xs="6"     >
                            <v-hover v-slot="{hover}">
                                <v-card
                                    :elevation="hover?12:0"
                                    :class="{'on-hover': hover, 'hover-card':true }"
                                    rounded
                                >
                                    <v-img
                                        :src="seasonLogo(seasons[season].logo, seasons[season].sportId)"
                                        class="white--text align-end"
                                        gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                                        height= "200px"
                                        @click="setSeason(season)"
                                    >
                                    </v-img>
                                    <v-card-actions>
                                        <v-card-title dense class="card-title">{{ seasons[season].name }}</v-card-title>
                                    </v-card-actions>
                                </v-card>
                            </v-hover>
                        </v-col>
                        <v-col v-if="isAdminUser()">
                            <v-hover v-slot="{hover}">
                                <v-card :elevation="hover?12:0"
                                    :class="{'on-hover': hover, 'hover-card':true}"
                                    rounded
                                >
                                </v-card>

                            </v-hover>
                        </v-col>
                    </v-row>
                </div>
            </v-col>


            <v-col v-if="account.user.details.id === clubs[this.selectedClub].ownerId">
                <div>
                    <v-row>
                        <v-col cols="12">
                            <v-divider/>
                        </v-col>
                        <v-col cols="12" style="text-align: left;">
                            <h3>Inactive Seasons</h3>
                        </v-col>
                    </v-row>
                    <v-row v-if="inactiveSeasonList.length===0">
                        No inactive seasons
                    </v-row>
                    <v-row>
                        <v-col v-for="season in inactiveSeasonList"  :key="season" xl="2" lg="2" md="2" sm="6" xs="6"     >
                            <v-hover v-slot="{hover}">
                                <v-card
                                    :elevation="hover?12:0"
                                    :class="{'on-hover': hover, 'hover-card':true }"
                                    rounded
                                >
                                    <v-img
                                        :src="seasonLogo(seasons[season].logo, seasons[season].sportId)"
                                        class="white--text align-end"
                                        gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                                        height= "200px"
                                        @click="setSeason(season)"
                                    >
                                    </v-img>
                                    <v-card-actions>
                                        <v-card-title dense class="card-title">{{ seasons[season].name }}</v-card-title>
                                    </v-card-actions>
                            
                                </v-card>
                            </v-hover>
                        </v-col>
                    </v-row>
                </div>
            </v-col>
        </v-row>

        <!--
        <div v-if="showReview">
            <v-divider/>
            <tryout-review />
        </div>
    -->
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import Loading from '@/components/loading'
import DateCombo from '@/components/DateCombo'
export default {
    props: [
    ],
    components: {
        ValidationObserver,
        ValidationProvider,
        DateCombo,
        Loading,
    },
    computed: {
        ...mapState({
            clubs:      state => state.clubStore.data,

            seasons:    state => state.seasonStore.data,
            seasonList: state => state.seasonStore.list,
            seasonStatus: state => state.seasonStore.status,

            tryouts:    state => state.tryoutStore.data,
            tryoutList: state => state.tryoutStore.list,
            tryoutStatus: state => state.tryoutStore.status,

            selectedClub: state => state.navigatorStore.selectedClub,
            selectedClubPane: state => state.navigatorStore.selectedClubPane,

            sports:     state => state.sportStore.data,
            account:        state => state.account,
        }),
        loading(){
            return false    
                || this.tryoutStatus.loading
                || this.seasonStatus.loading
        },
        activeSeasonList: function () {
            // If the user is a club owner, show all seasons
            /*
            if(this.account.user.details.id === this.clubs[this.selectedClub].ownerId) {
                return this.seasonList
            }
            */
            // Otherwise only show active seasons
            return this.seasonList.filter(seasonId => this.seasons[seasonId].active)
        },
        inactiveSeasonList: function() {
            /*
            if(this.account.user.details.id === this.clubs[this.selectedClub].ownerId){
                return this.seasonList
            }
            */
            return this.seasonList.filter(seasonId => !this.seasons[seasonId].active)
        }

    },
    methods: {
        ...mapActions('seasonStore', {
            findAllSeasonsForClub: 'findAllForClub',
            createSeason: 'create',
        }),
        ...mapActions('navigatorStore', {
            selectTryout: 'selectTryout',
            selectClub: 'selectClub',
            selectPane: 'selectPane',
            selectSeason: 'selectSeason',
            selectNav: 'selectNav',
        }),
        ...mapActions('tryoutStore', {
            getAllTryoutsForSeason: 'findAllForSeason',
           // findActiveTryouts: 'findActive'
        }),
        reviewReg(id){
            this.selectTryout(id)
            this.showReview=true
        },
        register(tryoutId) {
            this.selectClub(this.selectedClub)
            this.selectTryout(tryoutId)
            this.selectPane('TryoutRegistration')
        },
        async setSeason(season){
            await this.getAllTryoutsForSeason(season)
            this.selectSeason(season)
            this.selectPane('SeasonHome')
            this.selectNav('Season')
        },
        seasonLogo(logo, sportId){
            if(logo) return logo
            return this.sports[sportId].cardImage
        },
        async createNewSeason(reset){
            reset()
            await this.createSeason({
                    season: {
                        name: this.newname,
                        startDate: this.newstartDate,
                        endDate: this.newendDate,
                        clubId: this.selectedClub,
                        sportId: this.clubs[this.selectedClub].sportId,
                    }
                })
                this.newname= ''
                this.newstartDate= ''
                this.newendDate= ''
                this.dialog=false
        },
        
        isAdminUser() {
            return (this.clubs[this.selectedClub].ownerId === this.account.id ) || this.clubs[this.selectedClub].admin
        },
        closeDialog(reset){
            reset()
                this.newname= ''
                this.newstartDate= ''
                this.newendDate= ''

             
                this.dialog=false
            },
    },
    async created() {

        //await this.findAllSeasonsForClub({clubId:this.selectedClub,isAdmin:false})
        
    },
    watch: {
        loading(){
        }
    },
    data() {
        return {
            loaded: false,
            showReview: false,
            dialog: false,
            newstartDate: new Date(),
                newendDate: new Date(),
                newname:'',
            /*
            sports: {
                'Volleyball': {
                    cardImage: 'https://s3.ap-southeast-2.amazonaws.com/www.coachsight.net/app_assets/volleyball-card.png'
                },
            }
            */
        }
    }
}

</script>

<style scoped>
li {
    list-style: none;
}

.seasonTile{
    background-color: blueviolet;
    color:white;
    height:100px;
    position: relative;
    

}

.textcenter{
    margin-left: auto;
    margin-right: auto;
}

.vertical-center {
    margin:0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translateY(-50%);
    -ms-transform: translateX(-50%);
    transform: translateY(-50%);
    transform: translateX(-50%);
    text-align: center;
    font-weight: 600;
}
.card-title{
    font-size:small;
    font-family: 'Varela Round';
    word-break: break-word;
    font-weight:600;
    text-align:left;
    margin-left:0px;
    padding-left: 0px;
    margin-right:0px;
    padding-right: 0px;
    line-height:1;

}
.hover-card {
  transition: opacity .4s ease-in-out;
}

.hover-card:not(.on-hover) {
  opacity: 0.6;
 }

.show-btns {
  color: rgba(255, 255, 255, 1) !important;
}

</style>